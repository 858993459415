import React, { useState } from "react";
import Modal from "../../ui_reusable/Modal";
import DatasetForm from "./Form";

const EditModal = ({ dataset, setRefresh, open, onClose }) => {
    const handleSubmit = async (formData, fileInfo) => {
        const payload = new FormData();
        Object.keys(formData).forEach(key => {
            payload.append(key, formData[key]);
        });
        
        // Convert ID to string to match Django's URL pattern expectation
        const datasetId = String(dataset.id);
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/${datasetId}`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: payload
        });

        if (response.status !== 200) {
            const error = await response.json();
            throw new Error(error.message || 'Failed to update dataset');
        }

        setRefresh(new Date().getTime());
        return true;
    };

    const handleSuccess = () => {
        // Close modal after 2 seconds (matching the success animation duration)
        setTimeout(() => {
            onClose();
        }, 100);
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Edit Dataset"
        >
            <Modal.Body>
                <div style={{ width: '65rem'}}>
                    <DatasetForm 
                        onSubmit={handleSubmit}
                        initialData={dataset}
                        submitButtonText="UPDATE DATASET"
                        successMessage="Dataset updated successfully!"
                        isEdit={true}
                        onSuccess={handleSuccess}
                        hasFileUploader={false}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditModal;


