import React, { useCallback } from 'react';
import VersatileGrid from '../../ui_reusable/VersatileGrid';
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../../ui_reusable/VersatileGridUtils';
import { TagSuccess, TagDanger, TagWarning, VerySmallNormalTag } from '../../ui_reusable/styled_comps/Tags';
import { EasyIconButton } from '../../ui_reusable/styled_comps/Buttons';
import CustomIcons from '../../ui_reusable/CustomIcons';
import theme from "../../../styles/theme";
import { StatsContainer } from '../../clients/styled_sub_comps/ListStyledSubComps';
import { HeaderMedium600Style } from "../../styles/TextStyles";
import { SmallNormalTag } from '../../ui_reusable/styled_comps/Tags';
import { StandardCheckbox } from '../../ui_reusable/StandardInputs';
import ShowDataset from '../../comp_reusable/drivers/Show';

const DriverList = ({
    data = [],
    loading = false,
    onCheckboxClick,
    selectedDrivers = [],
    dependentDriverId = null,
    expandedRows = new Set(),
    onExpandRow,
    totalAvailable = 0,
    totalSelected = 0
}) => {
    const headers = [
        {
            title: 'Driver Name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            flexGrow: 2
        },
        {
            title: 'Driver Type',  // New column for dependent/independent tag
            key: 'driver_type',
            type: 'string',
            isSortable: false,
            isSearchable: false,
            center: true
        },
        {
            title: 'Source',
            key: 'source',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Last updated',  // Changed to match List.js naming
            key: 'created_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Observations',
            key: 'observations',
            type: 'number',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Units',
            key: 'units',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Quality',
            key: 'quality',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'View',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        }
    ];

    const gridHeader = (
        <StatsContainer>
            <span css={HeaderMedium600Style}>Available Drivers: {totalAvailable}</span>
            <div>
                <SmallNormalTag>
                    {totalSelected} drivers selected
                </SmallNormalTag>
            </div>
        </StatsContainer>
    );

    const renderIntersectingRow = useCallback((rowData, rowIdx, dataIdx) => {
        if (!expandedRows.has(dataIdx)) return null;
        return <ShowDataset dataset={rowData.driver} datasetId={rowData.driver.id} units={rowData.driver.units} />;
    }, [expandedRows]);

    return (
        <VersatileGrid
            headers={headers}
            data={data}
            searchPlaceholder="Find driver"
            renderRow={(rowData, rowIdx, dataIdx) => [
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <StandardCheckbox 
                        onChange={() => onCheckboxClick(rowData.id)}
                        disabled={dependentDriverId === rowData.id}
                        checked={selectedDrivers.includes(rowData.id)}
                    >
                        
                    </StandardCheckbox>
                    <GridCellBoldText>{rowData.driver.name}</GridCellBoldText>
                </div>,
                <GridCellNormalText>
                    {dependentDriverId === rowData.id ? 
                        <VerySmallNormalTag>Dependent</VerySmallNormalTag> :
                        selectedDrivers.includes(rowData.id) ?
                        <VerySmallNormalTag>Independent</VerySmallNormalTag> : null
                    }
                </GridCellNormalText>,
                <GridCellNormalText>{rowData.driver.source}</GridCellNormalText>,
                <GridCellSmallFadedText>
                    {`${new Date(rowData.driver.created_at).toLocaleDateString()}@${new Date(rowData.driver.created_at).getHours()}:${new Date(rowData.driver.created_at).getMinutes()}`}
                </GridCellSmallFadedText>,
                <GridCellNormalText>{rowData.driver.observations}</GridCellNormalText>,
                <GridCellNormalText>{rowData.driver.units}</GridCellNormalText>,
                <GridCellNormalText>
                    {rowData.driver.quality === "good" && <TagSuccess label={rowData.driver.quality} />}
                    {rowData.driver.quality === "check" && <TagWarning label={rowData.driver.quality} />}
                    {rowData.driver.quality === "poor" && <TagDanger label="Fix" />}
                </GridCellNormalText>,
                <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                    <EasyIconButton onClick={() => onExpandRow(dataIdx)}>
                        {expandedRows.has(dataIdx) ? 
                            <CustomIcons.faEyeSlash color={theme.colors.veryFadedText} solid={false} size="xs" /> : 
                            <CustomIcons.faEye color={theme.colors.veryFadedText} solid={false} size="xs" />
                        }
                    </EasyIconButton>
                </div>
            ]}
            renderIntersectingRow={renderIntersectingRow}
            maxDisplayedItems={8}
            headerInfo={gridHeader}
            loading={loading}
        />
    );
};

export default DriverList;
