import React, { useEffect, useState } from "react";
import { Container, Panel, Stack, Text, useMediaQuery } from "rsuite";
import SimpleAccordion from './ui_reusable/SimpleAccordion';

import { useClientProjectContext } from "../context/ClientProjectContext";
import { useAuth } from "../context/AuthContext";
import { useClientContext } from "../context/ClientContext";
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import ProjectIcon from '@rsuite/icons/Project';
import BarLineChartIcon from '@rsuite/icons/BarLineChart';

const Dashboard = () => {
    const {clientProjectData, setClientProjectData} = useClientProjectContext();
    const {isAuthenticated, user, isAdmin, isClient, isClientUser, isClientManager} = useAuth();
    const {selectedClient, setSelectedClient} = useClientContext();
    const [totalClients, setTotalClients] = useState(0);
    const [totalDatasets, setTotalDatasets] = useState(0);
    const [isMobile] = useMediaQuery('(max-width: 800px)');
    useEffect(() => {
        if(isAuthenticated && isClient){
            setSelectedClient(user.client);
        }
    }, [isAuthenticated, isClient, user, setSelectedClient]);

    useEffect(() => {
        const fetchClientProjectData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/?limit=99999`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });
        
                if (!response.ok) {
                    throw new Error('Failed to fetch clients');
                }
        
                const result = await response.json();
                setTotalClients(result.total);
                const clients = result.data;
        
                const projectFetchPromises = clients.map(async (client) => {
                    const projectsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/projects?client=${client.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    });
        
                    if (!projectsResponse.ok) {
                    throw new Error(`Failed to fetch projects for client ID ${client.id}`);
                    }
        
                    const projectsResult = await projectsResponse.json();
                    const projects = projectsResult.data;
        
                    return projects.map((project) => ({
                        client: { id: client.id, name: client.name, logo: client.logo },
                        project: { id: project.id, name: project.name, logo: project.logo },
                    }));
                });
        
                const clientProjectsArrays = await Promise.all(projectFetchPromises);
                const clientProjects = clientProjectsArrays.flat();
                setClientProjectData(clientProjects);
            } catch (error) {
                console.error("Error fetching client-project data:", error);
            } 
        };
        
        const fetchProjectsData = async () => {
            try {
                const projectsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });

                if (!projectsResponse.ok) {
                throw new Error(`Failed to fetch projects for client ID ${selectedClient.id}`);
                }

                const projectsResult = await projectsResponse.json();
                const projects = projectsResult.data;

                const clientProjects = projects.map((project) => ({
                    client: { id: selectedClient.id, name: selectedClient.name, logo: selectedClient.logo },
                    project: { id: project.id, name: project.name, logo: project.logo },
                }));
                setClientProjectData(clientProjects);
            } catch (error) {
                console.error("Error fetching client-project data:", error);
            }
        };
        if(isAuthenticated){
            if(isAdmin){
                fetchClientProjectData();
            } else if(isClientManager && selectedClient){
                fetchProjectsData(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}`);
            }
            else if(isClientUser && selectedClient){
                fetchProjectsData(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}&user=${user.id}`);
            }
        }
    }, [isAuthenticated, user, isAdmin, isClientManager, isClientUser, selectedClient]);

    useEffect(()=>{
        const getDatasets = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/datasets/?limit=99999`,
                    { 
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const res = await response.json();
                setTotalDatasets(res.total);
            } catch (error) {
                console.error('Failed to fetch datasets:', error);
            }
        };
        if(isAdmin || isClientManager){
            getDatasets();
        }
    },[isAdmin, isClientManager]);

    return (
        <Container>
            {
                isAuthenticated && <Panel>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
                        {isAdmin && (
                            <div style={{ textAlign: "center", flex: 1, borderBottom: "3px solid #EF5F43", padding: "20px", backgroundColor: "#fff", borderRadius: "4px", boxShadow: "0 0 10px rgba(0,0,0,0.1)" }}>
                                <OperatePeopleIcon style={{ fontSize: "1.5em", marginBottom: "10px"}}/>
                                <Text weight="bold" transform="uppercase">Clients</Text>
                                <hr style={{ width: "50px", borderBottom: "5px solid #d7aca3" }}/>
                                <p>{totalClients && totalClients}</p>
                            </div>
                        )}
                        <div style={{ textAlign: "center", flex: 1, borderBottom: "3px solid #EF5F43", padding: "20px", backgroundColor: "#fff", borderRadius: "4px", boxShadow: "0 0 10px rgba(0,0,0,0.1)" }}>
                            <ProjectIcon style={{ fontSize: "1.5em", marginBottom: "10px"}}/>
                            <Text weight="bold" transform="uppercase">Projects</Text>
                            <hr style={{ width: "50px", borderBottom: "5px solid #d7aca3" }}/>
                            <p>{clientProjectData && clientProjectData.length}</p>
                        </div>
                        {(isAdmin || isClientManager) && (
                            <div style={{ textAlign: "center", flex: 1, borderBottom: "3px solid #EF5F43", padding: "20px", backgroundColor: "#fff", borderRadius: "4px", boxShadow: "0 0 10px rgba(0,0,0,0.1)" }}>
                                <BarLineChartIcon style={{ fontSize: "1.5em", marginBottom: "10px"}}/>
                                <Text weight="bold" transform="uppercase">Datasets</Text>
                                <hr style={{ width: "50px", borderBottom: "5px solid #d7aca3" }}/>
                                <p>{totalDatasets && totalDatasets}</p>
                            </div>
                        )}
                    </div>
                </Panel>
            }
        </Container>
    );
}
export default Dashboard;