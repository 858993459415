import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "rsuite";

import SectionTitle from "../ui_reusable/SectionTitle";
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import {ThickSelectPicker} from '../ui_reusable/StandardSelectPickers';
import { HeaderContainer } from '../ui_reusable/styled_comps/PlacementMisc';
import {TagFilledActive,TagFilledInactive} from "../ui_reusable/styled_comps/Tags";


// Import project management components
import Settings from "./managements/Settings";
import Users from "./managements/Users";
import Deactivate from "./managements/Deactivate";
import ProjectSolutionList from "./managements/solutions/List";

import { useClientContext } from "../../context/ClientContext";
import { useProjectContext } from "../../context/ProjectContext";

const ShowProject = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id, projects } = location.state || {};
    const [refresh, setRefresh] = useState(new Date().getTime());

    const {setSelectedClient} = useClientContext();
    const {setSelectedProject} = useProjectContext();
    // Fetch project data
    useEffect(() => {
        const getProject = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const data = await response.json();
                setProject(data.data);
            } catch (error) {
                console.error('Failed to fetch project:', error);
            } finally {
                setLoading(false);
            }
        };
        
        if (id) {
            getProject();
        }
    }, [id, refresh]);

    const handleProjectChange = (projectId) => {
        const project = projects.find(elem=>elem.id===projectId);
        setSelectedClient(project.client);
        setSelectedProject({id: project.id, name: project.name, logo: project.logo});
        navigate(`/projects/${projectId}`, {
            state: {
                id: projectId,
                projects
            }
        });
    };

    return (
        <>
            <HeaderContainer>
                <SectionTitle>
                    Manage project
                </SectionTitle>
                {project && (project.status==='active') ? <TagFilledActive label="Project is active" / > : <TagFilledInactive label="Project is inactive" / >}
                <ThickSelectPicker
                    placeholder="Select project"
                    value={id}
                    data={projects?.map(project => ({
                        label: project.name,
                        value: project.id
                    })) || []}
                    onChange={handleProjectChange}
                    style={{ width: 250 }}
                />
            </HeaderContainer>

            <Container style={{ margin: "0 10px" }}>
                {project && !loading ? (
                    <SimpleAccordion id={"manageProjectPageAccordion"} defaultActiveKey={['settings']}>
                        <SimpleAccordion.Panel
                            header={
                                <>
                                    Project Settings
                                    
                                </>
                            }
                            eventKey="settings"
                        >
                            <Settings project={project} />
                        </SimpleAccordion.Panel>

                        <SimpleAccordion.Panel
                            header="Solutions"
                            eventKey="solutions"
                            initClosed={true}
                        >
                            <ProjectSolutionList/>
                        </SimpleAccordion.Panel>

                     <SimpleAccordion.Panel
                            header="Project Users"
                            eventKey="users"
                            initClosed={true}
                        >
                            <Users project={project} />
                            
                        </SimpleAccordion.Panel>
{/*
                        <SimpleAccordion.Panel
                            header="Insights"
                            eventKey="insights"
                        >

                        </SimpleAccordion.Panel>
 */}
 {/*
                        <SimpleAccordion.Panel
                            header="Change Log"
                            eventKey="changelog"
                        >
                        </SimpleAccordion.Panel>
*/}
                        <SimpleAccordion.Panel
                            header="Deactivate Project"
                            eventKey="deactivate"
                            color="#fffcf5"
                            initClosed={true}
                        >
                            <Deactivate project={project} setRefresh={setRefresh}/>
                        </SimpleAccordion.Panel>
                    </SimpleAccordion>
                ) : (
                    <div>Loading project configuration...</div>
                )}
            </Container>
      
        </>
    );
};

export default ShowProject;