import React, { useState, useRef, useEffect, useCallback } from 'react';
import CustomIcons from './CustomIcons';
import Modal from './Modal';
import UniversalLoader from './UniversalLoader';
import { StandardAIText } from './styled_comps/AITextStyled';
import {
    Container,
    HeaderContainer,
    HeaderTitle,
    Toolbox,
    OuterContainer,
    PlotArea as ContentArea,  // Reused but renamed for clarity
    GradientContainer,
    Footer
} from './styled_sub_comps/ProductContainerStyles';
import theme from '../../styles/theme';
import { SurfaceGhostIconButton } from './styled_comps/Buttons';
import { scrollbarWidth } from '../../styles/ScrollbarStyles';

const AIContainerModal = ({ title, markdownText, onClose, ...props }) => (
    <Modal 
        title={title}
        onClose={onClose} 
        {...props}
    >
        <Modal.Body>
            <StandardAIText
                markdownText={markdownText}
                style={{ padding: "2rem" }}
                useAnimation={false}
            />
        </Modal.Body>
    </Modal>
);

const HelpModal = ({ helpText, open, onClose }) => (
    <Modal 
        title="Help"
        open={open} 
        onClose={onClose}
    >
        <Modal.Body>
            {helpText}
        </Modal.Body>
        <Modal.Footer>
            <Modal.DefaultFooter 
                confirmText="OK" 
                onConfirm={onClose}
                confirmGhost={true}
                flip
            />
        </Modal.Footer>
    </Modal>
);

const AIContainer = ({ 
    title,
    description,
    helpText,
    markdownText,
    size,
    loading = false,
    isLoading: externalLoading,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isScrollMode, setIsScrollMode] = useState(false);
    const containerRef = useRef(null);
    const scrollTimerRef = useRef(null);
    const [initPaddingRightContainer, setInitPaddingRightContainer] = useState(null);
    
    const isLoading = loading || externalLoading || !markdownText;

    // Reset scroll timer when appropriate
    const handleScroll = useCallback(() => {
        if (!containerRef.current) return;

        // Clear existing timer
        if (scrollTimerRef.current) {
            clearTimeout(scrollTimerRef.current);
        }

        // Only start timer if we're at the top
        if (containerRef.current.scrollTop === 0) {
            scrollTimerRef.current = setTimeout(() => {
                setIsScrollMode(false);
            }, 3000);
        }
    }, []);

    // Check for overflow
    useEffect(() => {
        const checkOverflow = () => {
            if (containerRef.current) {
                const hasOverflow = containerRef.current.scrollHeight > containerRef.current.clientHeight;
                setIsOverflowing(hasOverflow && !isScrollMode);
            }
        };

        checkOverflow();
        const timeoutId = setTimeout(checkOverflow, 100);

        const resizeObserver = new ResizeObserver(checkOverflow);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            clearTimeout(timeoutId);
            resizeObserver.disconnect();
        };
    }, [markdownText, isLoading, isScrollMode]);

    // Set up scroll listener
    useEffect(() => {
        const container = containerRef.current;
        if (container && isScrollMode) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [isScrollMode, handleScroll]);

    // Clean up timer on unmount
    useEffect(() => {
        return () => {
            if (scrollTimerRef.current) {
                clearTimeout(scrollTimerRef.current);
            }
        };
    }, []);

    // Move this useEffect after the component mount
    useEffect(() => {
        // Use requestAnimationFrame to ensure DOM is ready
        requestAnimationFrame(() => {
            if (containerRef.current?.parentElement) {
                const computedStyle = window.getComputedStyle(containerRef.current.parentElement);
                const initialPadding = parseInt(computedStyle.paddingRight);
                setInitPaddingRightContainer(initialPadding || 24); // fallback to 24 if parsing fails
            }
        });
    }, []); // Run only once after mount

    const handleChevronClick = () => {
        if (!containerRef.current) return;
        setIsScrollMode(true);
        // Removed the scrollTo animation - now just enables scroll mode
    };

    const handleExpand = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleHelpOpen = () => setShowHelpModal(true);
    const handleHelpClose = () => setShowHelpModal(false);

    return (
        <>
             <OuterContainer 
                size={size}
                style={{
                    paddingRight: isScrollMode 
                        ? `${(initPaddingRightContainer || 24) - scrollbarWidth}px` 
                        : `${initPaddingRightContainer || 24}px`,
                    transition: 'padding 0.3s ease' // Add smooth transition for padding change
                }}
            >
                <Container 
                    
                >
                    <HeaderContainer>
                        <HeaderTitle>{title}</HeaderTitle>
                        <Toolbox>
                            <CustomIcons.ExpandIcon 
                                color={theme.colors.veryVeryVeryFadedText}
                                size="small" 
                                onClick={handleExpand}
                            />
                            {helpText && (
                                <CustomIcons.QuestionIcon 
                                    color={theme.colors.veryVeryVeryFadedText}
                                    size="small" 
                                    onClick={handleHelpOpen}
                                />
                            )}
                        </Toolbox>
                    </HeaderContainer>

                    <ContentArea
                    ref={containerRef}
                    style={{
                        overflowY: isScrollMode ? 'auto' : 'hidden',
                        paddingRight: isScrollMode ? `${scrollbarWidth}px` : '0',
                        transition: 'all 0.3s ease'
                    }}>
                        <UniversalLoader show={isLoading} />
                        {!isLoading && (
                            <StandardAIText
                                markdownText={markdownText}
                                style={{ width: "100%", height: "100%" }}
                            />
                        )}
                        <GradientContainer show={isOverflowing && !isScrollMode}>
                            <SurfaceGhostIconButton onClick={handleChevronClick}>
                                <CustomIcons.ChevronDownIcon 
                                    size="xs" 
                                    color={theme.colors.veryVeryFadedText}
                                />
                            </SurfaceGhostIconButton>
                        </GradientContainer>
                    </ContentArea>

                    
                    
                </Container>
                {description && <Footer>{description}</Footer>}
            </OuterContainer>
            <AIContainerModal
                title={title}
                markdownText={markdownText}
                open={showModal}
                onClose={handleClose}
            />

            <HelpModal 
                helpText={helpText}
                open={showHelpModal}
                onClose={handleHelpClose}
            />
        </>
    );
};

export default AIContainer;
