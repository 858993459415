import React, { useEffect, useState } from 'react';
import { HStackSecondRow } from '../styled_sub_comps/ExecutiveSummaryStyledSubComps';
import GraphContainer from '../../../ui_reusable/GraphContainer';
import AIContainer from '../../../ui_reusable/AIContainer';
import ReportSubSection from '../ui_reusable/ReportSubSection';

/**
 * Model Performance component
 * Shows detailed model performance metrics
 */

const demoMarkdownChunks = [
    `# Q4 2023 Market Analysis

## Executive Overview
Our Q4 2023 analysis reveals `,

    `_significant market developments_ with our **core metrics exceeding targets** by 27%. The model demonstrates exceptional accuracy with an R² value of 0.92.

### Key Performance Metrics
- Revenue growth: **23.5%** YoY
- Customer retention: _89%_ (industry avg: 76%)
- Market share: increased to **34.2%**`,
];

const sampleAIText = `
## Comprehensive Model Performance Analysis

Our model demonstrates _remarkable stability_ and **robust performance** across multiple scenarios and test conditions. Here's a detailed breakdown of our findings:

### 1. Statistical Performance Metrics

The model achieves industry-leading accuracy scores:
* RMSE: 0.023 _(top quartile performance)_
* MAE: 0.019 _(15% improvement over baseline)_
* R²: 0.94 _(indicates strong predictive power)_
* F1 Score: 0.91 _(balanced precision and recall)_

### 2. Key Technical Observations

1. **Prediction Stability**
   * Consistent performance across all segments
   * Variance contained within ±2.3% margin
   * No significant outliers detected
   * High resilience to data noise

2. **Seasonal Pattern Recognition**
   * Successfully identified 12 distinct patterns
   * _98.7% accuracy_ in holiday period predictions
   * Proper handling of yearly cycles
   * Adaptive learning for emerging trends

### 3. Model Robustness Analysis

> "The model demonstrates exceptional adaptability to changing market conditions while maintaining high precision in its predictions."

Key findings from stress testing:
1. **Data Quality Impact**
   * Maintains accuracy with up to 15% missing data
   * Graceful degradation under noise
   * _Strong performance_ with sparse inputs

2. **Edge Case Handling**
   * Correct identification of anomalies
   * Proper treatment of outliers
   * **Robust error handling** mechanisms

### 4. Business Impact Assessment

The model provides significant business value:

* **Revenue Impact**
  * Improved forecast accuracy by 34%
  * Reduced inventory costs by 22%
  * _Enhanced_ planning efficiency

* **Operational Benefits**
  * 45% faster decision-making
  * Reduced manual analysis needs
  * **Real-time** adjustment capabilities

### 5. Future Recommendations

To maintain and improve performance:

1. **Regular Monitoring**
   * Weekly accuracy assessments
   * Monthly drift analysis
   * Quarterly deep-dive reviews

2. **Enhancement Opportunities**
   * Consider adding external data sources
   * Implement automated retraining
   * _Explore_ ensemble approaches

> "Based on comprehensive testing and validation, this model represents a significant advancement in our predictive capabilities."

---

**Note:** All metrics are based on production data from the last 6 months, with rigorous cross-validation and testing protocols.
`;

const ModelPerformance = ({plotData}) => {
    const [markdownText, setMarkdownText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [aiInsightText, setAiInsightText] = useState(null);
    const [isPlotLoading, setIsPlotLoading] = useState(false);
    const [isAiLoading, setIsAiLoading] = useState(true);

    // Simulate loading markdown text for first section
    useEffect(() => {
        setIsLoading(true);
        let currentText = '';
        
        demoMarkdownChunks.forEach((chunk, index) => {
            setTimeout(() => {
                currentText += chunk;
                setMarkdownText(currentText);
                if (index === demoMarkdownChunks.length - 1) {
                    setIsLoading(false);
                }
            }, 2000 * (index + 1)); // 2 second delay between chunks
        });
    }, []);

    // Simulate AI generating insights
    useEffect(() => {
        const fetchAiInsights = async () => {
            setIsAiLoading(true);
            // Simulate API delay with chunks
            await new Promise(resolve => setTimeout(resolve, 1000));
            setAiInsightText("## Performance Analysis\n\nAnalyzing data");
            
            await new Promise(resolve => setTimeout(resolve, 1000));
            setAiInsightText(prev => prev + "\n\nOur model shows _remarkable stability_");
            
            await new Promise(resolve => setTimeout(resolve, 1000));
            setAiInsightText(sampleAIText);
            setIsAiLoading(false);
        };

        fetchAiInsights();
    }, []);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <ReportSubSection
                title="Model fit" 
                overviewText="Tell us how well the model predicts the outcome variable."
            >
            </ReportSubSection>

            <HStackSecondRow>
                <GraphContainer
                    title="Model fit"
                    description="Model fit"
                    helpText="This graph shows the model fit"
                    data={plotData?.data}
                    layout={plotData?.layout}
                    size="big"
                    loading={isPlotLoading}
                />

                <AIContainer
                    title="AI Insights"
                    description="Model fit analysis"
                    helpText="AI-generated insights about model fit"
                    markdownText={aiInsightText}
                    size="small"
                    loading={isAiLoading}
                />
            </HStackSecondRow>
        </div>
    );
};

export default ModelPerformance;