import React from 'react';
import CustomIcons from '../../../ui_reusable/CustomIcons';
import theme from '../../../../styles/theme';
import {
    Container,
    HeaderContainer,
    TitleContainer,
    Title,
    Overview,
    ButtonContainer,
    IconButton,
    Divider,
    ContentContainer
} from './styled_sub_comps/ReportSubSectionStyledSubComps';

/**
 * Template component for Report subsections
 * @param {string} title - Section title
 * @param {string} overviewText - Grey text description below title
 * @param {ReactNode} children - Content to be rendered below divider
 */
const ReportSubSection = ({ title, overviewText, children }) => {
    return (
        <Container>
            <HeaderContainer>
                <TitleContainer>
                    <Title>{title}</Title>
                    {overviewText && <Overview>{overviewText}</Overview>}
                </TitleContainer>
                <ButtonContainer>
                    <IconButton>
                    <CustomIcons.CirclePlusThickIcon size="small" color={theme.colors.fancyText} />
                    </IconButton>
                    <IconButton>
                        <CustomIcons.ShareThickIcon size="small" color={theme.colors.fancyText} />
                    </IconButton>
                </ButtonContainer>
            </HeaderContainer>
            <Divider />
            <ContentContainer>
                {children}
            </ContentContainer>
        </Container>
    );
};

export default ReportSubSection;