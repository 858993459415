import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RegularIcons from '@fortawesome/free-regular-svg-icons';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as TrashSvg } from '../../assets/icons/trash.svg';
import { ReactComponent as PenSvg } from '../../assets/icons/pen.svg';
import { ReactComponent as EyeSvg } from '../../assets/icons/eye.svg';
import { ReactComponent as ProcessSvg } from '../../assets/icons/process.svg';
import { ReactComponent as CopySvg } from '../../assets/icons/copy.svg';
import { ReactComponent as ZoomInSvg } from '../../assets/icons/zoomIn.svg';
import { ReactComponent as ZoomOutSvg } from '../../assets/icons/zoomOut.svg';
import { ReactComponent as ExpandSvg } from '../../assets/icons/expand.svg';
import { ReactComponent as QuestionSvg } from '../../assets/icons/question.svg';
import { ReactComponent as CheckCircleSvg } from '../../assets/icons/checkCircle.svg';
import { ReactComponent as CircleQuestionThickSvg } from '../../assets/icons/circleQuestionThick.svg';
import { ReactComponent as CircleMinusThickSvg } from '../../assets/icons/circleMinusThick.svg';
import { ReactComponent as CirclePlusThickSvg } from '../../assets/icons/circlePlusThick.svg';
import { ReactComponent as ShareThickSvg } from '../../assets/icons/shareThick.svg';
import { ReactComponent as CheckmarkCircleThickSvg } from '../../assets/icons/checkmarkCircleThick.svg';
import { ReactComponent as ChevronDownSvg } from '../../assets/icons/chevronDownSymbol.svg';
import { ReactComponent as ChevronUpSvg } from '../../assets/icons/chevronUpSymbol.svg';
import theme from '../../styles/theme';

// Size mapping for icons
const sizes = {
  xxs: '10px',
  xs: '16px',
  extraSmall: '16px',
  small: '20px',
  s: '20px',
  medium: '24px',
  m: '24px',
  large: '32px',
  l: '32px'
};

// Function to lighten a color by percentage
const lightenColor = (color, percent) => {
  // Remove any leading #
  const hex = color.replace('#', '');
  
  // Convert to RGB
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Lighten each component
  const lightenComponent = (c) => {
    const amount = Math.floor((255 - c) * (percent / 100));
    return Math.min(255, c + amount);
  };

  const lightR = lightenComponent(r);
  const lightG = lightenComponent(g);
  const lightB = lightenComponent(b);

  // Convert back to hex
  const toHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(lightR)}${toHex(lightG)}${toHex(lightB)}`;
};

// Base styled component for SVG icons
const StyledIcon = styled.div`
  cursor: ${props => props.clickDisabled ? 'default' : (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: -0.5rem;
  border-radius: 4px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  
  svg {
    width: ${props => sizes[props.size] || sizes.medium};
    height: ${props => sizes[props.size] || sizes.medium};
    
    path, polygon {
      stroke: ${props => props.color || theme.colors.standardText};
      transition: stroke 0.2s ease;
    }
  }

  &:hover path, &:hover polygon {
    stroke: ${props => !props.disabled && !props.clickDisabled && (props.color ? lightenColor(props.color, 20) : theme.colors.veryFadedText)};
  }

  &:hover {
    cursor: ${props => props.clickDisabled ? 'default' : (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

// Styled component for FontAwesome icons
const StyledFontAwesomeIcon = styled.div`
  cursor: ${props => props.clickDisabled ? 'default' : (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: -0.5rem;
  border-radius: 4px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  
  svg {
    width: ${props => sizes[props.size] || sizes.medium};
    height: ${props => sizes[props.size] || sizes.medium};
    color: ${props => props.color || theme.colors.standardText};
    transition: color 0.2s ease;
  }

  &:hover svg {
    color: ${props => {
      if (props.disabled || props.noHover || props.clickDisabled) return props.color || theme.colors.standardText;
      if (props.hoverColor) return props.hoverColor;
      return props.color ? lightenColor(props.color, 20) : theme.colors.veryFadedText;
    }};
  }

  &:hover {
    cursor: ${props => props.clickDisabled ? 'default' : (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

// Modify the wrapFontAwesomeIcon function to handle the solid prop
const wrapFontAwesomeIcon = (faIcon, fromSolid = false) => {
    const WrappedIcon = ({ size = 'medium', color, hoverColor, onClick, disabled, clickDisabled, solid = fromSolid }) => (
        <StyledFontAwesomeIcon 
            size={size} 
            color={color}
            hoverColor={hoverColor}
            onClick={disabled || clickDisabled ? undefined : onClick} 
            disabled={disabled}
            clickDisabled={clickDisabled}
        >
            <FontAwesomeIcon icon={solid ? SolidIcons[faIcon.iconName] : RegularIcons[faIcon.iconName] || faIcon} />
        </StyledFontAwesomeIcon>
    );
    return WrappedIcon;
};

// Modify the proxy handler
const iconHandler = {
    get: function(target, prop) {
        if (prop in target) {
            return target[prop];
        }
        
        if (prop.startsWith('fa')) {
            // Important change: Use the actual icon object directly
            const solidIcon = SolidIcons[prop];
            const regularIcon = RegularIcons[prop];
            
            if (solidIcon || regularIcon) {
                // Use the icon directly instead of trying to access its iconName
                const WrappedIcon = ({ 
                    size = 'medium', 
                    color, 
                    hoverColor, 
                    onClick, 
                    disabled,
                    clickDisabled,
                    solid = true,
                    noHover = false 
                }) => (
                    <StyledFontAwesomeIcon 
                        size={size} 
                        color={color}
                        hoverColor={hoverColor}
                        onClick={disabled || clickDisabled ? undefined : onClick} 
                        disabled={disabled}
                        clickDisabled={clickDisabled}
                        noHover={noHover}
                    >
                        <FontAwesomeIcon icon={solid ? solidIcon : regularIcon || solidIcon} />
                    </StyledFontAwesomeIcon>
                );
                target[prop] = WrappedIcon;
                return WrappedIcon;
            }
        }
        return undefined;
    }
};

// Create the base exports object with our custom SVG icons
const baseExports = {
    TrashIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <TrashSvg />
        </StyledIcon>
    ),
    PenIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <PenSvg />
        </StyledIcon>
    ),
    EyeIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <EyeSvg />
        </StyledIcon>
    ),
    ProcessIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ProcessSvg />
        </StyledIcon>
    ),
    CopyIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <CopySvg />
        </StyledIcon>
    ),
    ZoomInIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ZoomInSvg />
        </StyledIcon>
    ),
    ZoomOutIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ZoomOutSvg />
        </StyledIcon>
    ),
    ExpandIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ExpandSvg />
        </StyledIcon>
    ),
    QuestionIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <QuestionSvg />
        </StyledIcon>
    ),
    CheckCircleIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <CheckCircleSvg />
        </StyledIcon>
    ),
    CircleQuestionThickIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <CircleQuestionThickSvg />
        </StyledIcon>
    ),
    CircleMinusThickIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <CircleMinusThickSvg />
        </StyledIcon>
    ),
    CirclePlusThickIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <CirclePlusThickSvg />
        </StyledIcon>
    ),
    ShareThickIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ShareThickSvg />
        </StyledIcon>
    ),
    CheckmarkCircleThickIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <CheckmarkCircleThickSvg />
        </StyledIcon>
    ),
    ChevronDownIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ChevronDownSvg />
        </StyledIcon>
    ),
    ChevronUpIcon: ({ size = 'medium', color, onClick, disabled, clickDisabled }) => (
        <StyledIcon size={size} color={color} onClick={disabled || clickDisabled ? undefined : onClick} disabled={disabled} clickDisabled={clickDisabled}>
            <ChevronUpSvg />
        </StyledIcon>
    ),
    createIcon: wrapFontAwesomeIcon
};

// Create and export the proxy
export default new Proxy(baseExports, iconHandler);
