import React from 'react';
import {
    Container,
    CustomHStack,
    LabelValueContainer,
    Label,
    SymbolContainer,
    ValueContainer
} from './HeaderInfoDisplayerStyledSubComps';

const HeaderInfoDisplayer = ({ title, symbol, children }) => {
    return (
        <Container>
            <CustomHStack>
                <LabelValueContainer>
                    <Label>{title}</Label>
                    <ValueContainer>
                        {children}
                    </ValueContainer>
                </LabelValueContainer>
                <SymbolContainer>
                    {symbol}
                </SymbolContainer>
            </CustomHStack>
        </Container>
    );
};

export default HeaderInfoDisplayer;
