/**
 * FAQAccordion which inherits from BaseAccordion
 * Styling and icons match ReportAccordion for consistency
 * Features edit and delete actions alongside expand/collapse
 */

import React, { useState, useEffect, forwardRef } from 'react';
import BaseAccordion from '../../ui_reusable/BaseAccordion';
import { HeaderLarge500Style } from '../../styles/TextStyles';
import { EasyIconButton } from '../../ui_reusable/styled_comps/Buttons';
import CustomIcons from '../../ui_reusable/CustomIcons';
import theme from '../../../styles/theme';

import {
    AccordionContainer,
    PanelContainer,
    DynamicHeader,
    ActionButtonsContainer,
    HeaderTitleContainer,
    MainText
} from './styled_sub_comps/FAQAccordionStyledSubComps';
import LineDividerStyled from '../../ui_reusable/LineDivider';

const FAQAccordion = ({ children, onContextReady, ...props }) => {

    return (
      <AccordionContainer>
        <BaseAccordion  {...props}>
          {children}
        </BaseAccordion>
      </AccordionContainer>
  )
};

/**
 * Panel subcomponent for FAQAccordion
 * @param {boolean} showActions - Controls visibility of edit/delete buttons
 */
const Panel = forwardRef(({ 
    header, 
    children,
    text, // Add text prop
    onEdit,
    onDelete,
    showActions = true, // Add showActions prop with default true
    ...props 
}, ref) => {
    // Add state to track mousedown position and time
    const [mouseDownPos, setMouseDownPos] = useState({ x: 0, y: 0 });
    const [mouseDownTime, setMouseDownTime] = useState(0);

    const handleMouseDown = (e) => {
        setMouseDownPos({ x: e.clientX, y: e.clientY });
        setMouseDownTime(Date.now());
    };

    const handleClick = (e, onToggle) => {
        // Calculate distance and time of mouse movement
        const distance = Math.sqrt(
            Math.pow(e.clientX - mouseDownPos.x, 2) + 
            Math.pow(e.clientY - mouseDownPos.y, 2)
        );
        const timeElapsed = Date.now() - mouseDownTime;

        // If movement is small and quick, treat as click
        if (distance < 5 && timeElapsed < 200) {
            onToggle(e);
        }
    };

    return (
        <PanelContainer>
            <BaseAccordion.Panel
                {...props}
                ref={ref}
                renderHeader={({ isActive, onToggle }) => (
                    <DynamicHeader 
                        isActive={isActive}
                        onMouseDown={handleMouseDown}
                        onClick={(e) => handleClick(e, onToggle)}
                    >
                        <HeaderTitleContainer>
                            <span css={HeaderLarge500Style}>{header}</span>
                        </HeaderTitleContainer>
                        
                        <ActionButtonsContainer>
                            {showActions && (
                                <>
                                    <CustomIcons.PenIcon 
                                        size="xs" 
                                        color={theme.colors.standardText}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onEdit?.();
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    
                                    <CustomIcons.TrashIcon 
                                        size="xs" 
                                        color={theme.colors.standardText}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete?.();
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </>
                            )}
                            
                            {isActive ? 
                                <CustomIcons.ChevronUpIcon 
                                    size="xs" 
                                    color={theme.colors.standardText} 
                                /> : 
                                <CustomIcons.ChevronDownIcon 
                                    size="xs" 
                                    color={theme.colors.standardText} 
                                />
                            }
                        </ActionButtonsContainer>
                    </DynamicHeader>
                )}
            >
                {/* Add text before children if provided */}
                <LineDividerStyled />
                {text && <MainText hasChildren={!!children}>{text}</MainText>}
                {children}
            </BaseAccordion.Panel>
        </PanelContainer>
    );
});

// ...existing exports...
FAQAccordion.Panel = Panel;
export default FAQAccordion;