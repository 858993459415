import React, { useState } from "react";
import { Message } from "rsuite";
import { useForm } from "react-hook-form";
import { StandardButton } from "../../ui_reusable/styled_comps/Buttons";
import { StandardInput } from "../../ui_reusable/StandardInputs";
import { FormSelectPicker } from "../../ui_reusable/StandardSelectPickers";
import { ButtonContainer, Label, FormGroup, FormSection, GridLayout, Container, ErrorMessage, SuccessContainer, ServerErrorMessage } from '../../ui_reusable/styled_comps/CreateInputUtils';
import { roles_list } from "../../../utils/dropdowns";
import CheckmarkAnimation from '../../ui_reusable/CheckmarkAnimation';

const FormClientUser = ({ 
    initialData,
    onSubmit, 
    submitButtonText = "Save user",
    successMessage = "User saved successfully!",
    isEdit = false,
    onSuccess,
    resetOnSuccess = false
}) => {
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(initialData?.role || "user");
    const [showSuccess, setShowSuccess] = useState(false);
    const [removeContainer, setRemoveContainer] = useState(true);
    const [serverError, setServerError] = useState(null);

    const { 
        register, 
        handleSubmit, 
        reset,
        formState: { errors },
        clearErrors,
    } = useForm({
        defaultValues: {
            name: initialData?.name || "",
            email: initialData?.email || "",
            phone: initialData?.phone || "",
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const handleInputChange = (fieldName) => {
        if (errors[fieldName]) {
            clearErrors(fieldName);
        }
    };

    const onFormSubmit = async (formData) => {
        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);
        setServerError(null); // Clear any previous errors

        try {
            const success = await onSubmit({ ...formData, role });

            if (success) {
                setRemoveContainer(false);
                setShowSuccess(true);

                if (resetOnSuccess) {
                    reset();
                    setRole("user");
                }

                setTimeout(() => {
                    setShowSuccess(false);
                    if (onSuccess) {
                        onSuccess();
                    }
                    setTimeout(() => setRemoveContainer(true), 300);
                }, 1500);
            }
        } catch (error) {
            setServerError(error.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <GridLayout>
                    <FormSection>
                        <FormGroup>
                            <Label htmlFor="name">Name</Label>
                            <StandardInput
                                id="name"
                                {...register("name", { 
                                    required: "Name is required",
                                    onChange: () => handleInputChange("name")
                                })}
                            />
                            <ErrorMessage>
                                {errors.name?.message || '\u00A0'}
                            </ErrorMessage>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="email">Email</Label>
                            <StandardInput
                                id="email"
                                type="email"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Please enter a valid email address"
                                    },
                                    onChange: () => handleInputChange("email")
                                })}
                            />
                            <ErrorMessage>
                                {errors.email?.message || '\u00A0'}
                            </ErrorMessage>
                        </FormGroup>
                    </FormSection>
                    <FormSection>
                        <FormGroup>
                            <Label htmlFor="phone">Phone</Label>
                            <StandardInput
                                id="phone"
                                {...register("phone", {
                                    required: "Phone number is required",
                                    pattern: {
                                        value: /^[\d\s+()-]{8,}$/,
                                        message: "Please enter a valid phone number (minimum 8 digits)"
                                    },
                                    onChange: () => handleInputChange("phone")
                                })}
                            />
                            <ErrorMessage>
                                {errors.phone?.message || '\u00A0'}
                            </ErrorMessage>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="role">Role</Label>
                            <FormSelectPicker
                                style={{ width: "100%" }}
                                data={roles_list} 
                                value={role} 
                                onChange={value => setRole(value)}
                            />
                        </FormGroup>
                    </FormSection>
                </GridLayout>
                <ButtonContainer>
                    <StandardButton type="submit">
                        {loading ? 'Saving...' : submitButtonText}
                    </StandardButton>
                </ButtonContainer>
            </form>
            {(serverError || showSuccess) && (
                <SuccessContainer>
                    {serverError ? (
                        <ServerErrorMessage>
                            {serverError}
                        </ServerErrorMessage>
                    ) : (
                        <CheckmarkAnimation 
                            show={showSuccess} 
                            text={successMessage}
                        />
                    )}
                </SuccessContainer>
            )}
        </Container>
    );
};

export default FormClientUser;
