import styled from '@emotion/styled';
import { baseAccordionContainer, baseAccordionHeader } from '../../../ui_reusable/styles/PanelStyle';
import theme from '../../../../styles/theme';
import {HeaderMedium500Style} from '../../../styles/TextStyles';
export const AccordionContainer = styled.div`
  ${baseAccordionContainer}
  gap: 20px;
`;

export const PanelContainer = styled.div`
    position: relative;  // Create new stacking context
    border-radius: 1.1rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid ${theme.colors.backgroundDark};
    transition: border 0.2s ease;
    z-index: 1;  // Base z-index
    
    &:hover {
        border: 1px solid ${theme.colors.backgroundVeryDark};
    }
`;

export const DynamicHeader = styled.div`
    ${baseAccordionHeader}
    background-color: transparent;
    padding: 1.2rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;  // Create new stacking context
    z-index: 2;  // Higher than PanelContainer
`;

export const HeaderTitleContainer = styled.div`
    flex-grow: 1;
    max-width: 90%;
    padding-top: 0.5rem;
    box-sizing: border-box;
`;
export const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 1.3rem;
    align-items: center;
    height: 1.2rem;
    box-sizing: border-box;
    flex-shrink: 1;
    padding-right: 0.4rem;
    padding-top: 0.9rem;
    /* Match ReportAccordion's icon button styling */
    button {
        padding: 0.4rem;
        transition: all 0.2s ease;

        &:hover {
            background-color: ${theme.colors.primaryVeryLight};
            border-radius: 0.4rem;
        }
    }
`;

export const PanelContent = styled.div`
    position: relative;  // Create new stacking context
    padding: 1.2rem;
    line-height: 1.5;
    color: ${theme.colors.text};
    z-index: 1;  // Same as PanelContainer
    overflow: hidden;  // Contain overflow
`;

export const MainText = styled.div`
    ${HeaderMedium500Style}
    position: relative;  // Create new stacking context
    color: ${theme.colors.veryFadedText};
    line-height: 1.6;
    margin-bottom: ${props => props.hasChildren ? '2rem' : '0'};
    white-space: pre-wrap;
    padding: 1rem 2rem 2rem 2rem;
    z-index: 1;  // Same as PanelContainer
`;