import React, { useState, useCallback, useEffect } from "react";
import { Message, SelectPicker } from "rsuite";
import { useForm } from "react-hook-form";

import useStandardToaster from '../../ui_reusable/StandardToaster';
import ImageUploader from '../../ui_reusable/ImageUploader';
import { StandardInput } from '../../ui_reusable/StandardInputs';
import { CircleButton } from '../../ui_reusable/styled_comps/Buttons';
import CustomIcons from '../../ui_reusable/CustomIcons';

import { ErrorMessage } from '../../ui_reusable/styled_comps/CreateInputUtils';
import {PanelNormalIndent} from '../../ui_reusable/styled_comps/PlacementMisc';
import {
    SettingsContainer,
    ProjectSettingsGrid,
    LogoSection,
    EditableFieldContainer,
    InfoSection,
    NormalCell,
    ShrankCell
} from './styled_sub_comps/SettingsStyledSubComps';

const ProjectSettings = ({ project }) => {
    const [KAMs, setKAMs] = useState([]);
    const [selectedKam, setSelectedKam] = useState("");
    const { pushToast } = useStandardToaster();
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingId, setIsEditingId] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const { 
        register, 
        handleSubmit, 
        formState: { errors },
        setValue,
        watch
    } = useForm({
        defaultValues: {
            name: project?.name || "",
            project_id: project?.project_id || "",
        }
    });

    // Fetch KAMs
    useEffect(() => {
        const getKAMs = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users?user_type=kam`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const res = await response.json();
                setKAMs(res.data || []);
                setSelectedKam(project?.kam?.id);
            } catch (error) {
                pushToast(
                    <Message type="error">Failed to load KAMs</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            }
        };
        getKAMs();
    }, [project]);

    // Add useEffect to initialize logo
    useEffect(() => {
        if (project?.logo) {
            setPreviewUrl(`${process.env.REACT_APP_API_URL}/${project.logo}`);
            setFileList([{
                name: project.logo.split('/').pop(),
                fileKey: project.logo,
                url: `${process.env.REACT_APP_API_URL}/${project.logo}`,
                status: 'finished'
            }]);
        } else {
            setPreviewUrl(null);
            setFileList([]);
        }
    }, [project]);

    // Update field handler
    const handleFieldUpdate = async (fieldName) => {
        try {
            const value = watch(fieldName);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${project.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    [fieldName]: value
                })
            });

            if (response.ok) {
                pushToast(
                    <Message type="success">Updated successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                fieldName === 'name' ? setIsEditingName(false) : setIsEditingId(false);
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to update</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    // Handle KAM change
    const handleKamChange = async (kamId) => {
        setSelectedKam(kamId);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${project.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ kam_id: kamId }),
            });
            
            if (response.ok) {
                pushToast(
                    <Message type="success">KAM updated successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to update KAM');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to update KAM</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    // Image handlers
    const handleFileSelect = useCallback((files) => {
        if (!files || !files.length) return;
        
        const file = files[files.length - 1];
        
        if (!file.blobFile.type.startsWith('image/')) {
            pushToast(
                <Message type="error">Please upload an image file</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
            return;
        }

        // Clean up old preview URL if exists
        if (previewUrl && !previewUrl.includes(process.env.REACT_APP_API_URL)) {
            URL.revokeObjectURL(previewUrl);
        }
        
        const objectUrl = URL.createObjectURL(file.blobFile);
        setPreviewUrl(objectUrl);
        setFileList([file]);

        // Handle the file upload
        handleLogoUpload(file.blobFile);
    }, [previewUrl, pushToast]);

    const handleLogoUpload = async (file) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('logo', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${project.id}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.ok) {
                pushToast(
                    <Message type="success">Logo updated successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to update logo</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        } finally {
            setUploading(false);
        }
    };

    const handleRemove = useCallback(async () => {
        if (!project?.id) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${project.id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ logo: null })
            });

            if (response.ok) {
                if (previewUrl && !previewUrl.includes(process.env.REACT_APP_API_URL)) {
                    URL.revokeObjectURL(previewUrl);
                }
                setPreviewUrl(null);
                setFileList([]);
                pushToast(
                    <Message type="success">Logo removed successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to remove logo');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to remove logo</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    }, [project?.id, previewUrl, pushToast]);

    return (
        <PanelNormalIndent>
            {project && (
                <SettingsContainer>
                    <InfoSection>
                        <ProjectSettingsGrid>
                            <NormalCell>
                                <span>Project name:</span>
                            </NormalCell>
                            <NormalCell>
                                <EditableFieldContainer>
                                    <StandardInput
                                        {...register("name", {
                                            required: "Project name is required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9\s-_]+$/,
                                                message: "Invalid characters in project name"
                                            }
                                        })}
                                        disabled={!isEditingName}
                                    />
                                </EditableFieldContainer>
                            </NormalCell>
                            <NormalCell>
                                <CircleButton 
                                    onClick={() => {
                                        if (isEditingName) {
                                            handleFieldUpdate('name');
                                        }
                                        setIsEditingName(!isEditingName);
                                    }}
                                >
                                    {!isEditingName ? <CustomIcons.PenIcon size="xs" /> : 
                                        <CustomIcons.faSave />}
                                </CircleButton>
                            </NormalCell>
                            <NormalCell>
                                <span>Contact:</span>
                            </NormalCell>
                            <NormalCell>
                                <span>{project.client?.contact?.name || 'Not set'}</span>
                            </NormalCell>
                            <ShrankCell/>
                            <ShrankCell>
                                <ErrorMessage style={{height: '7px'}}>
                                    {errors.name?.message || '\u00A0'}
                                </ErrorMessage>
                            </ShrankCell>
                            <ShrankCell/>
                            <ShrankCell/>
                            <ShrankCell/>
                            <NormalCell>
                                <span>Project ID:</span>
                            </NormalCell>
                            <NormalCell>
                                <EditableFieldContainer>
                                    <StandardInput 
                                        {...register("project_id", {
                                            required: "Project ID is required",
                                            pattern: {
                                                value: /^[a-zA-Z0-9-_]+$/,
                                                message: "Invalid characters in project ID"
                                            }
                                        })}
                                        disabled={!isEditingId}
                                    />
                                </EditableFieldContainer>
                            </NormalCell>
                            <NormalCell>
                                <CircleButton 
                                    onClick={() => {
                                        if (isEditingId) {
                                            handleFieldUpdate('project_id');
                                        }
                                        setIsEditingId(!isEditingId);
                                    }}
                                >
                                    {!isEditingId ? <CustomIcons.PenIcon size="xs" /> : 
                                        <CustomIcons.faSave />}
                                </CircleButton>
                            </NormalCell>
                            <NormalCell>
                                <span>E-mail:</span>
                            </NormalCell>
                            <NormalCell>
                                <span>{project.client?.contact?.email || 'Not set'}</span>
                            </NormalCell>
                            <ShrankCell/>
                            <ShrankCell>
                                <ErrorMessage style={{height: '7px'}}>
                                    {errors.project_id?.message || '\u00A0'}
                                </ErrorMessage>
                            </ShrankCell>
                            <ShrankCell/>
                            <ShrankCell/>
                            <ShrankCell/>
                            <NormalCell>
                                <span>Client:</span>
                            </NormalCell>
                            <NormalCell style={{paddingLeft: '0.5rem'}}>
                                <span>{project.client?.name || 'Not set'}</span>
                            </NormalCell>
                            <NormalCell/>
                            <NormalCell>
                                <span>Phone:</span>
                            </NormalCell>
                            <NormalCell>
                                <span>{project.client?.contact?.phone || 'Not set'}</span>
                            </NormalCell>
                            <NormalCell>
                                <span>KAM:</span>
                            </NormalCell>
                            <NormalCell>
                                <SelectPicker
                                    searchable={false}
                                    cleanable={false}
                                    style={{ width: 200 }}
                                    data={KAMs.map(kam => ({
                                        label: kam?.name || 'Unnamed',
                                        value: kam?.id
                                    }))}
                                    value={selectedKam}
                                    onChange={handleKamChange}
                                />	
                            </NormalCell>


                            
                        </ProjectSettingsGrid>
                    </InfoSection>
                    <LogoSection>
                        <ImageUploader
                            onFileSelect={handleFileSelect}
                            onRemove={handleRemove}
                            previewUrl={previewUrl}
                            fileList={fileList}
                            uploading={uploading}
                            headerText="Project logo"
                        />
                    </LogoSection>
                </SettingsContainer>
            )}
        </PanelNormalIndent>
    );
};

export default ProjectSettings;