import React from 'react';
import {
    LongInfoContainer,
    LongInfoLabel,
    LongInfoValue
} from './styled_sub_comps/InfoBoxStyledSubComps';

/**
 * InfoBoxLong component
 * @param {string} infoLabel - Label text on the left
 * @param {string} infoValue - Value text on the right (bold)
 */
const InfoBoxLong = ({ infoLabel, infoValue }) => {
    return (
        <LongInfoContainer>
            <LongInfoLabel>{infoLabel}</LongInfoLabel>
            <LongInfoValue>{infoValue}</LongInfoValue>
        </LongInfoContainer>
    );
};

export default InfoBoxLong;