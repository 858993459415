import React, { useState, useEffect } from 'react';
import { Message } from "rsuite";
import GeneralDriverList from '../../comp_reusable/GeneralDriverList';
import useStandardToaster from '../../ui_reusable/StandardToaster';

const ClientDrivers = ({ clientId }) => {
    const [loading, setLoading] = useState(true);
    const [clientDrivers, setClientDrivers] = useState([]);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { pushToast } = useStandardToaster();
    
    // Fetch client drivers and access
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/${clientId}/datasets?filter_by=category&category=client&limit=99999`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                );
                const driversData = await response.json();
                setClientDrivers(driversData.data || []);
            } catch (error) {
                pushToast(
                    <Message type="error">Failed to load drivers</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [clientId, refresh]);

    const handleDriverToggle = async (driverId) => {
        const clientDriver = clientDrivers.find(clientDriver => clientDriver.dataset.id === driverId);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${clientId}/datasets/${driverId}`,
                {
                    headers: { 
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'PATCH',
                    body: JSON.stringify({ is_active: !clientDriver.is_active })
                }
            );
            if (response.ok) {
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="info">Driver selection updated.</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                pushToast(
                    <Message type="error">Failed to update driver selection.</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            }
        } catch (error) {
            pushToast(
                <Message type="error">`${error.message}`</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    return (
        <GeneralDriverList
            data={clientDrivers}
            loading={loading}
            onCheckboxClick={handleDriverToggle}
            totalAvailable={clientDrivers.length}
            totalSelected={clientDrivers.filter(e=>e.is_active).length}
        />
    );
};

export default ClientDrivers;
