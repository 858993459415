import styled from '@emotion/styled';

export const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 1.3rem;
    justify-content: center;
    align-items: center;
`;

export const StatsContainer = styled.div`
    display: flex;
    gap: 1rem;
    min-width: 20rem;
    width: 100%;
    height: 5rem;
    align-items: center;
`;
