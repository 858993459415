import styled from '@emotion/styled';
import { TextMediumMini500Style,TextMediumMini400FadedStyle, TextMediumMini400Style} from '../styles/TextStyles';
import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GhostButton } from './styled_comps/Buttons';
import {InfoExtraSmall500Style} from '../styles/TextStyles';
import theme from '../../styles/theme';
import {css} from '@emotion/react';
import { FullWidthCell } from './styled_sub_comps/VersatileGridStyledSubComps';
/*
* These are utility components for the versatile grid ment to be used inside cells,
* exposed to the end react component who uses the grid.
* Here we can reuse the same styles for different types of data in the grid.
*/
export const GridCellBoldText = styled.span`
  ${TextMediumMini500Style}; 
`;
export const GridCellSmallFadedText = styled.span`
  ${TextMediumMini400FadedStyle};
`;
export const GridCellNormalText = styled.span`
  ${TextMediumMini400Style};
`;

export const ListInVersatileGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
`;


export const ListInVersatileGridStyle = css`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const ListItemInVersatileGrid = styled.div`
    display: flex;
    align-items: top;
    gap: 10px;

`;

const ExpandButton = styled.button`
  ${InfoExtraSmall500Style}
  background: ${theme.colors.background};
  color: ${theme.colors.veryVeryVeryFadedText};
  min-width: 3.2rem;
  border-radius: 8px;
  padding-right: 0.6rem;
  cursor: pointer;
  margin: 0.5rem;
  border: none;

  
  &:hover {
    
    color: ${theme.colors.standardText};
  }
`;

const ExpandButtonKeeper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Ellipsis = styled.span`
  color: ${props => theme.colors.veryFadedText};
  text-align: center;
  width: 100%;
  line-height: 0.5rem;
`;

export const ExpansiveListInVersatileGrid = ({ children, initialVisibleItems = 2 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const childArray = React.Children.toArray(children);
    // This line ensures the button complex only shows when there are more items than initialVisibleItems
    const hasMoreItems = childArray.length > initialVisibleItems;
    const contentRef = useRef(null);
    const observerRef = useRef(null);

    // Function to measure total height including margins
    const measureHeight = () => {
        const element = contentRef.current;
        if (!element) return 0;

        let totalHeight = element.getBoundingClientRect().height;
        Array.from(element.children).forEach((child) => {
            const style = window.getComputedStyle(child);
            totalHeight += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
        });
        return totalHeight;
    };

    // Spring animation configuration
    const [{ height }, api] = useSpring(() => ({
        from: { height: 0 },
        config: { tension: 200, friction: 20 }
    }));

    useEffect(() => {
        if (!contentRef.current) return;

        // Update height when content changes
        const updateHeight = () => {
            const newHeight = measureHeight();
            api.start({ height: newHeight });
        };

        // Setup ResizeObserver to watch for content size changes
        observerRef.current = new ResizeObserver(() => {
            window.requestAnimationFrame(updateHeight);
        });

        // Observe the content element and all its children
        observerRef.current.observe(contentRef.current);
        Array.from(contentRef.current.getElementsByTagName('*')).forEach((element) => {
            observerRef.current.observe(element);
        });

        // Initial height measurement
        updateHeight();

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [api, isExpanded]);

    return (
        <div>
            <animated.div 
                
                style={{
                    overflow: 'hidden',
                    height: height,
                }}
            >
                <div css={ListInVersatileGridStyle} ref={contentRef}>
                    {isExpanded 
                        ? childArray
                        : childArray.slice(0, initialVisibleItems)
                    }
                </div>
            </animated.div>
            
            {/* Button complex only renders if hasMoreItems is true */}
            {hasMoreItems && (
                <ExpandButtonKeeper>
                    {!isExpanded && <Ellipsis>...</Ellipsis>}
                    <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
                        <FontAwesomeIcon 
                            style={{paddingRight: '0.2rem',paddingLeft: '0.0rem'}} 
                            icon={isExpanded ? faChevronUp : faChevronDown} 
                        />
                        {isExpanded ? 'Hide' : 'Show more'}
                    </ExpandButton>
                </ExpandButtonKeeper>
            )}
        </div>
    );
};

/* SmoothFullWidthCell Component
 * Wraps FullWidthCell with height animation capabilities
 * Uses ResizeObserver to detect content changes and animate smoothly
 */
export const SmoothFullWidthCell = ({ children, columns, isLastRow }) => {
    const contentRef = useRef(null);
    const observerRef = useRef(null);
    const [targetHeight, setTargetHeight] = useState(0);

    // Spring animation for height changes
    const [{ height }, api] = useSpring(() => ({
        from: { height: 0 },
        to: { height: 0 },
        config: { tension: 200, friction: 20 }
    }));

    // Add state to track if content is effectively empty
    const [isEffectivelyEmpty, setIsEffectivelyEmpty] = useState(false);
    const MIN_CONTENT_HEIGHT = 2; // Minimum height threshold in pixels

    // Function to measure total height including margins
    const measureHeight = () => {
        const element = contentRef.current;
        if (!element) return 0;

        // Get all direct children
        const children = Array.from(element.children);
        
        if (children.length === 0) return 0;

        // Calculate total height including margins
        let totalHeight = 0;
        children.forEach((child) => {
            const style = window.getComputedStyle(child);
            const marginTop = parseFloat(style.marginTop);
            const marginBottom = parseFloat(style.marginBottom);
            const elementHeight = child.getBoundingClientRect().height;
            totalHeight += elementHeight + marginTop + marginBottom;
        });

        return totalHeight;
    };

    useEffect(() => {
        if (!contentRef.current) return;

        // Update height when content changes
        const updateHeight = () => {
            const newHeight = measureHeight();
            setTargetHeight(newHeight);
            setIsEffectivelyEmpty(newHeight <= MIN_CONTENT_HEIGHT);
            
            // Animate to the new height
            api.start({ 
                height: newHeight,
                immediate: false
            });
        };

        // Setup ResizeObserver
        observerRef.current = new ResizeObserver(() => {
            window.requestAnimationFrame(updateHeight);
        });

        // Observe the content and all its children
        const element = contentRef.current;
        if (element) {
            observerRef.current.observe(element);
            Array.from(element.getElementsByTagName('*')).forEach((child) => {
                observerRef.current.observe(child);
            });
        }

        // Initial height measurement
        updateHeight();

        // Cleanup function
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [api, children]); // Added children as dependency to react to presence/absence

    return (
        <FullWidthCell 
            columns={columns} 
            isLastRow={isLastRow}
            hideBottomBorder={isEffectivelyEmpty}
        >
            <animated.div style={{ height, overflow: 'hidden' }}>
                <div ref={contentRef}>
                    {children}
                </div>
            </animated.div>
        </FullWidthCell>
    );
};