import React from 'react';
import { Modal } from 'rsuite';
import { StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter } from './styled_comps/ModalStyles';
import { ModalButtonConfirm, ModalButtonCancel } from './styled_comps/Buttons';

export const ModalDefaultButtonFooter = ({ 
    confirmText = 'Confirm', 
    cancelText,
    onConfirm,
    onCancel,
    confirmColor,
    flip = false
}) => {
    const buttons = [
        cancelText && (
            <ModalButtonCancel key="cancel" onClick={onCancel}>
                {cancelText}
            </ModalButtonCancel>
        ),
        <ModalButtonConfirm 
            key="confirm"
            onClick={onConfirm}
            style={confirmColor ? { backgroundColor: confirmColor } : undefined}
        >
            {confirmText}
        </ModalButtonConfirm>
    ].filter(Boolean);

    return (
        <StyledModalFooter>
            {flip ? buttons.reverse() : buttons}
        </StyledModalFooter>
    );
};

const StandardModal = ({ 
    children,
    size = "xs",
    style = { top: '30%', transform: 'translateY(-50%)' },
    ...props 
}) => {
    return (
        <StyledModal size={size} style={style} {...props}>
            {children}
        </StyledModal>
    );
};

// Export subcomponents for easier access
StandardModal.Header = StyledModalHeader;
StandardModal.Body = StyledModalBody;
StandardModal.Footer = StyledModalFooter;
StandardModal.Title = Modal.Title;
StandardModal.DefaultFooter = ModalDefaultButtonFooter;

export default StandardModal;
