import React, { useState, useEffect, memo } from 'react';
import { standardCheckboxStyle, standardInputStyle, headerInputStyle,standardDescriptionStyle } from './styles/StandardInputStyles';

// Standard Checkbox Component with controlled state
export const StandardCheckbox = ({ 
  checked = false, 
  onChange, 
  disabled = false, 
  color = null,  // Added color prop
  size = 'medium' // can be 'small', 'medium', or 'big'
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={handleChange}
      disabled={disabled}
      css={[
        standardCheckboxStyle(size, color),
        disabled && {
          cursor: 'not-allowed',
          opacity: 0.5,
        }
      ]}
    />
  );
};

// Standard Input Component with consistent styling across the application
export const StandardInput = memo(React.forwardRef(({ 
    onChange, 
    value, 
    type = "text",
    disabled = false,
    ...props 
}, ref) => {
    const handleChange = (e) => {
        if (onChange) onChange(e);
    };

    return (
        <input
            ref={ref}
            type={type}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            css={standardInputStyle}
            {...props}
        />
    );
}));

// New Description Component
export const StandardDescription = memo(React.forwardRef(({ 
    onChange, 
    value, 
    disabled = false,
    rows = 4,
    placeholder = "Enter description...", // Add default placeholder
    ...props 
}, ref) => {
    const handleChange = (e) => {
        if (onChange) onChange(e.target.value); // Change this line to pass e.target.value
    };

    return (
        <textarea
            ref={ref}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            rows={rows}
            placeholder={placeholder}
            css={standardDescriptionStyle}
            {...props}
        />
    );
}));

// Header Input Component
export const HeaderInput = memo(React.forwardRef(({ 
    onChange, 
    value, 
    disabled = false,
    ...props 
}, ref) => {
    return (
        <input
            ref={ref}
            type="text"
            value={value}
            onChange={onChange}
            disabled={disabled}
            css={headerInputStyle}
            {...props}
        />
    );
}));

