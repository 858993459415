import styled from '@emotion/styled';
import theme from '../../../../styles/theme';

export const ActionButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
`;

export const StatsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0;
`;
