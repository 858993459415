import React, { useState } from "react";
import { Checkbox, Toggle, Button } from "rsuite";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import EmailIcon from '@rsuite/icons/Email';
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import SectionTitle from "../ui_reusable/SectionTitle";
import { useNavigate } from "react-router-dom";
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import usersData from '../../data/usersData.json';
import VersatileGrid from "../ui_reusable/VersatileGrid";

const ConfigTeam = () => {
    const navigate = useNavigate();
    const [data] = useState(usersData.users);

    const headers = [
        { title: 'Name', key: 'name', type: 'string', isSortable: true },
        { title: 'E-mail', key: 'email', type: 'string', isSortable: true },
        { title: 'Phone', key: 'phone', type: 'string', isSortable: true },
        { title: 'Active', key: 'active', type: 'boolean', isSortable: false },
        { title: 'Solution access', key: 'solution_access', type: 'string', isSortable: false },
        { title: 'Scenario access', key: 'scenario_access', type: 'boolean', isSortable: false },
        { title: 'Manager', key: 'manager', type: 'boolean', isSortable: false },
        { title: 'Manage', key: 'manage', type: 'string', isSortable: false }
    ];

    const renderRow = (rowData) => [
        rowData.name,
        rowData.email,
        rowData.phone,
        <Toggle checked={rowData.active} color="orange" />,
        <div style={{ whiteSpace: 'pre-wrap' }}>
            {Object.keys(rowData.solution_access).map(e => (
                <div key={e}>
                    <Checkbox checked={rowData.solution_access[e]} color="orange">{e}</Checkbox>
                </div>
            ))}
        </div>,
        <Toggle checked={rowData.scenario_access} color="orange" />,
        <Toggle checked={rowData.manager} color="orange" />,
        <div>
            <Button size="sm" appearance="subtle"><EmailIcon /></Button>
            <Button size="sm" appearance="subtle"><TrashIcon /></Button>
            <Button size="sm" appearance="subtle" onClick={() => navigate("/tracker")}><EditIcon /></Button>
        </div>
    ];

    return (
        <>
            <SectionTitle>Manage team</SectionTitle>
            <SimpleAccordion id={"configureTeamsPageAccordion"}>
                <SimpleAccordion.Panel noCollapse>
                    <div style={{ margin: "20px 0", textAlign: "right" }}>
                        <Button appearance="ghost" color="violet" startIcon={<AddOutlineIcon />}>Add new user</Button>
                    </div>
                    <VersatileGrid 
                        headers={headers}
                        data={data}
                        renderRow={renderRow}
                        maxDisplayedItems={8}
                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>
        </>
    );
};

export default ConfigTeam;