import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { HeaderMediumMaxi600Style,InfoMediumMini500Style,InfoMedium500Style } from '../../../styles/TextStyles';
import theme from '../../../../styles/theme';

// Custom HStack component for horizontal stacking
export const HStack = styled.div`
    display: flex;
    width: 92%;
    justify-content: space-between;
`;

// Custom VStack component for vertical stacking
export const VStack = styled.div`
    display: flex;
    flex-direction: column;
    gap:  1.4rem;
`;

// Custom VStack component for vertical stacking
export const LinkVStack = styled.div`
    display: flex;
    flex-direction: column;
    
`;

// ListHeader component
export const ListHeader = styled.span`
    ${HeaderMediumMaxi600Style};
    color: ${theme.colors.veryVeryFadedText};
`;

// ListHeader component
export const ListItemInfo = styled.span`
    ${InfoMediumMini500Style};
    color: ${props => props.disabled ? theme.colors.veryVeryVeryFadedText : theme.colors.fadedText};
    transition: color 0.2s ease;
`;

// ListHeader component
export const LinkBtn = styled.button`
    ${InfoMedium500Style};
    
    align-self: flex-start; /* Prevents the button from stretching */
    color: ${theme.colors.fadedText};
    //Radius 1rem padding 1rem and hover effect
    border-radius: 1rem;
    padding: 0.7rem 1rem 0.7rem 0.6rem;
    background-color: ${theme.colors.surface};
    &:hover {
        color: ${theme.colors.veryFadedText};
        //background-color: ${theme.colors.surfaceSecondary};
    }
`;