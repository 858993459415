
import styled from '@emotion/styled';
import { baseAccordionContainer, baseAccordionHeader, PanelStyle } from '../styles/PanelStyle';
import theme from '../../../styles/theme';
import {HeaderXXLarge600Style} from '../../styles/TextStyles';
export const AccordionContainer = styled.div`
  ${baseAccordionContainer}
  gap: 45px;
`;

export const PanelContainer = styled.div`
  
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  border-radius: 1.1rem;
  background-color: ${theme.colors.surface};
  overflow: hidden;
  box-shadow: ${theme.shadows.medium};
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.primaryVeryLight };
  padding:  1rem 1rem 0.5rem 1.3rem;
`;

export const PanelHeaderTitle = styled.div`
  ${HeaderXXLarge600Style};
`;

export const CustomHStack = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4rem;
  padding: 0.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  &:hover {
    background-color: ${theme.colors.primaryVeryVeryLight};
    border-radius: 50%;
  }
  svg {
    width: 1rem;
    height: 1rem;
    color: ${theme.colors.primary};
  }
`;