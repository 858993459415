import React, { useState, useContext, useCallback, forwardRef, createContext, useEffect } from 'react';
import { standardRadioStyle } from './styles/StandardRadioButtonsStyles';

const RadioContext = createContext();

// RadioProvider now has a single selectedValue
export const RadioProvider = ({ children, onChange, initValue, group = 'default' }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // Effect to handle initial value
  useEffect(() => {
    if (initValue) {
      setSelectedValue(initValue);
    }
  }, [initValue]); // Only run when initValue changes

  const handleChange = useCallback((newValue) => {
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }, [onChange]);

  return (
    <RadioContext.Provider value={{ 
      selectedValue,
      handleChange,
      group 
    }}>
      {children}
    </RadioContext.Provider>
  );
};

// StandardRadio just checks against the single selectedValue
export const StandardRadio = forwardRef(({ group = 'default', value, size = 'l' }, ref) => {
  const context = useContext(RadioContext);
  
  if (!context) {
    throw new Error('StandardRadio must be used within a RadioProvider');
  }

  if (context.group !== group) {
    throw new Error(`Radio with group "${group}" must match its provider's group "${context.group}"`);
  }

  return (
    <input
      ref={ref}
      type="radio"
      checked={context.selectedValue === value}
      onChange={() => context.handleChange(value)}
      css={standardRadioStyle(size)}
    />
  );
});
