import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import CustomIcons from './CustomIcons';
import Modal from './Modal';
import UniversalLoader from './UniversalLoader';

import {
    Container,
    HeaderContainer,
    HeaderTitle,
    Toolbox,
    PlotArea,
    OuterContainer,
    Footer
} from './styled_sub_comps/ProductContainerStyles';
import theme from '../../styles/theme';

const baseConfig = {
    displayModeBar: true,
    displaylogo: false,  // Remove Plotly logo
    modeBarButtonsToRemove: [
        'lasso2d',    // Remove lasso select
        'select2d'    // Remove box select
    ],
    toImageButtonOptions: {
        format: 'png',
        filename: 'graph_export',
        height: 1080,
        width: 1920
    }
};

const GraphContainerModal = ({ title, data, layout, onClose, ...props }) => (
    <Modal 
        title={title}  // Pass title prop directly
        
        onClose={onClose} 
        {...props}
    >
        <Modal.Body  >

            <Plot
                data={data}
                layout={layout}
                config={baseConfig}
                style={{ width: "70VW" , height: "40VH",padding:"2rem"}}
            />
        </Modal.Body>
    </Modal>
);

const HelpModal = ({ helpText, open, onClose }) => (
    <Modal 
        title="Help"  // Use title prop here too
        open={open} 
        onClose={onClose}
        
    >
        <Modal.Body>
            {helpText}
        </Modal.Body>
        <Modal.Footer>
            <Modal.DefaultFooter 
                confirmText="OK" 
                onConfirm={onClose}
                confirmGhost={true}
                flip
            />
        </Modal.Footer>
    </Modal>
);

const GraphContainer = ({ 
    title,
    description,
    helpText,
    data,
    layout,
    config, // Still accept but ignore
    size,
    loading = false,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const plotContainerRef = useRef(null);
    const toolboxRef = useRef(null);
    
    const isLoading = loading || !data || !layout;

    const handleExpand = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleHelpOpen = () => setShowHelpModal(true);
    const handleHelpClose = () => setShowHelpModal(false);

    const plotConfig = {
        ...baseConfig,
        responsive: true // Ensure the graph responds to window resizing
    };

    const responsiveLayout = {
        ...layout,
        autosize: true // Enable auto-sizing
    };

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const modebar = plotContainerRef.current?.querySelector('.modebar');
            if (modebar) {
                modebar.style.position = 'relative'
                modebar.style.top = '-2.1rem';
                modebar.style.left = '14rem';
                observer.disconnect(); // Stop observing once the modebar is found and styled
            }
        });

        if (plotContainerRef.current) {
            observer.observe(plotContainerRef.current, {
                childList: true,
                subtree: true
            });
        }

        return () => observer.disconnect(); // Cleanup observer on component unmount
    }, [plotContainerRef]);

    return (
        <>
            <OuterContainer size={size}>
                <Container >
                    <HeaderContainer>
                        <HeaderTitle>{title}</HeaderTitle>
                        <Toolbox ref={toolboxRef}>
                            <CustomIcons.ExpandIcon 
                                color={theme.colors.veryVeryVeryFadedText}
                                size="small" 
                                onClick={handleExpand}
                            />
                            {helpText && (
                                <CustomIcons.QuestionIcon 
                                    color={theme.colors.veryVeryVeryFadedText}
                                    size="small" 
                                    onClick={handleHelpOpen}
                                />
                            )}
                        </Toolbox>
                    </HeaderContainer>

                    <PlotArea ref={plotContainerRef}>
                        <UniversalLoader show={isLoading} />
                        {!isLoading && (
                            <Plot
                                data={data}
                                layout={responsiveLayout}
                                config={plotConfig}
                                useResizeHandler={true}
                                style={{ width: "100%", height: "100%" }}
                            />
                        )}
                    </PlotArea>

                    {description && (
                        <Footer>{description}</Footer>
                    )}
                </Container>
            </OuterContainer >
            <GraphContainerModal
                title={title}
                data={data}
                layout={responsiveLayout}
                open={showModal}
                onClose={handleClose}
            />

            <HelpModal 
                helpText={helpText}
                open={showHelpModal}
                onClose={handleHelpClose}
            />
        </>
    );
};

export default GraphContainer;
