import React from 'react';
import { DateRangePicker } from 'rsuite';
import styled from '@emotion/styled';
import theme from '../../styles/theme';

const StyledDateRangePicker = styled(DateRangePicker)`
    .rs-picker-toggle {
        background-color: white !important;
        border: 1px solid ${theme.colors.border} !important;
        padding: 8px 12px !important;
        
        &:hover {
            border-color: ${theme.colors.primary} !important;
        }
    }

    &.rs-picker-focused {
        .rs-picker-toggle {
            border-color: ${theme.colors.primary} !important;
            box-shadow: none !important;
        }
    }
`;

export const StandardDateRangePicker = (props) => {
    return (
        <StyledDateRangePicker
            {...props}
            appearance="default"
            style={{ width: 260 }}
            format="yyyy-MM-dd"
            cleanable={false}
        />
    );
};
