import { useQuery } from 'react-query';

const fetchDatasetSummary = async (datasetId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/${datasetId}/analyze?action=summary`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    if (response.status === 200) {
        const result = await response.json();
        return result.data;
    } else {
        throw new Error('Failed to fetch dataset summary');
    }
};

const useDatasetSummary = (datasetId) => {
    return useQuery(
        ['datasetSummary', datasetId], 
        () => fetchDatasetSummary(datasetId), 
        {
            staleTime: 5 * 60 * 1000,
            cacheTime: 30 * 60 * 1000
        }
    );
};

export default useDatasetSummary;
