import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [authState, setAuthState] = useState({ isLoading: true, error: null });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) validateToken(token);
    else setAuthState({ isLoading: false, error: null });
    // eslint-disable-next-line
  }, []);

  const apiRequest = async (url, options = {}) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw await response.json();
      }
      return response.json();
    } catch (error) {
      throw error;
    }
  };

  const validateToken = async (token) => {
    try {
      await apiRequest(`${process.env.REACT_APP_API_URL}/api/auth/validate_token/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });
      setIsAuthenticated(true);
      localStorage.setItem('token', token);
      await fetchUserDetails(token);
      setAuthState({ isLoading: false, error: null }); 
    } catch (error) {
      setAuthState({ isLoading: false, error: 'Token validation failed.' });
      handleLogout();
    }
  };

  const fetchUserDetails = async (token) => {
    try {
      const response = await apiRequest(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const decoded = jwtDecode(token);
      const { id, name, email, avatar, phone, address} = response.data;
      setUserData({
        id,
        name,
        email,
        avatar,
        phone,
        address,
        scope: decoded.scope,
        role: decoded.role,
        client: decoded.client,
      });
    } catch(error) {
      console.error(error);
      setUserData(null);
    }
  };

  const login = async (credentials) => {
    setAuthState({ isLoading: true, error: null });
    try {
      const { access } = await apiRequest(`${process.env.REACT_APP_API_URL}/api/auth/token/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
      });
      localStorage.setItem('token', access);
      setIsAuthenticated(true);
      await fetchUserDetails(access);
      setAuthState({ isLoading: false, error: null });
      return true;
    } catch (error) {
      const errorMessage = error?.password?.[0] || error?.username?.[0] || 
        (error.detail || "Invalid credentials");
      setAuthState({ isLoading: false, error: errorMessage });
      return false;
    }
  };

  const handleLogout = (redirectUrl = '') => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUserData(null);
    setAuthState({ isLoading: false, error: null });
    window.location.href = redirectUrl || `${window.location.origin}/login`;
  };

  const hasScope = (requiredScopes) => {
    if (!userData?.scope) return false;
    const scopes = Array.isArray(requiredScopes) ? requiredScopes : [requiredScopes];
    return scopes.some((scope) => userData.scope === scope);
  };

  const isAdmin = userData?.scope ? ['owner', 'admin'].includes(userData.scope) : false;
  const isClient = userData?.scope ? userData.scope === 'client' : false;
  const isClientUser = (userData?.scope && userData?.role) ? (userData.scope === 'client' && userData.role === 'user'): false;
  const isClientManager = (userData?.scope && userData?.role) ? (userData.scope === 'client' && userData.role === 'manager'): false;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        login,
        logout: handleLogout,
        hasScope,
        isAdmin,
        isClient,
        isClientUser,
        isClientManager,
        isLoading: authState.isLoading,
        error: authState.error,
      }}
    >
      {!authState.isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
