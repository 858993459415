import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { DateRangePicker } from "rsuite";
import { scrollbarStyle } from '../../../styles/ScrollbarStyles';

// Common style variables
const commonDimensions = {
    width: '10rem',
    minWidth: '30%',
    paddingX: '12px',
    borderRadius: '8px'
};

// Base wrapper for the select picker
export const BaseSelectPickerWrapper = styled.div`
    width: 100vw;
    
    width: ${commonDimensions.width};
    min-width: ${commonDimensions.minWidth};
    ${props => props.customStyle}; // Allow custom style injection
`;

// Toggle text styling
export const ToggleText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
`;

// Toggle button styling
export const PickerToggle = styled.div`
    background: ${theme.colors.surface};
    border: 1px solid ${theme.colors.backgroundDark};
    border-radius: ${commonDimensions.borderRadius};
    padding: 8px ${commonDimensions.paddingX};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        border-color: ${theme.colors.primary};
    }
`;

// Dropdown menu styling
export const PickerMenu = styled.div`
    position: absolute;
    flex: 1 0 auto;
    min-width: 100%;
    background: white;
    border: 1px solid #D1D5DB;
    border-radius: ${commonDimensions.borderRadius};
    margin-top: 4px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    ${scrollbarStyle}
`;

// Menu item styling
export const MenuItem = styled.div`
    padding: 8px ${commonDimensions.paddingX};
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-color: rgba(59, 130, 246, 0.1);
    }

    &.selected {
        background-color: rgba(59, 130, 246, 0.2);
    }

    &.disabled {
        cursor: not-allowed;
        color: ${theme.colors.fadedText};
        background-color: ${theme.colors.backgroundLight};
        
        &:hover {
            background-color: ${theme.colors.backgroundLight};
        }
    }
`;

// Thick variant styling
export const ThickSelectPickerStyled = styled(BaseSelectPickerWrapper)`
    &.thick-select-picker {
        ${PickerToggle} {
            ${ToggleText} {
                font-weight: 600;
                font-size: 1rem;
            }
        }
    }
`;

// Form variant styling
export const FormSelectPickerStyled = styled(BaseSelectPickerWrapper)`
    &.form-select-picker {
        ${PickerToggle} {
            ${ToggleText} {
                font-weight: 400;
                font-size: 0.8rem;
            }
        }
    }
`;

// Keep DateRangePicker styling if needed
const sharedDateRangePickerStyles = `
    width: ${commonDimensions.width};
    min-width: ${commonDimensions.minWidth} !important;

    .rs-picker-input-group {
        background: ${theme.colors.surface} !important;
        border: 1px solid #D1D5DB !important;
        border-radius: ${commonDimensions.borderRadius} !important;
        padding: 0 ${commonDimensions.paddingX} !important;
        &:hover, &:focus {
            border-color: ${theme.colors.primary} !important;
        }
    }

    &.rs-picker-focused {
        .rs-picker-input-group {
            border-color: ${theme.colors.primary} !important;
            box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1) !important;
        }
    }

    // Z-index handling for modals
    &.rs-picker {
        position: relative;
        z-index: 3;
    }
    
    .rs-picker-menu {
        z-index: 1070 !important;
    }
`;

export const FormDateRangePickerStyled = styled(DateRangePicker)`
    ${sharedDateRangePickerStyles}

    .rs-input {
        font-weight: 400 !important;
        color: #374151 !important;
        font-size: 0.8rem !important;
    }
`;
