import styled from '@emotion/styled';
import { HeaderExtraLarge600Style, TextMedium500Style } from '../../../../styles/TextStyles';
import theme from '../../../../../styles/theme';

export const Container = styled.div`
`;

export const HeaderContainer = styled.div`

    padding: 0px 20px 5px 20px;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h2`
    ${HeaderExtraLarge600Style};
    display: block;
    margin-bottom: 0.8rem;
`;

export const Overview = styled.p`
    ${TextMedium500Style};
    color: ${theme.colors.veryFadedText};
`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const IconButton = styled.button`
    padding: 0.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${theme.colors.fadedText};
    
    &:hover {
        color: ${theme.colors.standardText};
    }
`;

export const Divider = styled.hr`
    margin: 0;
    border: none;
    border-top: 1.5px solid ${theme.colors.backgroundDark};
`;

export const ContentContainer = styled.div`
    padding: 2rem;
`;
