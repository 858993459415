import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { InfoExtraSmall600Style,InfoXXXSmall500Style, TextMediumMini400Style } from '../../styles/TextStyles';
import { CellStyle ,CellKeeperStyle} from '../styles/VersatileGridStyles';
import { CellContentStyle } from '../styles/VersatileGridStyles';


export const GridWrapper = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid ${theme.colors.backgroundDark};
  //background-color: aquamarine; // debug
`;

export const GridTop = styled.div`
  width: 100%;
`;

export const GridBottom = styled.div`
  width: 100%;
  background-color: ${theme.colors.surface};
`;

export const GridArea = styled.div`
  width: 100%;
  background-color: ${theme.colors.surface};
  overflow-y: auto; // Add scrollbar when content exceeds height
  overflow-x: hidden; // Prevent horizontal scrolling
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, auto)`};
  width: 100%;
  background-color: ${theme.colors.surface};

  height: auto;
`;

export const CellContent = styled.div`
  ${CellContentStyle};
  //background-color: #2a7d2f; // debug
`;

export const SortIcon = styled.span`
  margin-left: 8px;

  display: inline-flex;
  flex-direction: column;
  
  svg {
    font-size: 12px;
  }
`;

export const NoItemsMessage = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  color: ${props => props.theme.colors.fadedText};
`;

export const HeaderCell = styled.button`
  ${(props) => CellKeeperStyle(props)};
  ${InfoXXXSmall500Style};
  padding: 2%;
  background-color: ${theme.colors.surfaceSecondary}; // real
  //background-color: #9dd427; // debug
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: right;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.backgroundDark};
  transition: background-color 0.2s ease;
  padding-top: 1rem;


  & > * {
      
    ${(props) => {return props.center && `
          margin-left: auto;
      `}}
      margin-right: auto;
      
    }

    &:nth-of-type( ${(props) => props.columns}n+1) {
      
      & > * {
        margin-left: 0;
        margin-right: auto;
      }
    }

    
  &:hover {
    background-color: ${theme.colors.backgroundDark};
  }

  &.active {
    background-color: ${theme.colors.backgroundTwilight};
    
    ${SortIcon} {
      opacity: 1;
    }
  }
`;

export const RegularHeaderCell = styled.div`
  ${(props) => CellStyle(props)};
  ${InfoXXXSmall500Style};
  padding: 0.75rem;
  width: 100%;
`;

export const Cell = styled.div`
  ${(props) => CellStyle(props)};
  background-color: ${theme.colors.surface}; //real
  //background-color: #f0d9c8; // debug
  
`;

export const CellKeeper = styled.div`
  ${(props) => CellKeeperStyle(props)};
  background-color: ${theme.colors.surface}; 

`;

export const EmptySpace = styled.div`
  width: 100%;
  height: ${props => props.hasExpansiveList ? '1.8rem' : '0rem'};
  
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

export const PagesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex: 1;
`;

export const PageButton = styled.button`
  ${InfoExtraSmall600Style};
  background-color: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: outline background-color 0.2s ease;
  

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const NavigationButton = styled(PageButton)`
  min-width: 5rem;
  background-color: ${theme.colors.surface};
  outline: 2px solid ${theme.colors.backgroundDark};
  &:hover:not(:disabled) {
    background-color: ${theme.colors.background};
  }
`;


export const NumberButton = styled(PageButton)`
  min-width: 2.5rem;
  padding: 0.5rem;
  background-color: ${theme.colors.surface};
  color: ${theme.colors.veryVeryVeryFadedText};
  &:hover:not(:disabled) {
    outline: 0.1rem solid ${theme.colors.veryVeryVeryFadedText};
  }

  &.active {
    background: ${theme.colors.primaryVeryVeryLight};
    color: ${theme.colors.primaryFaded};
  }
`;

export const PageIndicator = styled.span`
  ${TextMediumMini400Style};
  padding: 0.5rem;
`;



export const GridHeaderContainer = styled.div`
  display: flex;
  
  padding: 1.5rem 3rem 1.5rem 2rem;
  gap: 1rem;
`;

export const GridHeaderLeft = styled.div`
  flex: 0 0 80%;
  display: flex;
  align-items: center;
`;

export const GridHeaderRight = styled.div`
  flex: 0 0 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

/* Full width cell that spans across all columns in the grid
 * Uses CSS Grid's grid-column property to span from first to last column
 * props.columns determines the total number of columns to span
 */
export const FullWidthCell = styled.div`
  grid-column: 1 / span ${props => props.columns};
  width: 100%;
  
  ${props => !props.isLastRow && !props.hideBottomBorder && `
    border-bottom: 1px solid ${props.theme.colors.backgroundDark};
  `}
`;