/**
 * SimpleAccordion Component which inheris from BaseAccordion
 * The main differences between all inherited accordions is applied styling and the header rendering.
 * This is the most standard design used through out the entire app
 * 
 */

import React, { useState, useEffect, useRef } from 'react';
import BaseAccordion from './BaseAccordion';
import CustomIcons from './CustomIcons';
import {
  AccordionContainer,
  PanelContainer,
  HeaderText,
  IconContainer,
  PanelHeader
} from './styled_sub_comps/SimpleAccordionStyledSubComps';
import { getHeight } from 'rsuite/esm/DOMHelper';

const SimpleAccordion = ({ children, id, ...props }) => {
  return (
    <AccordionContainer>
      <BaseAccordion id={id} {...props}>
        {children}
      </BaseAccordion>
    </AccordionContainer>
  );
};

/**
 * Panel subcomponent for SimpleAccordion
 * Renders a header with optional text and chevron indicator
 * @param {string} color - Optional background color for the panel
 */
const Panel = ({ header, children, color, ...props }) => {
  return (
    <PanelContainer color={color}>
      <BaseAccordion.Panel
        {...props}
        renderHeader={({ isActive, onToggle, noCollapse }) => (
          <PanelHeader>
            {header && <HeaderText>{header}</HeaderText>}
            {!noCollapse && (
              <IconContainer onClick={onToggle}>
                {isActive ? 
                  <CustomIcons.ChevronUpIcon size="xs" /> : 
                  <CustomIcons.ChevronDownIcon size="xs" />
                }
              </IconContainer>
            )}
          </PanelHeader>
        )}
      >
        <div style={{height: '2rem'}}></div>
        {children}
      </BaseAccordion.Panel>
    </PanelContainer>
  );
};

SimpleAccordion.Panel = Panel;
export default SimpleAccordion;