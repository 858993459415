import React, { useEffect, useState } from "react";
import CustomIcons from '../../ui_reusable/CustomIcons';

import { GhostIconButtonRed } from '../../ui_reusable/styled_comps/Buttons';
import { StandardToggleButton } from '../../ui_reusable/styled_comps/ToggleButtons';
import {
    WarningSection,
    OptionsSection,
    TwoColumnMainContainer,  // Changed from ThreeColumnContainer
    Column,
    TwoColumnContainer,
    ControlColumn,
    ContentColumn,
} from '../../ui_reusable/styled_sub_comps/DeactivateStyledSubComps';
import {HeaderLargeImportant,TextMediumMaxi600Style,HeaderMedium600Style,TextSmall500FadedStyle} from '../../styles/TextStyles';
import StandardModal from '../../ui_reusable/StandardModal';
import { Message } from "rsuite";
import useStandardToaster from "../../ui_reusable/StandardToaster";
import { useNavigate } from "react-router-dom";

const Deactivate = ({client, setRefresh}) => {
    const [isPreserveAccess, setIsPreserveAccess] = useState(true);
    const [isActive, setIsActive] = useState(true); // This will come from API
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteUnderstandModal, setShowDeleteUnderstandModal] = useState(false);
    const { pushToast } = useStandardToaster();
    const navigate = useNavigate();

    useEffect(()=>{
        setIsActive(client.is_active);
    },[client]);

    const updateClientStatus = async (activate=true) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${client.id}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({is_active: activate})
            });

            if (response.ok) {
                setIsActive(activate);
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="success">{(activate)?'Client is activated':'Client is deactivated'}</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to update client status');
            }
        } catch (error) {
            pushToast(
                <Message type="error">An error occurred, try again later.</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    }
    const handleActiveToggle = async() => {
        if (isActive) {
            setShowDeactivateModal(true);
        } else {
            await updateClientStatus(true);
        }
    };

    const handleDeleteClick = () => {
        setShowDeleteUnderstandModal(true);
    };

    const handleUnderstandClick = async () => {
        setShowDeleteUnderstandModal(false);
        setShowDeleteModal(true);
    };

    const handleDeleteClient = async ()=>{
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${client.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                setShowDeleteModal(false);
                pushToast(
                    <Message type="success">Client is deleted</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                navigate('/clients')
            } else {
                throw new Error('Failed to delete client');
            }
        } catch (error) {
            pushToast(
                <Message type="error">An error occurred, try again later.</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    }

    return (
        <>
            <WarningSection>
                <h2 css={HeaderLargeImportant}>DANGER ZONE</h2>
                <span css={TextMediumMaxi600Style}>
                    Please be very careful and very sure before making changes here.
                </span>
            </WarningSection>

            <OptionsSection>
                <TwoColumnMainContainer>  {/* Changed from ThreeColumnContainer */}
                    {/* Active Status Column */}
                    <Column>
                        <TwoColumnContainer>
                            <ControlColumn>
                                <StandardToggleButton
                                    checked={isActive}
                                    onChange={handleActiveToggle}
                                />
                            </ControlColumn>
                            <ContentColumn>
                                <h4 
                                    css={HeaderMedium600Style} 
                                    style={{color: isActive ? "#c5841c" : '#A0A0A0'}}
                                >
                                    Active status
                                </h4>
                                <span 
                                    css={TextSmall500FadedStyle} 
                                    style={{color: isActive ? "#c5841c" : '#A0A0A0'}}
                                >
                                    Click to deactivate client from Citrus.
                                    <br />
                                    This can be reverted later.
                                </span>
                            </ContentColumn>
                        </TwoColumnContainer>
                    </Column>

                    {/* Delete Column */}
                    <Column>
                        <TwoColumnContainer>
                            <ControlColumn>
                                <GhostIconButtonRed 
                                     onClick={handleDeleteClick}
                                >
                                    <CustomIcons.TrashIcon size="xs" color="#ff0000"/>
                                </GhostIconButtonRed>
                            </ControlColumn>
                            <ContentColumn>
                                <h4 css={HeaderMedium600Style} style={{color: "#b71c1c"}}>Delete client</h4>
                                <span css={TextSmall500FadedStyle} style={{color: "#b71c1c"}}>
                                    Click to delete client from Citrus. This action cannot be undone.
                                </span>
                            </ContentColumn>
                        </TwoColumnContainer>
                    </Column>
                </TwoColumnMainContainer>
            </OptionsSection>

            {/* Deactivate Modal */}
            <StandardModal 
                open={showDeactivateModal} 
                onClose={() => setShowDeactivateModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title>Deactivate Client?</StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    Are you sure you want to deactivate this client? The client with all
                    its users will lose access to all models unless the client is activated
                    again.
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="Deactivate"
                    cancelText="Cancel"
                    confirmColor="#f5a623"
                    onConfirm={async () => {
                        await updateClientStatus(false);
                        setShowDeactivateModal(false);
                    }}
                    onCancel={() => setShowDeactivateModal(false)}
                />
            </StandardModal>

            {/* Delete Understanding Modal */}
            <StandardModal 
                open={showDeleteUnderstandModal} 
                onClose={() => setShowDeleteUnderstandModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title>Do you understand?</StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    Hold on. You are about to delete a client from Citrus. Do you understand 
                    that this action cannot be undone?
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="I understand"
                    cancelText="Take me back"
                    confirmColor="#b71c1c"
                    onConfirm={handleUnderstandClick}
                    onCancel={() => setShowDeleteUnderstandModal(false)}
                    flip={true}
                />
            </StandardModal>

            {/* Delete Modal */}
            <StandardModal 
                open={showDeleteModal} 
                onClose={() => setShowDeleteModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title style={{ color: "#b71c1c" }}>
                        Delete Client?
                    </StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    <div>
                        Do you want to delete the client? The client with all its users will
                        lose access to all models. Are you sure about this?
                    </div>
                    
                    {/* Preserve Access Section */}
                    <TwoColumnContainer style={{ marginTop: '2rem' }}>
                        <ControlColumn>
                            <StandardToggleButton
                                checked={isPreserveAccess}
                                onChange={() => setIsPreserveAccess(!isPreserveAccess)}
                            />
                        </ControlColumn>
                        <ContentColumn>
                            <h4 css={HeaderMedium600Style}>Preserve access</h4>
                            <span css={TextSmall500FadedStyle}>
                                Allow active users to maintain access to their saved reports. If unchecked, individual users will permanently lose access to all
                                logs, history, and datasets.
                            </span>
                        </ContentColumn>
                    </TwoColumnContainer>
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="Delete client"
                    cancelText="Take me back"
                    confirmColor="#b71c1c"
                    onConfirm={() => handleDeleteClient()}
                    onCancel={() => setShowDeleteModal(false)}
                />
            </StandardModal>
        </>
    );
};

export default Deactivate;
