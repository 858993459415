import React from 'react';
import { useNavigate } from "react-router-dom";
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import { StandardButton } from "../ui_reusable/styled_comps/Buttons";
import {
    DashboardContainer,
    SectionPanel,
    SectionHeader,
    SectionTitle
} from './styled_sub_comps/DashboardStyledSubComps';

const Learning = () => {
    const navigate = useNavigate();

    const sections = [
        { title: "Quick-Start Intro", path: "/learning/intro" },
        { title: "Academy", path: "/learning/academy" },
        { title: "FAQ", path: "/learning/faq" }
    ];

    return (
        <>
        
            <SimpleAccordion id={"learningDashboardAccordion"} defaultActiveKey={["1"]}>
                <SimpleAccordion.Panel 
                    header="Learning" 
                    eventKey="1"
                    noCollapse
                >
                    {sections.map((section) => (
                        <SectionPanel key={section.title}>
                            <SectionHeader>
                                <SectionTitle>{section.title}</SectionTitle>
                                <StandardButton
                                    onClick={() => navigate(section.path)}
                                >
                                    Modify
                                </StandardButton>
                            </SectionHeader>
                        </SectionPanel>
                    ))}
                </SimpleAccordion.Panel>
            </SimpleAccordion>
        

        </>
    );
}

export default Learning;