import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Message } from "rsuite";
import useStandardToaster from '../../ui_reusable/StandardToaster';

import VersatileGrid from '../../ui_reusable/VersatileGrid';
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText, ListInVersatileGrid, ListItemInVersatileGrid } from '../../ui_reusable/VersatileGridUtils';
import { StandardToggleButton } from '../../ui_reusable/styled_comps/ToggleButtons';
import { StandardCheckbox } from '../../ui_reusable/StandardInputs';
import { ToggleContainer } from '../../clients/managements/styled_sub_comps/UsersStyledSubComps';

const Users = () => {
    const [projectUsers, setProjectUsers] = useState([]);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [loading, setLoading] = useState(true);

    const { pushToast } = useStandardToaster();
    const location = useLocation();
    const {id} = location.state || {};


    // Fetch project users
    useEffect(()=>{
        const getProjectUsers = async () =>{
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/users`, { 
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const res = await response.json();
                
                setProjectUsers(res.data);
            } catch (error) {
                console.error('Failed to fetch project users:', error);
                setProjectUsers([]);
            } finally {
                setLoading(false);
            }
        }
        getProjectUsers();
    },[id, refresh]);

    // Handle scenario access toggle
    const handleToggleScenarioAccess = async(userId, scenario_access) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/users/${userId}`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            body: JSON.stringify({ has_scenario_access: scenario_access })
        });
        if (response.status === 200) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Scenario access updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update scenario access.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    // Add new handler for solution access toggle
    const handleSolutionAccessToggle = async (userId, solutionId, currentAccess) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/users/${userId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({solution: { project_solution_id: solutionId, access: !currentAccess }})
            });

            if (response.status === 200) {
                setRefresh(new Date().getTime());
                pushToast(<Message type="success">Solution access updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
            }
        } catch (error) {
            console.error('Failed to update solution access:', error);
            pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };
    // Grid configuration
    const headers = [
        {
            title: 'Name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Email',
            key: 'email',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Phone',
            key: 'phone',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Solution Access',
            key: 'solutions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            borderLeft: true
        },
        {
            title: 'Scenario Access',
            key: 'has_scenario_access',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            borderLeft: true
        },
        {
            title: 'Manager',
            key: 'role',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            borderLeft: true
        }
    ];
    return (
        <VersatileGrid
            headers={headers}
            data={projectUsers}
            renderRow={(rowData) => [
                <GridCellBoldText>{rowData.client_user.user.name}</GridCellBoldText>,
                <GridCellNormalText>{rowData.client_user.user.email}</GridCellNormalText>,
                <GridCellSmallFadedText>{rowData.client_user.user.phone}</GridCellSmallFadedText>,
                <ListInVersatileGrid>
                    {rowData.project_solutions.map((project_solution, i) => (
                        <ListItemInVersatileGrid key={i} style={{ display: 'flex', alignItems: 'center' }}>
                            <StandardCheckbox
                                checked={project_solution.has_access}
                                onChange={() => handleSolutionAccessToggle(rowData.client_user.user.id, project_solution.id, project_solution.has_access)}
                            >
                            </StandardCheckbox>
                            <GridCellSmallFadedText>{project_solution.solution.name}</GridCellSmallFadedText> 
                        </ListItemInVersatileGrid>
                    ))}
                </ListInVersatileGrid>,
                <ToggleContainer>
                    <StandardToggleButton
                        checked={rowData.has_scenario_access} 
                        onChange={() => handleToggleScenarioAccess(rowData.client_user.user.id, !rowData.has_scenario_access)}
                    />
                </ToggleContainer>,
                <ToggleContainer>
                    <StandardToggleButton
                        checked={rowData.client_user.role==='manager'}
                    />
                </ToggleContainer>
            ]}
            maxDisplayedItems={10}
            loading={loading}
        />
    );
}

export default Users;
