import React, { useState, useRef, useEffect } from 'react';
import CustomIcons from './CustomIcons';
import StringSearchMatchDisplayer from './logic/StringSearchMatchDisplayer';
import theme from '../../styles/theme';
import { 
    SearchContainer, 
    SearchInput, 
    SearchDropdown,
    DropdownItem,
    TagContainer,
    Tag,
    TagCloseButton,
    InputWrapper
} from './styled_sub_comps/StandardSearchFieldStyledSubComps';
import { searchStyle } from './styles/StandardSearchFieldStyles';


const StandardSearchField = ({ 
    data, 
    onChange, 
    placeholder = "Search...",
    maxSelected = Infinity,
    alwaysShowList = false,
    initValueList = [] // Add this new prop
}) => {
    const [searchText, setSearchText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const containerRef = useRef(null);

    // Add new useEffect for initialization and validation
    useEffect(() => {

        if (initValueList && initValueList.length > 0) {
            // Validate that all initial values exist in data
            const allExist = initValueList.every(initItem => 
                data.some(dataItem => dataItem.id === initItem.id)
            );

            if (!allExist) {
                console.error('StandardSearchField: Some initial values do not exist in data');
                return;
            }

            // If validation passes, set the initial selected items
            setSelectedItems(initValueList);
            onChange(initValueList);
        }
    }, [data]); // Only run on data changes

    // Filter items based on search text
    useEffect(() => {
        if (!searchText && alwaysShowList) {
            setFilteredItems(data.filter(item => 
                !selectedItems.some(selected => selected.id === item.id)
            ));
        } else if (!searchText) {
            setFilteredItems([]);
            return;
        }

        const filtered = data.filter(item => 
            item.name.toLowerCase().includes(searchText.toLowerCase()) &&
            !selectedItems.some(selected => selected.id === item.id)
        );
        setFilteredItems(filtered);
    }, [searchText, data, selectedItems, alwaysShowList]);

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleInputChange = (e) => {
        setSearchText(e.target.value);
        setShowDropdown(true);
    };

    const handleSelectItem = (item) => {
        if (selectedItems.length >= maxSelected) return;
        
        setSelectedItems([...selectedItems, item]);
        setSearchText('');
        setShowDropdown(false);
        onChange([...selectedItems, item]);
    };

    const handleRemoveTag = (itemToRemove) => {
        const newSelected = selectedItems.filter(item => item.id !== itemToRemove.id);
        setSelectedItems(newSelected);
        onChange(newSelected);
    };

    const handleInputFocus = () => {
        if (alwaysShowList && selectedItems.length < maxSelected) {
            setShowDropdown(true);
            setFilteredItems(data.filter(item => 
                !selectedItems.some(selected => selected.id === item.id)
            ));
        }
    };

    return (
        <SearchContainer ref={containerRef} css={searchStyle}>
            <InputWrapper >
                <CustomIcons.faSearch 
                    size={'small'}
                    
                    color={theme.colors.backgroundVeryDark}
                    noHover={true}
                />
                <TagContainer>
                    {selectedItems.map(item => (
                        <Tag key={item.id}>
                            {item.name}
                            <TagCloseButton onClick={() => handleRemoveTag(item)}>×</TagCloseButton>
                        </Tag>
                    ))}
                    {selectedItems.length < maxSelected && (
                        <SearchInput
                            value={searchText}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            placeholder={selectedItems.length === 0 ? placeholder : ""}
                        />
                    )}
                </TagContainer>
            </InputWrapper>

            {showDropdown && filteredItems.length > 0 && selectedItems.length < maxSelected && (
                <SearchDropdown>
                    {filteredItems.map(item => (
                        <DropdownItem
                            key={item.id}
                            onClick={() => handleSelectItem(item)}
                        >
                            <StringSearchMatchDisplayer 
                                searchTerms={searchText.split(/\s+/).filter(Boolean)}
                            >
                                {item.name}
                            </StringSearchMatchDisplayer>
                        </DropdownItem>
                    ))}
                </SearchDropdown>
            )}
        </SearchContainer>
    );
};

export default StandardSearchField;
