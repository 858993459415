import styled from '@emotion/styled';
import { PanelStyle, baseAccordionContainer, baseAccordionHeader } from '../styles/PanelStyle';
import { HeaderMedium600Style,HeaderLarge600Style } from '../../styles/TextStyles';
import theme from '../../../styles/theme';

export const AccordionContainer = styled.div`
  ${baseAccordionContainer}
  gap: 10px;
`;

export const PanelContainer = styled.div`
  ${PanelStyle}
  box-shadow: ${theme.shadows.light};
  background-color: ${props => props.color || 'white'} !important;
`;

export const HeaderText = styled.span`
  ${HeaderLarge600Style}
  color: ${theme.colors.primary};
  background-color: ${theme.colors.primaryVeryVeryLight};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  &:hover {
    background-color: ${theme.colors.primaryVeryVeryLight};
    border-radius: 50%;
  }
  svg {
    width: 1rem;
    height: 1rem;
    color: ${theme.colors.primary};
  }
`;

export const PanelHeader = styled.div`
  ${baseAccordionHeader}
  background-color: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;