import styled from '@emotion/styled';
import { HeaderMedium600Style,InfoLarge600Style,InfoXXXLarge600Style, InfoMedium500Style } from '../../../../styles/TextStyles';
import theme from '../../../../../styles/theme';

// Base container style shared by both components
export const BaseInfoContainer = styled.div`
   
    
    display: flex;
    align-items: center;
    border-radius: 0.7rem;
    margin-bottom: 0.625rem;
    background-color: ${theme.colors.primaryVeryVeryLight };
`;

// Specific to InfoBoxLong - horizontal layout
export const LongInfoContainer = styled(BaseInfoContainer)`
    padding: 1.25rem;
    justify-content: center;
    flex: 1 1 13rem;
    max-width: 20rem;
    
`;

export const LongInfoLabel = styled.span`
    ${InfoMedium500Style};
    color: ${theme.colors.standardText};
    margin: 0 0.313rem 0 0;
`;

export const LongInfoValue = styled.span`
    ${HeaderMedium600Style};
    color: ${theme.colors.standardText};
    margin: 0 0 0 0.313rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

// Specific to InfoBox - vertical layout
export const ShortInfoContainer = styled(BaseInfoContainer)`
    flex-direction: column;
    align-items: center;
    padding: 2rem;

`;

export const ShortInfoLabel = styled.span`
    ${InfoMedium500Style};
    color: ${theme.colors.standardText};
`;

export const ShortInfoValue = styled.span`
    ${InfoXXXLarge600Style};
`;
