import "@fontsource/inter";
import "@fontsource/source-code-pro";
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Global } from '@emotion/react';
import 'rsuite/dist/rsuite.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DocumentStyles } from './styles/DocumentStyles';
import { initDebugColors } from './utils/debugColors';

const root = ReactDOM.createRoot(document.getElementById('root'));
document.getElementById('root').className = 'top';

// Initialize debug colors if enabled
initDebugColors();

const version = process.env.REACT_APP_VERSION;

console.log(`Version: ${version}`);

root.render(
    <StrictMode>
        <Global styles={DocumentStyles} />
        <App />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
