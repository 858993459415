import React from 'react';
import styled from '@emotion/styled';
import CustomIcons from '../../../ui_reusable/CustomIcons';

const qualityColors = {
    good: {
        background: '#A6F4C5',
        icon: '#12B76A'
    },
    warning: {
        background: '#FEDF89',
        icon: '#F79009'
    },
    bad: {
        background: '#FFE5E5',
        icon: '#FF0000'
    }
};

const CircleContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${props => qualityColors[props.quality]?.background};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const QualityTag = ({ quality = 'good' }) => {
    return (
        <CircleContainer quality={quality}>
            <CustomIcons.CheckmarkCircleThickIcon
                size="small"
                color={qualityColors[quality]?.icon}
                clickDisabled={true}
            />
        </CircleContainer>
    );
};

export default QualityTag;
