import { css } from '@emotion/react';
import theme from '../../styles/theme';
import styled from '@emotion/styled';
/**
 * Base text css using Inter font with medium (500) weight
 * Inherits color from theme's standardText
 */

//large  1 and above
//medium below 1
//small below 0.8
const xxxxl  = '2.7rem';
const xxxl  = '1.75rem';
const xxl  = '1.4rem';
const xl  = '1.1rem';
const l       = '1.0rem';
const mMaxi  = '0.9rem';
const m       = '0.85rem';
const mMini      = '0.8rem';
const s       = '0.75rem';
const xs   = '0.7rem';
const xxs         = '0.67rem';
const xxxs      = '0.625rem';
//Text types
export const TextLarge500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${l};
  color: ${theme.colors.standardText};
`;
export const TextLarge600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${l};
  color: ${theme.colors.standardText};
`;


export const TextMedium500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${m};
  color: ${theme.colors.fadedText};
`;

export const TextMediumMaxi600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${mMaxi};
  color: ${theme.colors.fadedText};
`;



export const TextMediumMini500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${mMini};
  color: ${theme.colors.standardText};
`;

export const TextMediumMini400Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: ${mMini};
  color: ${theme.colors.fadedText};
`;
export const TextMediumMini400FadedStyle = css`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: ${mMini};
  color: ${theme.colors.veryFadedText};
`;

export const TextSmall500FadedStyle = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${s};
  color: ${theme.colors.fadedText};
`;

//Headers

//h1
export const HeaderXXXXLarge600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${xxxxl};
    color: ${theme.colors.standardText};
`;

//h1
export const HeaderXXLarge600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${xxl};
    color: ${theme.colors.standardText};
`;

//h1
export const HeaderExtraLarge600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${xl};
    color: ${theme.colors.standardText};
`;

//h1
export const HeaderLargeImportant = css`
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: ${xl};
    margin-bottom: 0.2rem;
    color: ${theme.colors.standardText};
`;


//h2
export const HeaderLarge600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${l};
    color: ${theme.colors.standardText};
`;

export const HeaderLarge500Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: ${l};
    color: ${theme.colors.standardText};
`;

export const HeaderMediumMaxi600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${mMaxi};
  color: ${theme.colors.fadedText};
`;

//h3

export const HeaderMedium500Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: ${m};
    color: ${theme.colors.standardText};
`;

export const HeaderMedium400Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: ${m};
    color: ${theme.colors.standardText};
`;

//h3
export const HeaderMedium600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${m};
`;

//h4
export const HeaderSmall600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${s};
    color: ${theme.colors.fadedText};
`;



//INFO and Tag styles

export const InfoXXXLarge600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${xxxl};
  color: ${theme.colors.standardText};
`;

export const InfoLarge600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${l};
  color: ${theme.colors.standardText};
`;

export const InfoLarge500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${l};
  color: ${theme.colors.standardText};
`;

export const InfoMediumMaxi500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${mMaxi};
  color: ${theme.colors.standardText};
`;

export const InfoMediumMaxi600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${mMaxi};
  color: ${theme.colors.fadedText};
`;

export const InfoMedium700Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: ${m};
  color: ${theme.colors.surface};

`;

export const InfoMedium600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${m};
  color: ${theme.colors.fadedText};
`;
export const InfoMedium500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${m};
  color: ${theme.colors.fadedText};
`;
export const InfoMediumMini500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${mMini};
  color: ${theme.colors.fadedText};
`;
export const InfoSmall500Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: ${s};
    color: ${theme.colors.veryFadedText};
`;

export const InfoSmall600Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: ${s};
    color: ${theme.colors.veryFadedText};
`;

export const InfoSmall700Style = css`
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: ${s};
    color: ${theme.colors.veryFadedText};
`;

export const InfoExtraSmall600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${xs};
  color: ${theme.colors.veryFadedText};
`;

export const InfoExtraSmall500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${xs};
  color: ${theme.colors.veryFadedText};
`;

export const InfoXXXSmall600Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: ${xxxs};
  color: ${theme.colors.veryFadedText};
`;

export const InfoXXXSmall500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: ${xxxs};
  color: ${theme.colors.veryFadedText};
`;


