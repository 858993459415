import React from 'react';
import GraphContainer from '../ui_reusable/GraphContainer';
import useDatasetAnalyze from './drivers/cache_query_handler/useDatasetAnalyze';
import { calculateDateRange } from '../../utils/helper';

// Add capitalize function
const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const DatasetGraph = ({ datasetId, action }) => {
    const { data: plotData, isLoading } = useDatasetAnalyze(datasetId, action);

    return (
        <GraphContainer
            title={capitalize(action)}
            data={plotData?.data}
            layout={plotData?.layout}
            config={plotData?.config}
            helpText={"This is the help text"}
            loading={isLoading}
        />
    );
};

export default React.memo(DatasetGraph);
