import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { StandardCheckbox } from './StandardInputs';
import { StandardRadio } from './StandardRadioButtons';

const HStackContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? 0.5 : 1};
    transition: opacity 0.2s ease;
    pointer-events: auto; // Changed from conditional to always auto to allow cursor to show

    &:hover {
        opacity: ${props => !props.disabled && 0.8};
    }
`;

const OneLineBtnHStack = ({ children, disabled, onClick, ...props }) => {
    const containerRef = useRef(null);

    const handleClick = (e) => {
        if (disabled) return;
        
        e.stopPropagation();
        
        // Find all children that can be clicked
        if (containerRef.current) {
            const elements = containerRef.current.children;
            Array.from(elements).forEach(element => {
                if (!element.disabled) {
                    // Try different methods to trigger click
                    if (element.click) {
                        element.click();
                    } else if (element.dispatchEvent) {
                        element.dispatchEvent(new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        }));
                    }
                }
            });
        }
        
        if (onClick) {
            onClick(e);
        }
    };

    // Update children cloning to pass disabled to all children
    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                disabled: disabled || child.props.disabled,
            });
        }
        return child;
    });

    return (
        <HStackContainer
            ref={containerRef}
            disabled={disabled}
            onClick={handleClick}
            {...props}
        >
            {childrenWithProps}
        </HStackContainer>
    );
};

export default OneLineBtnHStack;
