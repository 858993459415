import React, { createContext, useContext, useEffect, useState } from "react";
import { useClientContext } from "./ClientContext";
import { useAuth } from "./AuthContext";

const ClientProjectContext = createContext();

export const ClientProjectProvider = ({ children }) => {
  const [clientProjectData, setClientProjectData] = useState([]);
  const {isAuthenticated, user, isAdmin, isClientUser, isClientManager} = useAuth();
  const {selectedClient} = useClientContext();

  useEffect(() => {
    const fetchClientProjectData = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/?limit=99999`, {
              method: 'GET',
              headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              },
          });
  
          if (!response.ok) {
              throw new Error('Failed to fetch clients');
          }
  
          const result = await response.json();
          const clients = result.data;
  
          // Fetch projects for all clients in parallel
          const projectFetchPromises = clients.map(async (client) => {
              const projectsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/projects?client=${client.id}`, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
              },
              });
  
              if (!projectsResponse.ok) {
              throw new Error(`Failed to fetch projects for client ID ${client.id}`);
              }
  
              const projectsResult = await projectsResponse.json();
              const projects = projectsResult.data;
  
              // Combine client and project data
              return projects.map((project) => ({
                  client: { id: client.id, name: client.name, logo: client.logo },
                  project: { id: project.id, name: project.name, logo: project.logo },
              }));
          });
  
          // Wait for all project fetches to complete
          const clientProjectsArrays = await Promise.all(projectFetchPromises);
  
          // Flatten the array of arrays into a single array
          const clientProjects = clientProjectsArrays.flat();
  
          // Update state
          setClientProjectData(clientProjects);
      } catch (error) {
          console.error("Error fetching client-project data:", error);
      } 
  };
  
  const fetchProjectsData = async (url) => {
      try {
          const projectsResponse = await fetch(url, {
              method: 'GET',
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
              },
          });

          if (!projectsResponse.ok) {
          throw new Error(`Failed to fetch projects for client ID ${selectedClient.id}`);
          }

          const projectsResult = await projectsResponse.json();
          const projects = projectsResult.data;

          // Combine client and project data
          const clientProjects = projects.map((project) => ({
              client: { id: selectedClient.id, name: selectedClient.name, logo: selectedClient.logo },
              project: { id: project.id, name: project.name, logo: project.logo },
          }));
          setClientProjectData(clientProjects);
      } catch (error) {
          console.error("Error fetching client-project data:", error);
      }
    };
    if(isAuthenticated){
        if(isAdmin){
            fetchClientProjectData();
        } else if(isClientManager && selectedClient){
            fetchProjectsData(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}`);
        }
        else if(isClientUser && selectedClient){
            fetchProjectsData(`${process.env.REACT_APP_API_URL}/api/projects?client=${selectedClient.id}&user=${user.id}`);
        }
    }
  }, [isAuthenticated, user, isAdmin, isClientUser, isClientManager, selectedClient]);

  return (
    <ClientProjectContext.Provider value={{ clientProjectData, setClientProjectData }}>
      {children}
    </ClientProjectContext.Provider>
  );
};

// Custom hook to use ClientProjectContext
export const useClientProjectContext = () => {
  return useContext(ClientProjectContext);
};
