import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

// Base loading container to maintain fixed width during animation
const LoadingContainer = styled.span`
  display: inline-block;
  min-width: 2em;  // Increased to accommodate larger dots
  text-align: left;
`;

// Enhanced loading animation with fade effect
const LoadingTextAnimation = styled.span`
  @keyframes dotFade {
    0%, 100% { opacity: 0.2; }
    20% { opacity: 1; }
    40% { opacity: 0.2; }
  }

  & > span {
    opacity: 0.2;
    animation: dotFade 1.4s infinite;
    margin-left: 2px;  // Increased spacing between dots
    font-size: 1.2em;  // Make dots 20% bigger than parent text
    
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
`;

// Optional pulse animation that can be added via css prop
export const pulseAnimation = css`
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  animation: pulse 2s infinite;
`;

// Optional shimmer animation that can be added via css prop
export const shimmerAnimation = css`
  @keyframes shimmer {
    0% { color: inherit; }
    50% { color: #a0a0a0; }
    100% { color: inherit; }
  }
  animation: shimmer 2s infinite;
`;

const LoadingText = ({ 
    children, 
    isLoading, 
    css,
    ...props 
}) => {
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        // Check if children is empty, handling both string and React elements
        setIsEmpty(!children || 
            (typeof children === 'string' && children.trim() === '') ||
            (Array.isArray(children) && children.length === 0));
    }, [children]);

    if (isLoading || isEmpty) {
        return (
            <span css={css} {...props}>
                <LoadingContainer>
                    <LoadingTextAnimation>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </LoadingTextAnimation>
                </LoadingContainer>
            </span>
        );
    }

    return (
        <span css={css} {...props}>
            {children}
        </span>
    );
};

export default LoadingText;
