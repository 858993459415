import React, { useState } from 'react';
import { Text } from 'rsuite';
import Cookies from 'js-cookie';
import StandardModal from '../ui_reusable/StandardModal';
import { StandardCheckbox } from '../ui_reusable/StandardInputs';
import styled from '@emotion/styled';

const StyledHeading = styled.h4`
    margin-bottom: 16px;
`;

const ExampleBlock = styled.pre`
    background-color: #f8f9fa;
    padding: 12px;
    border-radius: 4px;
    margin: 12px 0;
`;

const CheckboxContainer = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const COOKIE_NAME = 'hideFileValidation';

const FileValidationModal = ({ 
    open, 
    handleClose, 
    onProceed, 
    onHideInFutureChanged, 
    mode = 'proceed' 
}) => {

    const [hideInFuture, setHideInFuture] = useState(false);
    const handleCheckboxChange = (checked) => {

        // Call the callback immediately when checkbox changes
        onHideInFutureChanged?.(checked);
    };

    const handleProceed = () => {
        setTimeout(() => {
            handleCheckboxChange(hideInFuture);
        }, 1000);
        
        onProceed?.();
        handleClose();
    };

    return (
        <StandardModal 
            open={open} 
            onClose={handleClose}
            size="md"
            backdrop="static"
        >
            <StandardModal.Body>
                <StyledHeading>How your excel file should be formatted?</StyledHeading>
                <Text>Before uploading your excel files, please make sure it follows the required format:</Text>
                <br/>
                <Text><b>Column 1: </b>Date(Format: YYYY-MM-DD)</Text>
                <Text><b>Column 2: </b>Measures(e.g., Quality, Value)</Text>
                <Text><b>Remaining Columns: </b>Dimensions(e.g., Location, Category...)</Text>
                <br/>
                <Text>Examples:</Text>
                <ExampleBlock>
                    <p>Date        | Value | Location  | Quantity</p>
                    <p>2024-01-01  | 500   | Norway    | 100</p>
                    <p>2024-01-02  | 750   | Sweden    | 150</p>
                </ExampleBlock>
                <Text>This is an example excel file, click <a href="/">here</a> to download.</Text>
                {mode === 'proceed' && (
                    <CheckboxContainer>
                        <StandardCheckbox
                            onChange={setHideInFuture}
                            initState={false}
                        />
                        <Text>Don't show this info again</Text>
                    </CheckboxContainer>
                )}
            </StandardModal.Body>
            <StandardModal.DefaultFooter 
                confirmText="Ok"
                onConfirm={handleProceed}
            />
        </StandardModal>
    );
};

export default FileValidationModal;
