import styled from '@emotion/styled';
import { HeaderXXXXLarge600Style } from '../../styles/TextStyles';
import theme from '../../../styles/theme';
export const SolutionPosterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
`;

export const SolutionPoster = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem;
    background: ${props => props.theme.colors.primaryVeryLight};
    padding: 2rem 3rem;
`;

export const SolutionPosterTitle = styled.h1`
    ${HeaderXXXXLarge600Style};
    color: ${theme.colors.veryVeryFadedText};
    text-transform: uppercase;
    margin: 0;
`;
