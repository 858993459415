import { useQuery } from 'react-query';

const fetchDatasetAnalyze = async ({ datasetId, action }) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/datasets/${datasetId}/analyze?action=${action}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }
    );
    if (response.status === 200) {
        const result = await response.json();
        return result.data;
    }
    throw new Error('Failed to fetch dataset analysis');
};

const useDatasetAnalyze = (datasetId, action) => {
    return useQuery(
        ['datasetAnalyze', datasetId, action],
        () => fetchDatasetAnalyze({ datasetId, action }),
        {
            staleTime: 5 * 60 * 1000,
            cacheTime: 30 * 60 * 1000,
        }
    );
};

export default useDatasetAnalyze;
