import React from "react";
import { Message } from "rsuite";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStandardToaster from '../../../ui_reusable/StandardToaster';
import CustomIcons from '../../../ui_reusable/CustomIcons';


import VersatileGrid from '../../../ui_reusable/VersatileGrid';
import { GridCellNormalText } from '../../../ui_reusable/VersatileGridUtils';
import { EasyIconButton } from '../../../ui_reusable/styled_comps/Buttons';
import { StandardToggleButton } from '../../../ui_reusable/styled_comps/ToggleButtons';
import { StandardCheckbox } from '../../../ui_reusable/StandardInputs';
import { ToggleContainer } from '../styled_sub_comps/SolutionsStyledSubComps';
import theme from "../../../../styles/theme";
const Solutions = () => {
    const [solutions, setSolutions] = useState([]);
    const [projectSolutions, setProjectSolutions] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = location.state || {};
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getSolutions = async () =>{
            setLoading(true); // Set loading to true when fetching starts
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/solutions?limit=9999`, { 
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const res = await response.json();
                setSolutions(res.data);
            } catch (error) {
                console.error('Failed to fetch solutions:', error);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        }
        getSolutions();
    },[]);

    useEffect(() => {
        const getProjectSolutions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const res = await response.json();
                setProjectSolutions(res.data);
            } catch (error) {
                console.error('Failed to fetch project solutions:', error);
                pushToast(<Message type="error">Failed to load project solutions.</Message>, { placement: 'topCenter', duration: 5000 });
            }
        };
        if (id) {
            getProjectSolutions();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, refresh]);

    const handleToggleProjectSolution = async(solutionId, status) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                method: 'PATCH',
                body: JSON.stringify({ is_active: status })
            });
            
            if (response.status === 200) {
                setRefresh(new Date().getTime());
                pushToast(<Message type="success">Solution access updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
            }
        } catch (error) {
            console.error('Failed to toggle project solution:', error);
            pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    const handleCreateProjectSolution = async(solutionId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                method: 'POST',
                body: JSON.stringify({ solution_id: solutionId })
            });

            if (response.status === 201) {
                setRefresh(new Date().getTime());
                pushToast(<Message type="success">Solution added to project successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                pushToast(<Message type="error">Failed to add project solution.</Message>, { placement: 'topCenter', duration: 5000 });
            }
        } catch (error) {
            console.error('Failed to create project solution:', error);
            pushToast(<Message type="error">Failed to add project solution.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    const handleCheckboxChange = async(rowId, featureKey) => {
        const projectSolution = projectSolutions.find(elem => elem.solution.id === rowId);
        if (!projectSolution) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${rowId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                method: 'PATCH',
                body: JSON.stringify({
                    features: {
                        ...projectSolution.features,
                        [featureKey]: !projectSolution.features[featureKey],
                    }
                })
            });

            if (response.status === 200) {
                setRefresh(new Date().getTime());
                pushToast(<Message type="success">Solution features updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                pushToast(<Message type="error">Failed to update solution features.</Message>, { placement: 'topCenter', duration: 5000 });
            }
        } catch (error) {
            console.error('Failed to update feature:', error);
            pushToast(<Message type="error">Failed to update solution features.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    const handleManage = (id, solutionId) => {
        navigate('/projects/'+id+'/solutions/'+solutionId, {state: {id, solutionId}});
    }

    // Grid configuration
    const headers = [
        {
            title: '',
            key: 'is_active',
            type: 'boolean',
            isSortable: false,
            isSearchable: false
        },
        {
            title: 'Variables',
            key: 'variables',
            type: 'number',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Models',
            key: 'models',
            type: 'number',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Saved',
            key: 'saved',
            type: 'number',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Scenario',
            key: 'scenario_access',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            center: true,
            borderLeft: true
        },
        {
            title: 'AI+',
            key: 'ai',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            center: true,
            borderLeft: true
        },
        {
            title: 'KPI+',
            key: 'kpi',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Segment+',
            key: 'segment',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            center: true,

        },
        {
            title: 'Geo+',
            key: 'geo',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            center: true,

        },
        {
            title: 'Report+',
            key: 'report',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            
            borderLeft: true,

        },
        {
            //title: 'View / History / Configure',
            title: 'Configure',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            borderLeft: true,
            center: false
        }
    ];

    return (
        <>
            <VersatileGrid
                headers={headers}
                data={solutions}
                gridContainerStyle ={{gridTemplateColumns: `6fr 1.5fr 1.5fr 1.5fr 2.4fr 1fr 1fr 1fr 1fr 1fr 4fr`}}
                renderRow={(rowData,rowIdx) => [
                    <ToggleContainer>
                        <StandardToggleButton
                            color="green"
                            disabled={!rowData.is_active}
                            checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                            onChange={async () => {
                                const projectSolution = projectSolutions.find(e => e.solution.id === rowData.id);
                                if (projectSolution) {
                                    await handleToggleProjectSolution(projectSolution.solution.id, !projectSolution.is_active);
                                } else {
                                    await handleCreateProjectSolution(rowData.id);
                                }
                            }}
                        >
                            {rowData.name}
                        </StandardToggleButton>
                    </ToggleContainer>,
                    <GridCellNormalText>{rowData.variables}</GridCellNormalText>,
                    <GridCellNormalText>{rowData.models}</GridCellNormalText>,
                    <GridCellNormalText>{rowData.saved}</GridCellNormalText>,
                    <ToggleContainer>
                        <StandardToggleButton
                            disabled={!rowData.is_active || !projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                            checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.scenario_access)}
                            onChange={() => handleCheckboxChange(rowData.id, "scenario_access")}
                        />
                    </ToggleContainer>,
                    <StandardCheckbox
                        key={`${rowData.id}-ai-${!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.ai)}`}
                        disabled={!rowData.is_active || !projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                        checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.ai)}
                        onChange={() => handleCheckboxChange(rowData.id, "ai")}
                    />,
                    <StandardCheckbox
                        disabled={!rowData.is_active || !projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                        checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.kpi)}
                        onChange={() => handleCheckboxChange(rowData.id, "kpi")}
                    />,
                    <StandardCheckbox
                        disabled={!rowData.is_active || !projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                        checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.segment)}
                        onChange={() => handleCheckboxChange(rowData.id, "segment")}
                    />,
                    <StandardCheckbox
                        disabled={!rowData.is_active || !projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                        checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.geo)}
                        onChange={() => handleCheckboxChange(rowData.id, "geo")}
                    />,
                    <StandardCheckbox
                        disabled={!rowData.is_active || !projectSolutions.find(e => e.solution.id === rowData.id && e.is_active)}
                        checked={!!projectSolutions.find(e => e.solution.id === rowData.id && e.features.report)}
                        onChange={() => handleCheckboxChange(rowData.id, "report")}
                    />,
                    <div style={{display: 'flex'}}>
                        {/*
                        <EasyIconButton>
                            <CustomIcons.EyeIcon 
                                color={theme.colors.veryFadedText}
                                size="xs" 
                                disabled={!rowData.is_active || !!projectSolutions.find(e => e.solution.id === rowData.id && !e.is_active)} 
                            />
                        </EasyIconButton>
                        <EasyIconButton>
                            <CustomIcons.ProcessIcon 
                                size="xs"
                                color={theme.colors.veryFadedText}
                                disabled={!rowData.is_active || !!projectSolutions.find(e => e.solution.id === rowData.id && !e.is_active)} 
                            />
                        </EasyIconButton>
                         */}
                        <EasyIconButton
                            onClick={() => handleManage(id, rowData.id)}
                            disabled={!rowData.is_active || !!projectSolutions.find(e => e.solution.id === rowData.id && !e.is_active)} 
                        >
                            <CustomIcons.PenIcon 
                                    color={theme.colors.veryFadedText}
                                size="xs" 
                                disabled={!rowData.is_active || !!projectSolutions.find(e => e.solution.id === rowData.id && !e.is_active)} 
                            />
                        </EasyIconButton>
                    </div>
                ]}
                maxDisplayedItems={10}
                loading={loading}
                showHeader={false}
            />
        </>
    );
};

export default Solutions;
