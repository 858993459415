import React, { useState, useRef, useEffect } from 'react';
import {
    BaseSelectPickerWrapper,
    PickerToggle,
    PickerMenuWrapper, // Add this import
    PickerMenu,
    MenuItem,
    ThickSelectPickerStyled,
    FormSelectPickerStyled,
    FormDateRangePickerStyled,
    ToggleText // Add this import
} from './styles/StandardSelectPickerStyles';
import theme from '../../styles/theme';

import CustomIcons from './CustomIcons';
// Base SelectPicker component
const SelectPicker = ({ 
    data = [], 
    defaultValue = '', 
    value, // Add this prop
    onChange, 
    placeholder = 'Select',
    className,
    disabledItemValues = [],
    style  // Add style prop
}) => {
    const [isOpen, setIsOpen] = useState(false); // Add this line
    // Use value prop if provided, otherwise use defaultValue
    const [selectedValue, setSelectedValue] = useState(value || defaultValue);
    const wrapperRef = useRef(null);

    // Validate disabledItemValues
    useEffect(() => {
        const invalidDisabledValues = disabledItemValues.filter(
            value => !data.some(item => item.value === value)
        );
        
        if (invalidDisabledValues.length > 0) {
            console.warn(
                `Warning: The following disabled values do not exist in the data array: ${invalidDisabledValues.join(', ')}`
            );
        }
    }, [data, disabledItemValues]);

    // Add effect to update selected value when value prop changes
    useEffect(() => {
        if (value !== undefined) {
            setSelectedValue(value);
        }
    }, [value]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelect = (value, label) => {
        if (disabledItemValues.includes(value)) {
            return;
        }
        setSelectedValue(value);
        setIsOpen(false);
        if (onChange) onChange(value);
    };

    const displayValue = data.find(item => item.value === selectedValue)?.label || placeholder;

    return (
        <BaseSelectPickerWrapper 
            ref={wrapperRef} 
            className={className}
            customStyle={style} // Pass the style as customStyle
        >
            <PickerToggle onClick={() => setIsOpen(!isOpen)}>
                <ToggleText>{displayValue}</ToggleText>
                <CustomIcons.ChevronDownIcon size="xxs" color={theme.colors.veryVeryFadedText}/>
            </PickerToggle>
            <div style={{width: '100%', height: 0, position: 'relative', zIndex: 1, display: 'flex'}}>
            
                {isOpen && (
                    <PickerMenu>
                        {data.map((item) => (
                            <MenuItem
                                key={item.value}
                                onClick={() => handleSelect(item.value, item.label)}
                                className={`
                                ${selectedValue === item.value ? 'selected' : ''}
                                ${disabledItemValues.includes(item.value) ? 'disabled' : ''}
                            `}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </PickerMenu>
                )}
                </div>
            
        </BaseSelectPickerWrapper>
    );
};

// Updated Thick and Form variants using the custom SelectPicker
const ThickSelectPicker = ({ ...props }) => {
    return (
        <SelectPicker 
            {...props}
            className="thick-select-picker"
        />
    );
};

const FormSelectPicker = ({ ...props }) => {
    return (
        <SelectPicker 
            {...props}
            className="form-select-picker"
        />
    );
};

const FormDateRangePicker = ({ ...props }) => {
    return (
        <FormDateRangePickerStyled 
            {...props}
            cleanable={false}
        />
    );
};

export { ThickSelectPicker, FormSelectPicker, FormDateRangePicker, SelectPicker };
