import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import styled from '@emotion/styled';
import { TextMediumMini500Style, InfoMedium500Style,HeaderMedium400Style, HeaderMedium500Style, InfoMedium700Style,InfoSmall500Style } from '../../styles/TextStyles';
import CustomIcons from '../CustomIcons';

export const StandardButton = styled.button`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.surface};
  border-radius: 0.5rem;
  font-family: Inter;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0.6rem;
  border: none;
  cursor: pointer;
  width: auto;
  &:hover {
    opacity: 0.8;
  }
`;

export const StandardButtonFaded = styled.button`
  background-color: ${theme.colors.primaryFaded};
  color: ${theme.colors.surface};
  border-radius: 0.5rem;
  font-family: Inter;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0.6rem;
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  width: auto;
  opacity: ${props => props.disabled ? 0.3 : 1};
  &:hover {
    opacity: ${props => props.disabled ? 0.3 : 0.8};
  }
`;

export const SmallStandardButton = styled.button`
    background-color: ${theme.colors.primaryFaded};
    color: ${theme.colors.primaryVeryVeryLight};
    border-radius: 0.67rem;
    font-family: Inter;
    font-weight: 500;
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    border: none;
    cursor: pointer;
    width: auto;
    &:hover {
        opacity: 0.8;
    }
`;

export const SecondarySmallButton = styled.button`
    ${TextMediumMini500Style}
    background-color: ${theme.colors.surface};
    color: ${theme.colors.backgroundVeryDark};
    border-radius: 0.67rem;
    font-family: Inter;
    outline: ${theme.colors.backgroundVeryDark} 1px solid;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    border: none;
    cursor: pointer;
    width: auto;
    &:hover {
        opacity: 0.8;
    }
`;

export const circleButtonStyle = css`
    width: 2.8rem;
    height: 2.8rem;
    min-width: 2.8rem;
    min-height: 2.8rem;
    flex: 0 0 2.8rem;
    border-radius: 50%;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #f5f5f5;
    }

    &:active {
        background-color: #d1d1d1;
    }

    svg, img {
        width: 1.4rem;
        height: 1.4rem;
    }
`;

export const CircleButton = styled.button`
    ${circleButtonStyle}
    --icon-color: ${theme.colors.standardText};
    
    &:hover {
        --icon-color: '#000';
    }
`;

export const GhostButton = styled.button`
  ${HeaderMedium400Style}
  background: transparent;
  outline: 1px solid ${theme.colors.veryFadedText};
  color: ${theme.colors.standardText};
  min-width: 3.2rem;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 0.5rem;
  border: none;
  
  &:hover {
    outline: 1px solid ${theme.colors.standardText};
    color: ${theme.colors.standardText};
  }
`;



export const WarningButton = styled.button`
  ${InfoMedium700Style}
  background-color: #f5a623;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

export const DangerButton = styled.button`
  ${InfoMedium700Style}
  background-color: #b71c1c;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

export const GhostIconButton = styled(GhostButton)`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 16px;
    height: 16px;

  }
`;

export const GhostIconButtonRed = styled(GhostIconButton)`
    outline: 1px solid red;
    &:hover {
        outline: 1px solid #880000;
  }
    

`;

export const ModalButtonConfirm = styled(StandardButton)`
  ${InfoSmall500Style};
  color: ${theme.colors.surface};
  min-width: 6rem;
  margin: 0;
`;

export const ModalButtonCancel = styled(GhostButton)`
  ${InfoSmall500Style};
  min-width: 6rem;
  margin: 0;
`;

export const ModalButtonStandardGhost = styled(GhostButton)`
  ${InfoSmall500Style};
  min-width: 6rem;
  margin: 0;
`;

export const EasyIconButton = styled.button`

  padding: 0.5rem;
  background-color: transparent;
  border-radius: 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;

 
`;

export const SurfaceGhostIconButton = styled.button`

  padding: 0.5rem;
  background-color: ${theme.colors.surface};
  border-radius: 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px solid ${theme.colors.backgroundVeryDark};
  height: 2rem;
  width: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  margin-bottom: 0.2rem;

  &:hover {
    background-color: ${theme.colors.surfaceSecondary};
  }

 
`;

export const RunButton = styled.button`
  background-color: #12B76A;
  color: ${theme.colors.surface};
  border-radius: 0.5rem;
  font-family: Inter;
  font-weight: 500;
  font-size: 0.9rem;
  padding-left: 1.4rem;
  padding-right: 1.0rem;
  padding-top: 0.9rem;
  padding-bottom: 0.7rem;
  border: none;
  cursor: pointer;
  width: auto;
  display: flex;
  flex-direction: row;
  &:hover {
    opacity: 0.8;
  }
  //affect all children
  & > * {
    
  }

  svg {
    padding-left: 0.5rem;
    padding-right: 0.3rem;
  }
`;

const RunButtonWithIcon = ({ children, ...props }) => (
  <RunButton {...props}>
    
    {children}
    <CustomIcons.CheckCircleIcon size="xs" color="white"/>
  </RunButton>
);

export default RunButtonWithIcon;