import styled from '@emotion/styled';
import { SecondaryPanelStyle,PanelStyle } from '../styles/PanelStyle';
import theme from '../../../styles/theme';

export const Panel = styled.div`
  ${PanelStyle}
  box-shadow: ${theme.shadows.light};
  background-color: ${theme.colors.surface};
`;

export const PanelSecondary = styled.div`
  ${SecondaryPanelStyle}
`;