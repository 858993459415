import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Message } from "rsuite";

// Import custom components
import VersatileGrid from '../ui_reusable/VersatileGrid';
import { HeaderContainer } from "../ui_reusable/styled_comps/PlacementMisc";
import SectionTitle from "../ui_reusable/SectionTitle";
import ClientProjectPicker from "../comp_reusable/ClientProjectPicker";
import { EasyIconButton } from '../ui_reusable/styled_comps/Buttons';
import { TagSuccess, TagWarning, TagDanger,VerySmallNormalTag } from "../ui_reusable/styled_comps/Tags";
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../ui_reusable/VersatileGridUtils';
import CustomIcons from '../ui_reusable/CustomIcons';
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import useStandardToaster from '../ui_reusable/StandardToaster';
import NoteModal from './report/ui_reusable/NoteModal';

// Import context
import { useProjectContext } from '../../context/ProjectContext';

// Import styled components
import { ActionButtonsContainer, StatsContainer } from './managements/styled_sub_comps/ListStyledSubComps';
import { HeaderMedium600Style } from "../styles/TextStyles";
import { SmallNormalTag } from '../ui_reusable/styled_comps/Tags';
import theme from "../../styles/theme";
import { getFitnessTag } from '../../utils/ScoreFitnessValidation';

const ModelList = () => {
    const [artifacts, setArtifacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { selectedProject } = useProjectContext();
    const { pushToast } = useStandardToaster();
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);

    // Fetch artifacts
    useEffect(() => {
        const fetchArtifacts = async () => {
            setLoading(true);
            try {
                let url = `${process.env.REACT_APP_API_URL}/api/artifacts`;
                if(selectedProject) {
                    url += `?filter_by=project&project=${selectedProject.id}`;
                }
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const result = await response.json();
                setArtifacts(result.data);
            } catch (error) {
                pushToast(
                    <Message type="error">Failed to load models</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } finally {
                setLoading(false);
            }
        };
        fetchArtifacts();
    }, [selectedProject]);

    // Grid configuration
    const headers = [
        {
            title: 'Name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true,
        },
        {
            title: 'Note',
            key: 'note',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        },
        {
            title: 'Solution',
            key: 'solution',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Last run date',
            key: 'run_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Last Fit score',
            key: 'fit_score',
            type: 'number',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Created by',
            key: 'created_by',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'View /  Run /  Report /  Delete',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        }
    ];

    // Grid header stats
    const gridHeader = (
        <StatsContainer>
            <span css={HeaderMedium600Style}>Saved Models: {artifacts.length}</span>
            
        </StatsContainer>
    );

    const handleShowNote = (rowData) => {
        setSelectedNote(rowData);
        setShowNoteModal(true);
    };

    console.log("artifacts",artifacts);
    return (
        <>
            <HeaderContainer>
                <SectionTitle>My models</SectionTitle>
                <ClientProjectPicker />
            </HeaderContainer>

            <SimpleAccordion id={"modelListPageAccordion"} defaultActiveKey={['1']}>
                <SimpleAccordion.Panel
                    header="Model List"
                    eventKey="1"
                    noCollapse
                >
                    <VersatileGrid
                        gridContainerStyle ={{gridTemplateColumns: `1.5fr 0.4fr 0.6fr 0.6fr 0.6fr 1fr 1fr`}}
                        headers={headers}
                        data={artifacts}
                        renderRow={(rowData) => [
                            <GridCellBoldText>{rowData.name}</GridCellBoldText>,
                            <CustomIcons.faFile 
                                onClick={() => handleShowNote(rowData)}
                                color={theme.colors.fadedText} 
                                solid={false} 
                                size="xs" 
                                style={{ cursor: 'pointer' }}
                            />,
                            <GridCellNormalText>
                                <VerySmallNormalTag>{rowData?.project_solution?.solution.name}</VerySmallNormalTag>
                            </GridCellNormalText>,
                            <GridCellSmallFadedText>
                                {new Date(rowData.run_at).toLocaleDateString()}
                            </GridCellSmallFadedText>,
                            getFitnessTag(rowData.fit_score),
                            <GridCellNormalText>{rowData.created_by.name}</GridCellNormalText>,
                            <ActionButtonsContainer>
                                
                                    <CustomIcons.PenIcon color={theme.colors.fadedText} size="xs" />
                                    <CustomIcons.ProcessIcon color={theme.colors.fadedText}  size="xs" />
                                    <CustomIcons.faFileLines 
                                        onClick={() => navigate(`/models/${rowData.id}/report`, {state: { id: rowData.id } })} 
                                         color={theme.colors.fadedText} solid={false} size="xs" 
                                    />
                                    <CustomIcons.TrashIcon color={theme.colors.fadedText} size="xs" />
                                
                            </ActionButtonsContainer>
                        ]}
                        headerInfo={gridHeader}
                        maxDisplayedItems={10}
                        loading={loading}
                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>

            <NoteModal
                open={showNoteModal}
                onClose={() => setShowNoteModal(false)}
                title={selectedNote?.name}
            />
        </>
    );
};

export default ModelList;