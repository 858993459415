import styled from '@emotion/styled';
import { InfoMediumMini500Style } from '../../../styles/TextStyles';
import theme from '../../../../styles/theme';

export const Container = styled.div`
    flex: 1 1 200px; /* Grow, shrink, and start with 200px width */
    max-width: 16rem;
    height: 3.6rem;
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 1rem 1.7rem 0.5rem 2rem;
    background-color: ${theme.colors.surface};
`;

export const CustomHStack = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    height: 100%;
`;

export const LabelValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    //Make them move away from each other as much as possible vertically:
    justify-content: space-between;

`;

export const ValueContainer = styled.div`
    //make child center vertically
    display: flex;
    align-items: center;
    flex-grow: 1;


`;

export const Label = styled.span`
    ${InfoMediumMini500Style};
    color: ${theme.colors.fancyText};
`;

export const SymbolContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
