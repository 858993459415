import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'rsuite';
import styled from '@emotion/styled';

const LoaderOverlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 800;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    &.visible {
        opacity: 1;
    }
`;

const UniversalLoader = ({ show, content = "Loading..." }) => {
    const [isVisible, setIsVisible] = useState(false);
    const overlayRef = useRef(null);

    useEffect(() => {
        if (show && overlayRef.current) {
            const parent = overlayRef.current.parentElement;
            if (parent) {
                const parentStyles = window.getComputedStyle(parent);
                if (parentStyles.position === 'static') {
                    parent.style.position = 'relative';
                    parent.style.zIndex = 0;
                }

                // Use RAF to ensure smooth transition
                requestAnimationFrame(() => {
                    setIsVisible(true);
                });
            }
        } else {
            setIsVisible(false);
        }
    }, [show]);



    if (!show) return null;

    return (
        <LoaderOverlay 
            ref={overlayRef}
            className={isVisible ? 'visible' : ''}
        >
            <Loader content={content} vertical size="md" />
        </LoaderOverlay>
    );
};

export default UniversalLoader;
