import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Message } from "rsuite";

import SectionTitle from "../../../ui_reusable/SectionTitle";
import SimpleAccordion from "../../../ui_reusable/SimpleAccordion";
import { StandardDescription } from '../../../ui_reusable/StandardInputs';
import { StandardButton } from '../../../ui_reusable/styled_comps/Buttons';
import { StandardDateRangePicker } from '../../../ui_reusable/StandardDatePicker';
import {HeaderContainer }from '../../../ui_reusable/styled_comps/PlacementMisc'
import useStandardToaster from "../../../ui_reusable/StandardToaster";
// Icons
import chatgpt_icon from '../../../../assets/ChatGPT_logo.svg';
import claude_icon from '../../../../assets/claude-color.svg';
import gemini_icon from '../../../../assets/google-gemini-icon.svg';
import  {useProjectContext } from '../../../../context/ProjectContext';
import ClientProjectPicker from '../../../comp_reusable/ClientProjectPicker';
import {RadioProvider,StandardRadio} from '../../../ui_reusable/StandardRadioButtons';
import {PanelNormalIndent} from '../../../ui_reusable/styled_comps/PlacementMisc';
import theme from '../../../../styles/theme';
// Styled components
import {
    ConfigureContainer,
    TimeframeContainer,
    AISettingsSection,
    AIOptionGroup,
    ContextTextareaContainer,
    ContextTitle,
    ButtonContainer,
    AILogoContainer,
    AITitle,
    AIDescription,
    RadioButtonKeeper,
    AIContainer,
    AIInfoContainer,
    ContextDescription,
    HStack
} from './styled_sub_comps/ConfigureStyledSubComps';

import GeneralDriverList from '../../../comp_reusable/GeneralDriverList';
import { useClientContext } from "../../../../context/ClientContext";

const ConfigProjectSolution = () => {
    const location = useLocation();
    const [projectSolution, setProjectSolution] = useState("");
    const {id, solutionId} = location.state || {};
    const [LLM, setLLM] = useState('');
    const [personality, setPersonality] = useState('');
    const [strategicContext, setStrategicContext] = useState("");
    const [companyContext, setCompanyContext] = useState("");
    const [projectContext, setProjectContext] = useState('');
    const [industryContext, setIndustryContext] = useState("");
    const [competitiveContext, setCompetitiveContext] = useState("");
    const [timeframe, setTimeframe] = useState({
        from: new Date(),
        to: new Date(),
    });
    const [datasets, setDatasets] = useState([]);
    const [projectSolutionDrivers, setProjectSolutionDrivers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const {selectedClient} = useClientContext();
    const {selectedProject} = useProjectContext();
    const { pushToast } = useStandardToaster();

    // Create refs for each AI option outside of the map function
    const chatgptRef = React.useRef(null);
    const claudeRef = React.useRef(null);
    const geminiRef = React.useRef(null);

    // Create a map of refs
    const aiOptionRefs = {
        'chatgpt_4': chatgptRef,
        'claude': claudeRef,
        'gemini': geminiRef
    };

    useEffect(()=>{
        const getProjectSolution = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setProjectSolution(data);
            if(data.ai_settings){
                setLLM(data.ai_settings.llm);
                setPersonality(data.ai_settings.personality);
                setStrategicContext(data.ai_settings.strategic_context);
                setProjectContext(data.ai_settings.project_context);
                setCompanyContext(data.ai_settings.company_context);
                setIndustryContext(data.ai_settings.industry_context);
                setCompetitiveContext(data.ai_settings.competitive_context);
                setTimeframe(data.timeframe);
            }
        }
        const getProjectSolutionDrivers = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}/drivers?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setProjectSolutionDrivers(res.data);
            console.log(res.data);
            const responseClientDriver = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${selectedClient.id}/datasets?limit=99999`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            const clientDrivers = await responseClientDriver.json();
            const datasetsData = clientDrivers.data
            .filter(clientDriver => clientDriver.is_active) 
            .map(clientDriver => {
                const selectedDriver = res.data.find(e => e.driver.id === clientDriver.dataset.id);
                return {
                    dataset: clientDriver.dataset,
                    is_active: !!selectedDriver,
                };
            });
            setDatasets(datasetsData);
        }
        getProjectSolution();
        getProjectSolutionDrivers();
    },[id, solutionId, refresh]);

    const handleToggleSelect = async(value) => {
        let drivers = [];
        if(value === "all"){
            if(!selectAll){
                setSelectAll(true);
                drivers = datasets.map(elem=>{return {'driver_id': elem.dataset.id}});
            } else {
                setSelectAll(false);
                drivers = [];
            }
        } else {
            const selectedProjectSolution = projectSolutionDrivers.find(e=>e.driver.id===value);
            if(selectedProjectSolution){ //unchecked
                const updatedProjectSolutionDrivers = projectSolutionDrivers.filter(projectSolutionDriver=>
                    projectSolutionDriver.driver.id !== selectedProjectSolution.driver.id
                );
                const updatedSolutionDrivers = datasets.filter(elem=>
                    updatedProjectSolutionDrivers.some(
                        (projectSolutionDriver) =>
                        projectSolutionDriver.driver.id === elem.dataset.id
                    ));
                drivers = updatedSolutionDrivers.map(elem=>{return {'driver_id': elem.dataset.id}});
            } else {
                const selectedSolutionDriver = datasets.find(e=>e.dataset.id===value);
                const existingSolutionDrivers = datasets.filter(elem=>
                    projectSolutionDrivers.some(
                        (projectSolutionDriver) =>
                        projectSolutionDriver.driver.id === elem.dataset.id
                    ));
                drivers = [...existingSolutionDrivers.map(elem=>{return {'driver_id': elem.dataset.id}}), {'driver_id': selectedSolutionDriver.dataset.id}];
            }
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            body: JSON.stringify({drivers})
        });
        if (response.status === 200) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Solution drivers updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update solution drivers.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleUpdate = async()=>{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: JSON.stringify({timeframe})
        });
        if (response.status === 200) {
            pushToast(<Message type="success">Solution access updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleUpdateAISettings = async() =>{
        const ai_settings = {
            llm: LLM,
            personality,
            strategic_context: strategicContext,
            project_context: projectContext,
            company_context: companyContext,
            industry_context: industryContext,
            competitive_context: competitiveContext,
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: JSON.stringify({ai_settings})
        });
        if (response.status === 200) {
            pushToast(<Message type="success">AI+ settings updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update AI+ settings.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const aiOptions = [
        {
            value: 'chatgpt_4',
            label: 'ChatGPT 4.0',
            icon: chatgpt_icon,
            description: "This is what it's good for and how you can use it.",
            inactive: false
        },
        {
            value: 'claude',
            label: 'Claude',
            icon: claude_icon,
            description: "This is what it's good for and how you can use it.",
            inactive: true
        },
        {
            value: 'gemini',
            label: 'Gemini',
            icon: gemini_icon,
            description: "This is what it's good for and how you can use it.",
            inactive: true // Set Gemini to inactive
        }
    ];

    const handleAIContainerClick = (e, containerRef, inactive) => {
        if (inactive) return;
        e.stopPropagation();
        
        if (containerRef.current) {
            const radioElement = containerRef.current.querySelector('input[type="radio"]');
            if (radioElement) {
                radioElement.click();
            }
        }
    };
    return (
        <ConfigureContainer>
            {projectSolution && (
                <>
                    <HeaderContainer>
                    <SectionTitle>
                        Configure {projectSolution.solution.name}
                    </SectionTitle>
                    <ClientProjectPicker/>
                    </HeaderContainer>
           
                    <SimpleAccordion id={"configureSolutionPageAccordion"} defaultActiveKey={['settings', 'drivers', 'ai']}>
                        <SimpleAccordion.Panel
                            header={`${projectSolution.solution.name} settings`}
                            eventKey="settings"
                        >
                            <TimeframeContainer>
                                <span>Timeframe:</span>
                                <StandardDateRangePicker
                                    value={[new Date(timeframe.from), new Date(timeframe.to)]}
                                    onChange={(value) => setTimeframe({ from: value[0], to: value[1] })}
                                />
                            </TimeframeContainer>
                            <ButtonContainer>
                                <StandardButton
                                    onClick={handleUpdate}
                                >
                                    Save
                                </StandardButton>
                            </ButtonContainer>
                        </SimpleAccordion.Panel>

                        <SimpleAccordion.Panel
                            header="Select drivers"
                            eventKey="drivers"
                        >
                            <GeneralDriverList
                                data={datasets}
                                loading={false}
                                onCheckboxClick={handleToggleSelect}
                                totalAvailable={datasets.length}
                                totalSelected={projectSolutionDrivers.length}
                            />
                        </SimpleAccordion.Panel>

                        {
                            projectSolution.ai_settings && <SimpleAccordion.Panel
                                header="AI+ settings"
                                eventKey="ai"
                            >
                                <PanelNormalIndent>
                                    <AISettingsSection>
                                        
                                        <HStack>
                                            <ContextTitle style={{color: theme.colors.standardText}}>
                                                {'Select LLM'}
                                            </ContextTitle>
                                            <ContextDescription>
                                                {'Choose the language model for AI+'}
                                            </ContextDescription>
                                        </HStack>
                                        <AIOptionGroup>
                                            <RadioProvider group={"llm_group"} initValue={LLM} onChange={(value) => setLLM(value)}>
                                            {aiOptions.map((option) => (
                                                <AIContainer
                                                    key={option.value}
                                                    ref={aiOptionRefs[option.value]}
                                                    selected={LLM === option.value}
                                                    inactive={option.inactive}
                                                    onClick={(e) => handleAIContainerClick(e, aiOptionRefs[option.value], option.inactive)}
                                                >
                                                    <RadioButtonKeeper>
                                                        <StandardRadio 
                                                            group={"llm_group"} 
                                                            value={option.value}
                                                            disabled={option.inactive}
                                                        />
                                                    </RadioButtonKeeper>
                                                    <AIInfoContainer>
                                                        <AILogoContainer>
                                                            <img src={option.icon} alt={option.label} />
                                                        </AILogoContainer>
                                                        <AITitle>{option.label}</AITitle>
                                                        <AIDescription>
                                                            {option.description}
                                                        </AIDescription>
                                                    </AIInfoContainer>
                                                </AIContainer>
                                            ))}
                                            </RadioProvider>
                                        </AIOptionGroup>

                                        {/* Context inputs */}
                                        {[
                                                {
                                                    title: 'Strategic context',
                                                    type: 'strategic',
                                                    value: strategicContext,
                                                    setValue: setStrategicContext,
                                                    description: 'Provide context about the strategic goals and objectives.'
                                                },
                                                {
                                                    title: 'Project context',
                                                    type: 'project',
                                                    value: projectContext,
                                                    setValue: setProjectContext,
                                                    description: 'Describe specific project details and requirements.'
                                                },
                                                {
                                                    title: 'Company context',
                                                    type: 'company',
                                                    value: companyContext,
                                                    setValue: setCompanyContext,
                                                    description: 'Include relevant information about the company and its operations.'
                                                },
                                                {   
                                                    title: 'Industry context',
                                                    type: 'industry',
                                                    value: industryContext,
                                                    setValue: setIndustryContext,
                                                    description: 'What are the key features, drivers and trends of the industry?'
                                                },
                                                {
                                                    title: 'Competitive context',
                                                    type: 'competitive',
                                                    value: competitiveContext,
                                                    setValue: setCompetitiveContext,
                                                    description: 'Who and what do you compete against, and what is the current market situation?'
                                                }
                                            ].map((contextType) => (
                                                <ContextTextareaContainer key={contextType.type}>
                                                    <HStack>
                                                        <ContextTitle>
                                                            {contextType.title}
                                                        </ContextTitle>
                                                        <ContextDescription>
                                                            {contextType.description}
                                                        </ContextDescription>
        


                                                    </HStack>
                                                    <StandardDescription
                                                        placeholder={`Enter comments...`}
                                                        rows={4}
                                                        value={contextType.value || ""}
                                                        onChange={(value) => contextType.setValue(value)}
                                                    />
                                                </ContextTextareaContainer>
                                        ))}

                                        <ButtonContainer>
                                            <StandardButton onClick={handleUpdateAISettings}>
                                                Save settings
                                            </StandardButton>
                                        </ButtonContainer>
                                    </AISettingsSection>
                                </PanelNormalIndent>
                            </SimpleAccordion.Panel>
                        }
                    </SimpleAccordion>
                </>
            )}
        </ConfigureContainer>
    );
}

export default ConfigProjectSolution;