import React, { useMemo } from 'react';
import { useClientProjectContext } from '../../context/ClientProjectContext';
import { useClientContext } from '../../context/ClientContext';
import { useProjectContext } from '../../context/ProjectContext';
import { useAuth } from '../../context/AuthContext';
import { ThickSelectPicker } from '../ui_reusable/StandardSelectPickers';

const ClientProjectPicker = ({ style, disabledItemValues = [] }) => {
    const { clientProjectData } = useClientProjectContext();
    const { selectedClient, setSelectedClient } = useClientContext();
    const { selectedProject, setSelectedProject } = useProjectContext();
    const { isAdmin, isClientManager } = useAuth();

    // Determine if user has admin privileges
    const hasAdminPrivileges = isAdmin || isClientManager;

    // Format data based on user role
    const pickerData = useMemo(() => {
        if (hasAdminPrivileges) {
            // Admin/Manager view - show full client-project combination
            return clientProjectData.map((elem, i) => ({
                key: i+1,
                label: `${elem?.client?.name} - ${elem?.project?.name}`, // Show both client and project
                value: `${elem?.client?.id}-${elem?.project?.id}`
            }));
        } else {
            // Regular user view - only show project names for their client
            return clientProjectData
                .filter(elem => elem?.client?.id === selectedClient?.id)
                .map((elem, i) => ({
                    key: i+1,
                    label: elem?.project?.name, // Only show project name
                    value: `${elem?.client?.id}-${elem?.project?.id}`
                }));
        }
    }, [clientProjectData, selectedClient?.id, hasAdminPrivileges]);

    // Handler for selection changes
    const handleSelectClientProject = (value) => {
        if (!value) {
            // Only reset project for regular users, both for admins
            if (hasAdminPrivileges) {
                setSelectedClient(null);
                setSelectedProject(null);
            } else {
                setSelectedProject(null);
            }
            return;
        }

        const [clientId, projectId] = value.split('-');
        const selectedClientProject = clientProjectData.find(
            elem => elem.client.id === parseInt(clientId)
        );
        const selectedProjectData = clientProjectData.find(
            elem => elem.project.id === parseInt(projectId)
        );

        if (selectedClientProject && selectedProjectData) {
            if (hasAdminPrivileges) {
                // Admin/Manager can change both client and project
                setSelectedClient(selectedClientProject.client);
                setSelectedProject(selectedProjectData.project);
            } else {
                // Regular users can only change project
                setSelectedProject(selectedProjectData.project);
            }
        }

        
    };


    return (
        <ThickSelectPicker 
            placeholder={hasAdminPrivileges ? "Select client & project" : "Select project"}
            size="lg" 
            data={pickerData}
            value={selectedClient && selectedProject ? `${selectedClient?.id}-${selectedProject?.id}` : null}
            onChange={handleSelectClientProject}
            style={{ width: 250, ...style }}
            searchable={hasAdminPrivileges} // Only allow search for admins/managers
            disabledItemValues={disabledItemValues} // Add disabled items support
        />
    );
};

export default ClientProjectPicker;
