import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { InfoSmall600Style } from '../../styles/TextStyles';
import { scrollbarStyle } from '../../../styles/ScrollbarStyles';

export const OuterContainer = styled.div`
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  background-color: ${theme.colors.surface};
  outline: 2px solid ${theme.colors.backgroundMediumDark};
  border-radius: 1.6rem;
  min-width: 10rem;  // Minimum width for all cases
  margin: 0.5rem;
  position: relative;

  ${props => {
    switch (props.size) {
      case 'small': return 'width: 20rem;';
      case 'big': return 'width: 40rem;';
      default: return 'width: 100%;';  // Allow container to grow and fill available space
    }
  }}
`;

export const Container = styled.div`
    
    position: relative;
    
`;

export const GradientContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background: linear-gradient(
        rgba(245, 245, 245, 0) 0%,
        #ffffff88 50%,
        #ffffffc7 100%
    );
    backdrop-filter: blur(0.05rem);
    border-radius: 0rem 0rem 1.2rem 1.2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    ${props => props.show && `
        opacity: 1;
        visibility: visible;
    `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const HeaderTitle = styled.span`
  ${InfoSmall600Style};
  color: ${theme.colors.veryFadedText};
`;

export const Toolbox = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const PlotArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 20rem;
  overflow: hidden;
  max-height: 35rem;
  ${scrollbarStyle}
  
`;

export const Footer = styled.div`
  margin-top: 1rem;
  color: ${theme.colors.veryFadedText};
  font-size: 0.85rem;
`;
