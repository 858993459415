import { css } from '@emotion/react';
import theme from './theme';

export const scrollbarWidth = 8;

export const scrollbarStyle = css`
  /* Webkit browsers (Chrome, Safari, newer versions of Opera) */
  &::-webkit-scrollbar {
    width: ${scrollbarWidth}px;
    height: ${scrollbarWidth}px;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.colors.surface};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    background: ${theme.colors.primaryLightMatte};
    border-radius: 5px;
    border: 2px solid ${theme.colors.surface};

    &:hover {
      background: ${theme.colors.primaryLight};
    }
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.primaryLightMatte} ${theme.colors.surface};

  /* Edge and IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;
