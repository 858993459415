import { useRef } from "react";
import { Message } from "rsuite";
import useStandardToaster from '../../ui_reusable/StandardToaster';
import CreatorAccordion from "../../ui_reusable/CreatorAccordion";
import FormClientUser from "./FormClientUser";

const CreateClientUser = ({ clientId, setRefresh }) => {
    const { pushToast } = useStandardToaster();
    const panelRef = useRef(null);

    const handleSubmit = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${clientId}/users/`, { 
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    user: {
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                    },
                    role: formData.role
                })
            });

            const responseData = await response.json();

            if (response.status !== 201) {
                throw new Error(responseData.message || 'Failed to create user');
            }

            await setRefresh(Date.now());
            return true;
        } catch (error) {
            // Instead of using toaster, throw the error to be handled by the form
            throw error;
        }
    };

    const handleSuccess = () => {
        if (panelRef.current) {
            panelRef.current.tryCollapse();
        }
    };

    return (
        <CreatorAccordion id={"createUserAccordion"}>
            <CreatorAccordion.Panel 
                ref={panelRef}
                header="Add new user" 
                initClosed={true}
            >
                <FormClientUser 
                    onSubmit={handleSubmit}
                    submitButtonText="Create User"
                    successMessage="User created successfully!"
                    onSuccess={handleSuccess}
                    resetOnSuccess={true}
                />
            </CreatorAccordion.Panel>
        </CreatorAccordion>
    );
};

export default CreateClientUser;
