import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import theme from '../../../styles/theme';
import {HeaderLarge500Style} from '../../styles/TextStyles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Overlay that covers the entire screen with a semi-transparent background
export const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 540;
    animation: ${fadeIn} 300ms ease;
`;

// The actual modal container
export const StyledModal = styled.div`
    background: white;
    border-radius: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 550;      // Higher than overlay

    margin: 1rem;
    width: fit-content;
    animation: ${slideDown} 300ms ease;
    flex-shrink: 1;
    min-width: ${props => {
        switch(props.size) {
            case 'xs': return '400px';
            case 'sm': return '500px';
            case 'md': return '600px';
            case 'lg': return '800px';
            case 'xl': return '1000px';
            default: return '23rem';
        }
    }}; 
    max-height: calc(100vh - 3.5rem);
    display: flex;
    flex-direction: column;
`;

export const StyledModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.1rem;
    padding-bottom: 0.6rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-bottom: 1px solid ${theme.colors.borderColor || '#dee2e6'};
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: ${theme.colors.modalHeaderBg || '#f8f9fa'};
`;

export const ModalTitle = styled.h3`
    ${HeaderLarge500Style};
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: ${theme.colors.fadedText || '#666'};
    padding: 0.25rem;
    margin: -0.25rem;
    line-height: 1;
    opacity: 0.7;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;

    &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const StyledModalBody = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 1.25rem;

    max-height: calc(100vh - 8rem); // Adjusted for smaller header/footer

`;

export const StyledModalFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-top: 1px solid #dee2e6;
    gap: 0.5rem;
    min-height: 3rem; // Set fixed minimum height
`;
