import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InfoMedium500Style } from '../../../styles/TextStyles';
import theme from '../../../../styles/theme';

// Vertical stack container
export const GraphStack = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
    justify-content: center;
`;

// Simple version of DrawerInfo without the complex positioning logic
export const SimpleInfoContainer = styled.div`
  background-color: ${theme.colors.backgroundLight};
  border-radius: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0rem;
  padding-bottom: 0rem;
`;

// Simple version of DrawerInfoDisplayer
export const SimpleInfoItem = styled.div`
  width: 8.3rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
`;

// New component for description items that spans full width
export const SimpleInfoDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
`;

export const decriptionInfoValueStyle = css`
  ${InfoMedium500Style};
  max-width: 50%;
  color: ${theme.colors.veryVeryVeryFadedText};
`;