import React, { useState, useRef } from 'react';
import { Message } from "rsuite";
import { 
    WrapHStack, 
    ValueInfoBigStyle, 
    ValueInfoNormalStyle,
    TitleContainer,
} from '../styled_sub_comps/HeaderStyledSubComps';
import HeaderInfoDisplayer from '../ui_reusable/HeaderInfoDisplayer';
import QualityTag from '../ui_reusable/QualityTag';
import CustomIcons from '../../../ui_reusable/CustomIcons';
import { HeaderInput } from '../../../ui_reusable/StandardInputs';
import useStandardToaster from '../../../ui_reusable/StandardToaster';
import LoadingText from '../../../ui_reusable/LoadingText';
import { getQualityString } from '../../../../utils/ScoreFitnessValidation';

// Dummy API function
const updateModelTitle = async (title) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    // Simulate API failure
    throw new Error('Failed to update title');
};

const Header = ({ solution, runDate, modelFitScore, modelHealthScore }) => {
    const [title, setTitle] = useState("Model name");
    const inputRef = useRef(null);
    const { pushToast } = useStandardToaster();

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleTitleSave = async () => {
        try {
            await updateModelTitle(title);
            pushToast(
                <Message type="success">Title updated successfully</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        } catch (error) {
            pushToast(
                <Message type="error">Failed to update title</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    const handleFocusTitle = () => {
        inputRef.current?.focus();
    };

    const getQualityTagFromFitScore = (score) => {
        return getQualityString(score);
    };
    
    const getQualityTagFromHealthScore = (score) => {
        return getQualityString(score * 10); // Converting to 0-100 scale
    };

    return (
        <>
            <TitleContainer >
                <HeaderInput
                    ref={inputRef}
                    value={title}
                    onChange={handleTitleChange}
                    onBlur={handleTitleSave}
                    style={{ width: `${Math.max(title.length, 1)}ch` }}
                />
                <CustomIcons.PenIcon
                    size="small"
                    onClick={handleFocusTitle}
                />
            </TitleContainer>

            <WrapHStack>
                <HeaderInfoDisplayer
                    title="Solution"
                    symbol={null}
                >
                    <LoadingText css={ValueInfoNormalStyle} isLoading={!solution}>
                        {solution}
                    </LoadingText>
                </HeaderInfoDisplayer>

                <HeaderInfoDisplayer
                    title="Run date"
                    symbol={null}
                >
                    <LoadingText css={ValueInfoNormalStyle} isLoading={!runDate}>
                        {runDate ? `${new Date(runDate).toLocaleDateString()}@${new Date(runDate).toLocaleTimeString()}` : ''}
                    </LoadingText>
                </HeaderInfoDisplayer>

                <HeaderInfoDisplayer
                    title="Model fit score"
                    symbol={<QualityTag quality={getQualityTagFromFitScore(modelFitScore)} />}
                >
                    <LoadingText css={ValueInfoBigStyle} isLoading={modelFitScore === undefined}>
                        {modelFitScore}
                    </LoadingText>
                </HeaderInfoDisplayer>

                <HeaderInfoDisplayer
                    title="Model health score"
                    symbol={<QualityTag quality={getQualityTagFromHealthScore(modelHealthScore)} />}
                >
                    <LoadingText css={ValueInfoBigStyle} isLoading={modelHealthScore === undefined}>
                        <span>
                            {modelHealthScore}<span css={ValueInfoNormalStyle}>/ 10</span>
                        </span>
                    </LoadingText>
                </HeaderInfoDisplayer>
            </WrapHStack>
        </>
    );
};

export default Header;
