import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Message } from "rsuite";

// Import custom components
import VersatileGrid from '../../ui_reusable/VersatileGrid';
import { HeaderContainer } from "../../ui_reusable/styled_comps/PlacementMisc";
import SectionTitle from "../../ui_reusable/SectionTitle";
import ClientProjectPicker from "../../comp_reusable/ClientProjectPicker";
import { EasyIconButton } from '../../ui_reusable/styled_comps/Buttons';
import { VerySmallNormalTag } from "../../ui_reusable/styled_comps/Tags";
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../../ui_reusable/VersatileGridUtils';
import CustomIcons from '../../ui_reusable/CustomIcons';
import SimpleAccordion from "../../ui_reusable/SimpleAccordion";
import useStandardToaster from '../../ui_reusable/StandardToaster';
import NoteModal from './ui_reusable/NoteModal';

// Import context and utils
import { useProjectContext } from '../../../context/ProjectContext';
import { getFitnessTag } from '../../../utils/ScoreFitnessValidation';

// Import styled components
import { ActionButtonsContainer, StatsContainer } from './styled_sub_comps/ListStyledSubComps';
import { HeaderMedium600Style } from "../../styles/TextStyles";
import theme from "../../../styles/theme";

const ReportList = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const { selectedProject } = useProjectContext();
    const navigate = useNavigate();
    const { pushToast } = useStandardToaster();
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);

    useEffect(() => {
        const fetchReports = async () => {
            setLoading(true);
            try {
                let url = `${process.env.REACT_APP_API_URL}/api/artifacts/reports`;
                if(selectedProject) {
                    url += `?filter_by=project&project=${selectedProject.id}`;
                }
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const result = await response.json();
                setReports(result.data);
            } catch (error) {
                pushToast(
                    <Message type="error">Failed to load reports</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } finally {
                setLoading(false);
            }
        };
        fetchReports();
    }, [selectedProject]);

    const handleShowNote = (rowData) => {
        setSelectedNote(rowData);
        setShowNoteModal(true);
    };

    const headers = [
        {
            title: 'Name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Note',
            key: 'note',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        },
        {
            title: 'Solution',
            key: 'solution',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Last run date',
            key: 'run_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Last fit score',
            key: 'fit_score',
            type: 'number',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Created by',
            key: 'created_by',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'View / Delete',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        }
    ];

    const gridHeader = (
        <StatsContainer>
            <span css={HeaderMedium600Style}>Total Reports: {reports.length}</span>
        </StatsContainer>
    );

    return (
        <>
            <HeaderContainer>
                <SectionTitle>My reports</SectionTitle>
                <ClientProjectPicker />
            </HeaderContainer>

            <SimpleAccordion id={"reportListPageAccordion"} defaultActiveKey={['1']}>
                <SimpleAccordion.Panel
                    header="Report List"
                    eventKey="1"
                    noCollapse
                >
                    <VersatileGrid
                        gridContainerStyle={{ gridTemplateColumns: `1.5fr 0.4fr 0.6fr 0.6fr 0.6fr 1fr 0.8fr` }}
                        headers={headers}
                        data={reports}
                        renderRow={(rowData) => [
                            <GridCellBoldText>{rowData.name}</GridCellBoldText>,
                            <CustomIcons.faFile 
                                onClick={() => handleShowNote(rowData)}
                                color={theme.colors.fadedText} 
                                solid={false} 
                                size="xs" 
                                style={{ cursor: 'pointer' }}
                            />,
                            <GridCellNormalText>
                                <VerySmallNormalTag>
                                    {rowData?.artifact?.project_solution?.solution.name}
                                </VerySmallNormalTag>
                            </GridCellNormalText>,
                            <GridCellSmallFadedText>
                                {new Date(rowData.artifact.run_at).toLocaleDateString()}
                            </GridCellSmallFadedText>,
                            getFitnessTag(rowData.artifact.fit_score),
                            <GridCellNormalText>{rowData.artifact.created_by.name}</GridCellNormalText>,
                            <ActionButtonsContainer>

                                    <CustomIcons.faFileLines color={theme.colors.fadedText} solid={false} size="xs" onClick={() => navigate(`/models/${rowData.artifact.id}/report`, {state: { id: rowData.artifact.id } })} />

                                    <CustomIcons.TrashIcon color={theme.colors.fadedText} size="xs" />

                            </ActionButtonsContainer>
                        ]}
                        headerInfo={gridHeader}
                        maxDisplayedItems={10}
                        loading={loading}
                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>

            <NoteModal
                open={showNoteModal}
                onClose={() => setShowNoteModal(false)}
                title={selectedNote?.name}
            />
        </>
    );
};

export default ReportList;