import React, { useRef } from "react";
import CreatorAccordion from "../ui_reusable/CreatorAccordion";
import DatasetForm from "../comp_reusable/drivers/Form";

const CreateDataset = ({ setRefresh }) => {
    const panelRef = useRef(null);
    
    const handleSubmit = async (formData, fileInfo) => {
        const payload = new FormData();
        Object.keys(formData).forEach(key => {
            payload.append(key, formData[key]);
        });
        if (fileInfo) {
            payload.append('file', fileInfo.blobFile);
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: payload
        });

        if (response.status !== 201) {
            const error = await response.json();
            throw new Error(error.message || 'Failed to create dataset');
        }

        setRefresh(new Date().getTime());
        return true;
    };

    const handleSuccess = () => {
        if (panelRef.current) {
            panelRef.current.tryCollapse();
        }
    };

    return (
        <CreatorAccordion id={"createDatasetAccordion"}>
            <CreatorAccordion.Panel 
                ref={panelRef}
                header="Add new dataset" 
                initClosed={true}
            >
                <DatasetForm 
                    onSubmit={handleSubmit}
                    submitButtonText="CREATE DATASET"
                    successMessage="Dataset created successfully!"
                    onSuccess={handleSuccess}
                    resetOnSuccess={true}  // Add this prop
                />
            </CreatorAccordion.Panel>
        </CreatorAccordion>
    );
};

export default CreateDataset;

