import styled from '@emotion/styled';
import { TextLarge500Style,TextLarge600Style,HeaderMedium500Style,HeaderMedium600Style } from '../../styles/TextStyles';
import { css } from '@emotion/react';
import { DataVariableLabel,DataValueLabel } from '../styles/DataInfoUtils';
export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const threeColumnGridStyle = css `

    display: grid;
    grid-template-columns: minmax(auto, max-content) 1fr minmax(auto, max-content);
    gap: 0.5rem;
    padding: 10px;
    align-items: stretch; /* Ensures all items stretch to fill the grid cell height */
    grid-auto-rows: 1fr; /* Ensures all rows have uniform height based on tallest child */

    & > * {
      
      align-self: center; 
    }

    & > :nth-child(3n) {
      justify-self: end;
    }

    & > :nth-child(3n-2) {
      margin-right: 2rem;
    }


`

export const ThreeColumnGrid = styled.div`
   ${threeColumnGridStyle};
  
`;

export const ThreeColumnGridAlignTop = styled.div`
 ${threeColumnGridStyle};

  & > * {
    
    align-self: start; 
  }
`;

// Removed OneLineBtnHStack styled component

export const TwoColumnGridSimpleTextDisplay = styled.div`
  ${DataValueLabel}
  display: grid;
  grid-template-columns: minmax(auto, max-content) 1fr;
  grid-template-rows: 2rem 2rem 2rem;
  gap: 10px;
  padding: 1rem;


  & > :nth-child(2n-1) {
    ${DataVariableLabel}
    margin-right: 1rem;
    
  }

  
`;


export const PanelSpecialIndent = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
`;

export const PanelNormalIndent = styled.div`
  padding: 1rem;

`;
