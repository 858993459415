import React, { useEffect, useState } from 'react';
import SimpleAccordion from '../../ui_reusable/SimpleAccordion';

import { PanelSpecialIndent } from '../../ui_reusable/styled_comps/PlacementMisc';

const Intro = () => {
	const [learning, setLearning] = useState();

    useEffect(()=>{
        const getLearning = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setLearning(res.data);
        }
        getLearning();
    }, []);

    return (
        <>
            <SimpleAccordion  id={"showIntroPageAccordion"}  defaultActiveKey={['1']}>
                <SimpleAccordion.Panel 
                    header="Quick-Start Intro"
                    eventKey="1"
                    noCollapse
                >
                    <PanelSpecialIndent>
                        {learning && <div dangerouslySetInnerHTML={{ __html: learning.intro }} />}
                    </PanelSpecialIndent>
                </SimpleAccordion.Panel>
            </SimpleAccordion>

        </>
    );
};

export default Intro;
