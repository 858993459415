import { css } from '@emotion/react';
import theme from '../../../styles/theme';

const sizes = {
  s: {
    size: '1rem',
    dotSize: '0.3rem'
  },
  m: {
    size: '1.15rem',
    dotSize: '0.4rem'
  },
  l: {
    size: '1.3rem',
    dotSize: '0.5rem'
  }
};

export const standardRadioStyle = (size = 'l') => css`
  appearance: none;
  width: ${sizes[size].size};
  height: ${sizes[size].size};
  flex: 0 0 ${sizes[size].size};
  border: 1px solid ${theme.colors.veryVeryVeryFadedText};
  border-radius: 50%;
  background-color: ${theme.colors.surface};
  cursor: pointer;
  position: relative;
  display: inline-block;

  &:hover {
    border-color: ${theme.colors.primaryDark};
    background-color: ${theme.colors.primaryLight};
  }

  &:checked {
    border: 2px solid ${theme.colors.primary};
    background-color: ${theme.colors.primaryVeryVeryLight};
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${sizes[size].dotSize};
      height: ${sizes[size].dotSize};
      flex: 0 0 ${sizes[size].dotSize};
      border-radius: 50%;
      background-color: ${theme.colors.primary};
      
    }
   
    &:hover {
      &:after {
        background-color: ${theme.colors.primaryDark};
      }
    }
  }
`;
