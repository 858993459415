import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { HeaderMedium500Style } from '../../styles/TextStyles';
import {DataVariableLabel,DataValueLabel} from '../styles/DataInfoUtils';
// Container for form sections with consistent styling
export const Container = styled.div`
    background-color: #ffffff;
    border: 1px solid ${theme.colors.backgroundDark};
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
`;

// Responsive grid layout for form sections
export const GridLayout = styled.div`
    display: flex;
    gap: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

// Individual form section wrapper
export const FormSection = styled.div`
    flex: 1;
    padding: 0 1.5rem;
`;

// Form group for input field grouping
export const FormGroup = styled.div`

    display: flex;
    flex-direction: column;

    padding-bottom: 1rem;
`;

// Styled label for form inputs - using TextStyles as source of truth
export const Label = styled.label`
    ${DataValueLabel}
    display: block;
    margin-bottom: 0.5rem;
`;
// Styled label for form inputs - using TextStyles as source of truth
export const LabelFaded = styled.label`
    ${DataValueLabel}
    color: ${theme.colors.veryVeryFadedText};
    display: block;
    margin-bottom: 0.5rem;
`;


export const LabelWithoutMarginNoWrap = styled.label`
    ${DataValueLabel}
    display: block;
    white-space: nowrap;
    
`;

// Styled input field with consistent theme
export const Input = styled.input`
    width: 90%;
    padding: 0.5rem;
    border: 1px solid ${theme.colors.backgroundDark};
    border-radius: 4px;
    &:focus {
        outline: none;
        border-color: ${theme.colors.primary};
    }
`;

// Container for button alignment
export const ButtonContainer = styled.div`
    text-align: right;
    margin: 10px;
`;

export const ErrorMessage = styled.span`
  display: block;
  min-height: 20px;  // Fixed height to prevent layout shifts
  color: ${theme.colors.error};
  font-size: 0.8rem;
  margin-top: 4px;
  padding-left: 0.7rem;
`;

export const ServerErrorMessage = styled.span`
  display: block;
  min-height: 20px;  // Fixed height to prevent layout shifts
  color: ${theme.colors.error};
  font-size: 1rem;
  font-weight: 500;
  margin-top: 4px;
  padding-left: 2px;
  align-items: center;
  justify-content: center;
    display: flex;

  `;

export const SuccessContainer = styled.div`
  height: 80px;  // Fixed height to match Container
  margin-top: 20px;
  position: relative;
  border-top: 1px solid ${theme.colors.backgroundDark};
  padding-top: 20px;
  overflow: hidden;  // Prevent any overflow
`;
