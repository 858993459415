import styled from '@emotion/styled';
import { InfoSmall500Style } from '../../../styles/TextStyles';

export const ClientContextualLabel = styled.div`
    ${InfoSmall500Style}

`
// Horizontal radio and text
export const HStackRadioComplex = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: center;
`;