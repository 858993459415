import React from 'react';
import StandardModal from '../../../ui_reusable/StandardModal';

const NoteModal = ({ open, onClose, title = "Placeholder", text = "Placeholder" }) => {
    return (
        <StandardModal 
            open={open} 
            onClose={onClose}
            size="sm"
        >
            <StandardModal.Header>
                <StandardModal.Title>{title}</StandardModal.Title>
            </StandardModal.Header>
            <StandardModal.Body>
                {text}
            </StandardModal.Body>
            <StandardModal.DefaultFooter
                confirmText="Close"
                onConfirm={onClose}
                hideCancel
            />
        </StandardModal>
    );
};

export default NoteModal;
