import styled from '@emotion/styled';
import theme from '../../../../../styles/theme';
import {InfoMedium600Style,InfoMediumMini500Style} from '../../../../styles/TextStyles';
export const ConfigureContainer = styled.div`
  margin: 20px 0;
`;

export const HeaderFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 2rem;
`;

export const TimeframeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 20px;
`;

export const AISettingsSection = styled.div`
  margin: 30px 0;
`;


export const ContextTextareaContainer = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
`;

export const ContextDescription = styled.div`
  ${InfoMediumMini500Style}
  color: ${theme.colors.veryFadedText};
  
`;

export const ContextTitle = styled.div`
  ${InfoMedium600Style}

  color: ${theme.colors.veryFadedText};
`;

export const HStack = styled.div`

  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.7rem;
`;


export const GridStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 0 1rem;
`;

export const StatsLeft = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StatsRight = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`;



export const AIOptionGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;


export const AIContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid ${theme.colors.backgroundDark};
    border-radius: 0.7rem;
    cursor: pointer;
    transition: all 0.2s;
    max-width: 21.2rem;
    padding: 1rem;
    pointer-events: auto;
    gap: 0.8rem;
    flex: 1 1 auto; // grow | shrink | basis
    min-width: min-content;
    width: max-content;


    ${props => props.selected && `
        border-color: ${theme.colors.primary};
        background-color: ${theme.colors.primaryVeryVeryLight};
    `}

    &:hover {
        border-color: ${theme.colors.primary};
        opacity: 0.8;
    }

    // Add inactive state styles
    ${props => props.inactive && `
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
        &:hover {
            opacity: 0.5;
            border-color: ${theme.colors.backgroundDark};
        }
    `}
`;

export const AIInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 2rem;
  max-width: 12rem;
`;

export const RadioButtonKeeper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.7rem;
  flex: 0 1 3rem;
`

export const AILogoContainer = styled.div`
  width: 27px;
  height: 27px;
  margin-bottom: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const AITitle = styled.div`
  ${InfoMedium600Style}
  color: ${theme.colors.veryFadedText};
  margin-bottom: 0.5rem;
`;

export const AIDescription = styled.div`
 ${InfoMediumMini500Style}
  color: ${theme.colors.fadedText};
`;
