import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Button, Container, Message, Panel } from "rsuite";
import JoditEditor from 'jodit-react';
import SimpleAccordion from '../../ui_reusable/SimpleAccordion';
import useStandardToaster from '../../ui_reusable/StandardToaster';
import { PanelSpecialIndent } from '../../ui_reusable/styled_comps/PlacementMisc';

const ManageAcademy = () => {
    const editor = useRef(null);
    const [content, setContent] = useState();
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getLearning = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setContent(data.academy);
        }
        getLearning();
    }, []);

    const saveContent = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ academy: content }),
        });
        if (response.ok) {
            pushToast(<Message type="success">Academy save successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to save content.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    const config = useMemo(() => ({
        readonly: false, 
        placeholder: 'Start typings...',
        height: "80vh",
        uploader: {
            insertImageAsBase64URI: true,
        },
        buttons: [
            'bold', 'italic',  'underline', 'strikethrough', 'font',  'fontsize',  'brush', '|',
            'paragraph','|',
            'align','lineHeight', '|',
            'outdent', 'indent', '|',
            'ul',  'ol', 'image', 'link',  'hr', 'table','|',
            'eraser','|',
            'undo', 'redo', '|',
            'fullsize',
        ],
    }), []);

    return (
        <>
            <SimpleAccordion id={"manageAcademyPageAccordion"} defaultActiveKey={['1']}>
                <SimpleAccordion.Panel 
                    header="Manage Academy"
                    eventKey="1"
                    noCollapse
                >
                    <PanelSpecialIndent>
                        <JoditEditor
                            ref={editor}
                            value={content && content}
                            config={config}
                            tabIndex={1}
                            onBlur={newContent => setContent(newContent)}
                            onChange={newContent => {}}
                        />
                        <div style={{ marginTop: '20px', textAlign: 'right'}}>
                            <Button appearance="primary" color="violet" onClick={saveContent}>
                                Save Content
                            </Button>
                        </div>
                    </PanelSpecialIndent>
                </SimpleAccordion.Panel>
            </SimpleAccordion>

        </>
    );
};

export default ManageAcademy;
