import { css } from '@emotion/react';
import theme from '../../../styles/theme';

/* Generic Panel Styles - These are shared across multiple components */
export const PanelStyle = css`
  padding: 1.7rem;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  border-radius: 1.1rem;
  background-color: ${theme.colors.surface};

`;

export const SecondaryPanelStyle = css`
  ${PanelStyle}
  background-color: ${theme.colors.surfaceSecondary};
`;

/* Base Accordion Styles - Internal building blocks shared by all accordions */
export const baseAccordionContainer = css`
  display: flex;
  flex-direction: column;
  border: none;
`;

export const baseAccordionHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

`;