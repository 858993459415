import React, { useCallback, useState } from 'react';
import VersatileGrid from '../ui_reusable/VersatileGrid';
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../ui_reusable/VersatileGridUtils';
import { TagSuccess, TagDanger, TagWarning } from '../ui_reusable/styled_comps/Tags';
import { EasyIconButton } from '../ui_reusable/styled_comps/Buttons';
import CustomIcons from '../ui_reusable/CustomIcons';
import theme from "../../styles/theme";
import { StatsContainer } from '../clients/styled_sub_comps/ListStyledSubComps';
import { HeaderMedium600Style } from "../styles/TextStyles";
import { SmallNormalTag } from '../ui_reusable/styled_comps/Tags';
import { StandardCheckbox } from '../ui_reusable/StandardInputs';
import ShowDataset from './drivers/Show';

const GeneralDriverList = ({
    data = [],
    loading = false,
    onCheckboxClick,
    totalAvailable = 0,
    totalSelected = 0
}) => {
    // Add internal expandedRows state
    const [expandedRows, setExpandedRows] = useState(new Set());

    const handleExpand = useCallback((dataIdx) => {
        setExpandedRows(prev => {
            const next = new Set(prev);
            if (next.has(dataIdx)) {
                next.delete(dataIdx);
            } else {
                next.add(dataIdx);
            }
            return next;
        });
    }, []);

    const headers = [
        {
            title: 'Driver Name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            flexGrow: 2
        },
        {
            title: 'Source',
            key: 'source',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Last Updated',
            key: 'updated_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Observations',
            key: 'observations',
            type: 'number',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Quality',
            key: 'quality',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'View',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        }
    ];

    const gridHeader = (
        <StatsContainer>
            <span css={HeaderMedium600Style}>Available Drivers: {totalAvailable}</span>
            <div>
                <SmallNormalTag>
                    {totalSelected} drivers selected
                </SmallNormalTag>
            </div>
        </StatsContainer>
    );

    const renderIntersectingRow = useCallback((rowData, rowIdx, dataIdx) => {
        if (!expandedRows.has(dataIdx)) return null;
        return <ShowDataset dataset={rowData.dataset} datasetId={rowData.dataset.id} units={rowData.dataset.units} />;
    }, [expandedRows]);

    return (
        <VersatileGrid
            headers={headers}
            data={data}
            searchPlaceholder="Find driver"
            renderRow={(rowData, rowIdx, dataIdx) => [
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <StandardCheckbox 
                        onChange={() => onCheckboxClick(rowData.dataset.id)}
                        checked={rowData.is_active}
                    />
                    <GridCellBoldText>{rowData?.dataset?.name}</GridCellBoldText>
                </div>,
                <GridCellNormalText>{rowData?.dataset?.source}</GridCellNormalText>,
                <GridCellSmallFadedText>
                    {new Date(rowData?.dataset?.updated_at).toLocaleDateString()}
                </GridCellSmallFadedText>,
                <GridCellNormalText>{rowData?.dataset?.observations}</GridCellNormalText>,
                <GridCellNormalText>
                    {rowData?.dataset?.quality === "good" && <TagSuccess label="Good" />}
                    {rowData?.dataset?.quality === "check" && <TagWarning label="Check" />}
                    {rowData?.dataset?.quality === "poor" && <TagDanger label="Fix" />}
                </GridCellNormalText>,
                <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                    <EasyIconButton onClick={() => handleExpand(dataIdx)}>
                        {expandedRows.has(dataIdx) ? 
                            <CustomIcons.faEyeSlash color={theme.colors.veryFadedText} solid={false} size="xs" /> : 
                            <CustomIcons.faEye color={theme.colors.veryFadedText} solid={false} size="xs" />
                        }
                    </EasyIconButton>
                </div>
            ]}
            renderIntersectingRow={renderIntersectingRow}
            maxDisplayedItems={8}
            headerInfo={gridHeader}
            loading={loading}
        />
    );
};

export default GeneralDriverList;
