import React from 'react';
import { HStack } from 'rsuite';
import LoadingText from '../../ui_reusable/LoadingText';
import useDatasetSummary from './cache_query_handler/useDatasetSummary';
import {
    ExpandedRow, 
    DrawerInfo, 
    DrawerInfoDisplayer, 
    InfoDisplayerTitle, 
    infoDisplayerValueStyle
} from '../../ui_reusable/DrawerInfoDisplayer';
import DatasetGraph from '../DatasetGraph';
import theme from '../../../styles/theme';
import { SimpleInfoContainer,decriptionInfoValueStyle, SimpleInfoItem, SimpleInfoDescription, GraphStack } from './styled_sub_comps/ShowStyledSubComps';

const ShowDataset = ({ dataset, datasetId, units }) => {
    const queryInfo = useDatasetSummary(datasetId);
    console.log('dataset in show', dataset);
    return (
        <ExpandedRow>
            {dataset?.name && ( 
                    <SimpleInfoDescription>
                        <InfoDisplayerTitle>Dataset name</InfoDisplayerTitle>
                        <LoadingText 
                            css={decriptionInfoValueStyle} 
                            isLoading={false}
                        >
                            {dataset.name || "N/A"}
                        </LoadingText>
                    </SimpleInfoDescription> 
                )}
            {dataset?.description && ( 
                    <SimpleInfoDescription>
                        <InfoDisplayerTitle>Description</InfoDisplayerTitle>
                        <LoadingText 
                            css={decriptionInfoValueStyle} 
                            isLoading={false}
                        >
                            {dataset.description || "N/A"}
                        </LoadingText>
                    </SimpleInfoDescription> 
                )}

            <SimpleInfoContainer style={{ backgroundColor: theme.colors.surface, marginTop: '10px' }}>
                {dataset?.source && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Source</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.source}
                        </LoadingText>
                    </SimpleInfoItem>
                 )}
                {dataset?.interval && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Interval</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.interval}
                        </LoadingText>
                    </SimpleInfoItem>
                 )}
                 {dataset?.updated_at && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Last updated</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {new Date(dataset.updated_at).toLocaleDateString()}
                        </LoadingText>
                    </SimpleInfoItem>
                 )}

                {dataset?.aggregation_method && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Aggr. Method</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.aggregation_method || "N/A"}
                        </LoadingText>
                    </SimpleInfoItem> 
                )}
                {dataset?.units && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Units</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.units || "N/A"}
                        </LoadingText>
                    </SimpleInfoItem> 
                )}
                {dataset?.influence && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Influence</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.influence || "N/A"}
                        </LoadingText>
                    </SimpleInfoItem> 
                )}
                {dataset?.impact && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Impact</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.impact || "N/A"}
                        </LoadingText>
                    </SimpleInfoItem> 
                )}
                
                {dataset?.quality && ( 
                    <SimpleInfoItem>
                        <InfoDisplayerTitle>Quality</InfoDisplayerTitle>
                        <LoadingText 
                            css={infoDisplayerValueStyle} 
                            isLoading={false}
                        >
                            {dataset.quality || "N/A"}
                        </LoadingText>
                    </SimpleInfoItem> 
                )}
            </SimpleInfoContainer> 

            
            <DrawerInfo>
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Total observations</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {queryInfo.data?.total_observations}
                    </LoadingText>
                </DrawerInfoDisplayer>
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Included observations</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {queryInfo.data?.included_observations}
                    </LoadingText>
                </DrawerInfoDisplayer>
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Missing</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {queryInfo.data?.gaps > 0 ? queryInfo.data.gaps : 'None'}
                    </LoadingText>
                </DrawerInfoDisplayer>
                
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Seasonality</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {(queryInfo.data?.season > 0) ? 'Detected' : 'Not detected'}
                    </LoadingText>
                </DrawerInfoDisplayer>
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Structural breaks</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {(queryInfo.data?.breaks) ? 'Yes' : 'None'}
                    </LoadingText>
                </DrawerInfoDisplayer>
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Std deviation</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {queryInfo.data?.stddev}
                    </LoadingText>
                </DrawerInfoDisplayer>
                <DrawerInfoDisplayer>
                    <InfoDisplayerTitle>Mean</InfoDisplayerTitle>
                    <LoadingText 
                        css={infoDisplayerValueStyle} 
                        isLoading={queryInfo.isLoading}
                    >
                        {queryInfo.data?.mean}
                    </LoadingText>
                </DrawerInfoDisplayer>   
            </DrawerInfo>                 
            
            

            <GraphStack>
                <DatasetGraph datasetId={datasetId} action="decompose" />
                {queryInfo?.data?.season > 0 && (
                    <DatasetGraph size='small' datasetId={datasetId} action="season" />
                )}
                {queryInfo?.data?.breaks && (
                    <DatasetGraph size='small' datasetId={datasetId} action="breaks" />
                )}
                {queryInfo?.data?.gaps > 0 && (
                    <DatasetGraph size='small' datasetId={datasetId} action="gaps" />
                )}
            </GraphStack>
        </ExpandedRow>
    );
};

export default React.memo(ShowDataset);
