import React, { useEffect, useState, useCallback } from "react";
import format from 'date-fns/format';
import { useLocation, useNavigate } from "react-router-dom";

import { useClientContext } from "../../context/ClientContext";
import { useProjectContext } from "../../context/ProjectContext";

import { aggregationMethodOptions, intervalOptions } from "../../utils/dropdowns";
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import {FormDateRangePicker} from "../ui_reusable/StandardSelectPickers";
import { HeaderContainer } from '../ui_reusable/styled_comps/PlacementMisc';
import SectionTitle from '../ui_reusable/SectionTitle';
import DriverList from './build/DriverList';
import { ParameterGrid } from './styled_sub_comps/BuildStyledSubComps';
import {Label,LabelFaded}  from '../ui_reusable/styled_comps/CreateInputUtils';
import {FormSelectPicker} from '../ui_reusable/StandardSelectPickers';
import { useForm } from "react-hook-form";
import { ErrorMessage, ErrorSummary,HStack,VStack } from './styled_sub_comps/BuildStyledSubComps';
import {PanelSpecialIndent} from '../ui_reusable/styled_comps/PlacementMisc';
import RunButtonWithIcon from '../ui_reusable/styled_comps/Buttons';
import { useClientProjectContext } from "../../context/ClientProjectContext";
import ClientProjectPicker from '../comp_reusable/ClientProjectPicker';
const ModelBuild = ()=>{
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [everExpanded, setEverExpanded] = useState(new Set());
    const location = useLocation();
    const navigate = useNavigate();
 
    const {solutionId} = location.state || {};
    const [projectSolution, setProjectSolution] = useState(null);
    const [projectSolutionDrivers, setProjectSolutionDrivers] = useState([]);
    const [timeframe, setTimeframe] = useState({
        from: '',
        to: '',
        prognosis: 0
    });
    const [parameters, setParameters] = useState({
        business_goal: 'Increase',
        dependent_driver: '',
        frequency: 'monthly',
        aggregation_method: ''
    });
    const [dependentDriver, setDependentDriver] = useState();
    const [independentDrivers, setIndependentDrivers] = useState([]);
    const {clientProjectData} = useClientProjectContext();
    const {selectedClient} = useClientContext();
    const {selectedProject} = useProjectContext();
    
    const { register, handleSubmit, formState: { errors }, watch, setValue, clearErrors, setError, trigger } = useForm({
        defaultValues: {
            timeframe_from: '',
            timeframe_to: '',
            prognosis: 0,
            business_goal: 'Increase',
            dependent_driver: '',
            frequency: 'monthly',
            aggregation_method: 'mean',
            independent_drivers: []
        },
        mode: "all", // Change this to show all errors
        criteriaMode: "all",
        reValidateMode: "onSubmit",
        shouldFocusError: false,
        resolver: (data) => {
            const errors = {};
            if (independentDrivers.length === 0) {
                errors.independent_drivers = {
                    type: 'required',
                    message: 'At least one independent driver must be selected'
                };
            }
            return {
                values: data,
                errors: errors
            };
        }
    });

    // Watch values for validation
    const watchedFields = watch();

    const validateForm = (data) => {
        let isValid = true;
        const validationErrors = [];

        // Check all required fields at once
        if (!data.dependent_driver) {
            setError("dependent_driver", {
                type: "required",
                message: "Dependent variable must be selected"
            });
            validationErrors.push("Dependent variable must be selected");
            isValid = false;
        }

        if (independentDrivers.length === 0) {
            setError("independent_drivers", {
                type: "required",
                message: "At least one independent driver must be selected"
            });
            validationErrors.push("At least one independent driver must be selected");
            isValid = false;
        }

        return {
            isValid,
            validationErrors
        };
    };

    const onSubmit = async (data) => {

        
        // Clear previous errors
        clearErrors();
        
        // Trigger all validations at once
        await trigger();

        const { isValid, validationErrors } = validateForm(data);

        
        if (!isValid) {

            return;
        }

        const payload = {
            timeframe: {
                from: data.timeframe_from,
                to: data.timeframe_to,
                prognosis: data.prognosis
            },
            parameters: {
                business_goal: data.business_goal,
                dependent_driver: data.dependent_driver,
                frequency: data.frequency,
                aggregation_method: data.aggregation_method
            },
            dependent_driver_id: data.dependent_driver,
            independent_drivers: independentDrivers.map(elem => ({
                driver_id: elem.driver.id
            }))
        };

        handleRun(payload);

    };

    useEffect(()=>{
        const getProjectSolution = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${selectedProject?.id}/solutions/${solutionId}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setProjectSolution(data);
            setTimeframe(prev => ({ ...prev, from: new Date(data.timeframe.from).toISOString().split('T')[0], to: new Date(data.timeframe.to).toISOString().split('T')[0] }));
        }
        const getProjectSolutionDrivers = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${selectedProject?.id}/solutions/${solutionId}/drivers?limit=99999`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setProjectSolutionDrivers(res.data);
        }
        getProjectSolution();
        getProjectSolutionDrivers();
    },[selectedProject, solutionId]);

    const handleDateRange = (value) =>{
        if(value){
            const [from, to] = value;
            if(from && to){
                setTimeframe(prev => ({ 
                    ...prev, 
                    from: new Date(from).toISOString().split('T')[0], 
                    to: new Date(to).toISOString().split('T')[0] 
                }));
                setValue('timeframe_from', new Date(from).toISOString().split('T')[0]);
                setValue('timeframe_to', new Date(to).toISOString().split('T')[0]);
            }
        } else {
            setTimeframe(prev => {
                const { from: _, to: __, ...rest } = prev;
                return rest;
            });
        }
    }

    const handleRun = async () =>{
        const payload = {
            name: `${dependentDriver.name}_${new Date().toISOString()}`,
            project_solution_id: projectSolution.id,
            timeframe, 
            parameters: {
                business_goal: parameters.business_goal,
                frequency: parameters.frequency,
                aggregation_method: parameters.aggregation_method
            }, 
            dependent_driver_id: dependentDriver.id, 
            independent_driver_ids: independentDrivers.map(elem=>elem.driver.id)
        };
        console.log(payload)
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/artifacts/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(payload)
            });
            if (response.ok) {
                const res = await response.json();
                const data = res.data;
                navigate(`/models/${data.id}/report`, {state: {id: data.id}});
            } else {
                console.error('Failed to run model:', response);
            }
        } catch (error) {
            console.error('Error running model:', error);
        }
    }



    // // Transform projectSolutionDrivers to match expected structure
    // const transformedDrivers = projectSolutionDrivers.map(psd => ({
    //     id: psd.id,
    //     name: psd.driver.name,
    //     source: psd.driver.source,
    //     interval: psd.driver.interval,
    //     created_at: psd.driver.updated_at,
    //     observations: psd.driver.observations,
    //     units: psd.driver.units,
    //     quality: psd.driver.quality,
    //     // Add these fields for ShowDataset component
    //     driver: {
    //         id: psd.driver.id,
    //         units: psd.driver.units
    //     },
    //     original: psd
    // }));

    const handleExpand = useCallback((dataIdx) => {
        setExpandedRows(prev => {
            const next = new Set(prev);
            if (next.has(dataIdx)) {
                next.delete(dataIdx);
            } else {
                next.add(dataIdx);
                setEverExpanded(prev => new Set(prev).add(dataIdx));
            }
            return next;
        });
    }, []);

    const handleDriverSelect = (psdId) => {
        let updatedDrivers;
        if(independentDrivers.find(e => e.id === psdId)) {
            updatedDrivers = independentDrivers.filter(elem => elem.id !== psdId);
        } else {
            updatedDrivers = [...independentDrivers, projectSolutionDrivers.find(e => e.id === psdId)];
        }
        
        setIndependentDrivers(updatedDrivers);
        setValue('independent_drivers', updatedDrivers);

        if (updatedDrivers.length === 0) {
            setError('independent_drivers', {  // Changed from setFormError to setError
                type: 'manual',
                message: 'At least one independent driver must be selected'
            });
        } else {
            clearErrors('independent_drivers');
        }
    };

    // Update the parameters change handler to remove driver from independentDrivers if selected as dependent
    const handleParameterChange = (field, value) => {
        if (field === 'dependent_driver') {
            if (independentDrivers.find(d => d.id === value)) {
                setIndependentDrivers(independentDrivers.filter(d => d.id !== value));
            }
            if (value) {
                clearErrors('dependent_driver');
                setValue('dependent_driver', value);
                const projectSolutionDriver = projectSolutionDrivers.find(d => d.id === value);
                setDependentDriver(projectSolutionDriver.driver);
            }
        }
        setParameters(prev => ({ ...prev, [field]: value }));
    };

    // Add new state to track projects without solution access
    const [projectsWithoutAccess, setProjectsWithoutAccess] = useState([]);
    const [allProjectSolutions, setAllProjectSolutions] = useState([]);

    // Replace the single project solutions fetch with multi-project fetch
    useEffect(() => {
        const getAllProjectSolutions = async () => {
            try {
                // Get all unique project IDs from clientProjectData
                const projectIds = [...new Set(clientProjectData.map(item => item.project.id))];
                
                // Fetch solutions for each project
                const allSolutionsPromises = projectIds.map(projectId => 
                    fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/solutions`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }).then(res => res.json())
                );

                // Wait for all requests to complete
                const results = await Promise.all(allSolutionsPromises);
                
                // Combine all solutions into one flat array
                const combinedSolutions = results.flatMap(result => result.data || []);
                

                setAllProjectSolutions(combinedSolutions);
            } catch (error) {
                console.error('Error fetching all project solutions:', error);
            }
        };

        if (clientProjectData.length > 0) {
            getAllProjectSolutions();
        }
    }, [clientProjectData]);

    // Add effect to fetch and determine which projects don't have access
    useEffect(() => {
        const fetchProjectAccess = () => {

            if (!solutionId || !allProjectSolutions.length) return;
            
            // Create a list of projects that DON'T have access to this solution
            const disabledProjects = clientProjectData.filter(item => {
                // Check if this project has any solutions with matching solutionId

                const hasAccess = allProjectSolutions.some(ps => 
                    ps.solution.id === parseInt(solutionId) && 
                    ps.project.id === item.project.id
                );
                return !hasAccess;
            }).map(item => `${item.client.id}-${item.project.id}`);

            setProjectsWithoutAccess(disabledProjects);
        };

        fetchProjectAccess();
    }, [solutionId, clientProjectData, allProjectSolutions]);

    useEffect(() => {
        if (independentDrivers.length > 0) {
            setValue('independent_drivers', independentDrivers);
        }
    }, [independentDrivers.length]);

    // Add an effect to handle initial redirection if no project is selected
    useEffect(() => {
        if (!selectedProject && !selectedClient) {
            navigate('/models');
        }
    }, [selectedProject, selectedClient, navigate]);

    // Update the data fetching effect with guards
    useEffect(() => {
        const fetchData = async () => {
            // Guard clause - don't proceed if we don't have required data
            if (!selectedProject?.id || !solutionId) {
                return;
            }

            try {
                // Fetch project solution
                const solutionResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/${selectedProject.id}/solutions/${solutionId}`, 
                    { 
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );

                if (!solutionResponse.ok) {
                    throw new Error('Failed to fetch project solution');
                }

                const solutionData = await solutionResponse.json();
                setProjectSolution(solutionData.data);
                setTimeframe(prev => ({ 
                    ...prev, 
                    from: new Date(solutionData.data.timeframe.from).toISOString().split('T')[0], 
                    to: new Date(solutionData.data.timeframe.to).toISOString().split('T')[0] 
                }));

                // Fetch project solution drivers
                const driversResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/${selectedProject.id}/solutions/${solutionId}/drivers?limit=99999`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );

                if (!driversResponse.ok) {
                    throw new Error('Failed to fetch drivers');
                }

                const driversData = await driversResponse.json();
                setProjectSolutionDrivers(driversData.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                navigate('/models');
            }
        };

        fetchData();
    }, [selectedProject?.id, solutionId, navigate]);

    return (
        <>
            {selectedProject && projectSolution ? (
                <SimpleAccordion id={"buildPageSimpleAccordion"} defaultActiveKey={[1, 2, 3, 4]}>
                    {
                        projectSolution && 
                        <>
                            {projectSolution && (
                                <HeaderContainer>
                                <SectionTitle>{projectSolution.solution.name} model build</SectionTitle>
                                <ClientProjectPicker 
                                    disabledItemValues={projectsWithoutAccess}
                                />
                            </HeaderContainer>
                            )}
                            <SimpleAccordion.Panel 
                                header="Set Timeframe"
                                eventKey={1}
                            >
                                <PanelSpecialIndent>
                                    <VStack >
                                        <HStack >
                                            <Label style={{width: '7.2rem'}}>Interval: </Label>
                                            {
                                                <FormDateRangePicker
                                                    // ranges={quickSelectionDateOptions(selectedDataset.date_start, selectedDataset.date_end)}
                                                    format="yyyy-MM-dd"
                                                    renderValue={([start, end]) => {
                                                        return format(start, 'EEE, d MMM Y') + ' - ' + format(end, 'EEE, d MMM Y');
                                                    }}
                                                    // shouldDisableDate={(date)=>{
                                                    //     return (date < new Date(`${projectSolution.date_start} 00:00:00`) || date > new Date(`${selectedDataset.date_end} 23:59:00`))
                                                    // }}
                                                    value={timeframe?[new Date(timeframe?.from), new Date(timeframe?.to)]:''}
                                                    onChange={handleDateRange}
                                                    cleanable={false}
                                                />
                                            }
                                        </HStack>
                                        {/*<HStack>
                                            <Label style={{width: '7.2rem'}}>Prognosis?</Label>
                                            <FormSelectPicker  style={{width: '14rem'}} searchable={false} defaultValue={0} data={prognosisOptions} onChange={(value)=>setTimeframe(prev=>({...prev, prognosis: value}))}  value={timeframe?.prognosis} cleanable={false}/>
                                        </HStack>*/}
                                    </VStack>
                                </PanelSpecialIndent>
                            </SimpleAccordion.Panel >
                            <SimpleAccordion.Panel 
                                header="Set Parameters"
                                eventKey={2}
                                
                            >
                                <PanelSpecialIndent>
                                    <ParameterGrid>
                                        {/* Row 1 */}
                                        <Label>I want to</Label>
                                        <LabelFaded>Select business goal</LabelFaded>
                                        <LabelFaded>Select dependent variable</LabelFaded>
        
                                        
                                        {/* Row 2 */}
                                        <div/>
                                        <FormSelectPicker 
                                        
                                            {...register("business_goal")}
                                            style={{width: '14rem'}}
                                            searchable={false} 
                                            cleanable={false} 
                                            data={[
                                                { label: 'Increase (default)', value: 'Increase' },
                                                { label: 'Decrease', value: 'Decrease' }
                                            ]} 
                                            defaultValue="Increase" 
                                            onChange={(value) => setParameters(prev => ({...prev, business_goal: value}))}
                                        />

                                        
                                        <FormSelectPicker 
                                            {...register("dependent_driver", { 
                                                required: "Dependent variable is required" 
                                            })}
                                            style={{width: '14rem'}}
                                            searchable={true} 
                                            cleanable={false} 
                                            data={projectSolutionDrivers.map((projectSolutionDriver, i) => ({ 
                                                key: i, 
                                                label: projectSolutionDriver.driver.name, 
                                                value: projectSolutionDriver.id 
                                            }))} 
                                            onChange={(value) => handleParameterChange('dependent_driver', value)}
                                        /> 
                                        {/* Row 3 */}
                                        <div/>
                                        <div/>
                                        <ErrorMessage>{errors.dependent_driver?.message}</ErrorMessage>
                                        {/* Row 4 */}
                                        <div/>
                                        <Label>Model data frequency</Label>
                                        <Label>{parameters.frequency!=='monthly'&&'Aggregation method'}</Label>
                                        {/* Row 5 */}
                                        <div/>
                                        <FormSelectPicker 
                                            style={{width: '14rem'}}
                                            searchable={false} 
                                            cleanable={false} 
                                            data={intervalOptions} 
                                            defaultValue="monthly" 
                                            onChange={(value) => setParameters(prev => ({...prev, frequency: value}))}
                                        />
                                        {
                                            parameters.frequency!=='monthly' && <FormSelectPicker 
                                                style={{width: '14rem'}}
                                                searchable={false} 
                                                cleanable={false} 
                                                data={aggregationMethodOptions} 
                                                defaultValue="mean" 
                                                onChange={(value) => setParameters(prev => ({...prev, aggregation_method: value}))}
                                            />
                                        }
                                    </ParameterGrid>
                                </PanelSpecialIndent>
                            </SimpleAccordion.Panel >
                            <SimpleAccordion.Panel 
                                header="Select drivers"
                                eventKey={3}
                                
                            >
                                <DriverList
                                    data={projectSolutionDrivers}
                                    onCheckboxClick={handleDriverSelect}
                                    selectedDrivers={independentDrivers.map(d => d.id)}
                                    dependentDriverId={parameters.dependent_driver}
                                    expandedRows={expandedRows}
                                    onExpandRow={handleExpand}
                                    totalAvailable={projectSolutionDrivers.length}
                                    totalSelected={independentDrivers.length}
                                />
                                <ErrorMessage style={{padding:'2rem'}}>{errors.independent_drivers?.message}</ErrorMessage>
                            </SimpleAccordion.Panel >
                            <SimpleAccordion.Panel 
                                header="Execute"
                                eventKey={4}
                                noCollapse
                            >
                                {errors && Object.keys(errors).length > 0 && (
                                    <>
                                        
                                        <ErrorSummary>
                                            <h4>In order to run a model, the following must be set:</h4>
                                            <ul>
                                                {Object.entries(errors).map(([key, value]) => {
                                                    
                                                    return <li key={key}>{value.message}</li>
                                                })}
                                            </ul>
                                        </ErrorSummary>
                                    </>
                                )}
                                <div style={{width: '95%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <RunButtonWithIcon 
                                        onClick={handleSubmit(onSubmit)} 
                                    >
                                        RUN
                                    </RunButtonWithIcon>
                                </div>
                            </SimpleAccordion.Panel>
                        </>
                    }
                </SimpleAccordion>
            ) : (
                <div>Loading...</div>
            )}
    
        </>
    );
}
export default ModelBuild;