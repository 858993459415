import styled from '@emotion/styled';
import { InfoLarge600Style, InfoXXXLarge600Style } from '../../../styles/TextStyles';
import { css } from '@emotion/react';

// Custom HStack component for horizontal stacking
export const HStack = styled.div`
    display: flex;
    align-items: center;
    gap: '10px';
`;

// Custom VStack component for vertical stacking
export const VStack = styled.div`
    display: flex;
    flex-direction: column;
    gap:  '10px';
`;

export const WrapHStack = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3rem;
`;

export const TitleContainer = styled.div`
    display: inline-flex;  // Changed to inline-flex to fit content
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    padding-left: 0.3rem;
    width: auto;  // Allow container to shrink to content
    min-width: min-content;  // Ensure it doesn't get smaller than content
`;

export const TitleText = styled.span`
    ${InfoXXXLarge600Style};
    display: inline-block;  // Helps with proper sizing
`;

export const ValueInfoBigStyle = css`
    ${InfoXXXLarge600Style};
`;

export const ValueInfoNormalStyle = css`
    ${InfoLarge600Style};
`;
