/**
 * This is ReportAccordion which inheris from BaseAccordion
 * The main differences between all inherited accordions is applied styling and the header rendering.
 * This one is used in the report page for model runs.
 * It has
 * - Checkbox for section inclusion/exclusion
 * - Help tooltip with section-specific information
 * - Expand/collapse controls with RSuite icons
 */

import React, { useState, useEffect } from 'react';
import BaseAccordion from './BaseAccordion';
import { AccordionContainer, PanelContainer, PanelHeader, PanelHeaderTitle, CustomHStack } from './styled_sub_comps/ReportAccordionStyledSubComps';
import { StandardCheckbox } from './StandardInputs';
import theme from '../../styles/theme';
import CustomIcons from './CustomIcons';

const ReportAccordion = ({ children, onContextReady, ...props }) => {


  return (
    <AccordionContainer>
      <BaseAccordion {...props}>
        {children}
      </BaseAccordion>
    </AccordionContainer>
  );
};

/**
 * Panel subcomponent for ReportAccordion
 * @param {string} header - Section title
 * @param {string} helpText - Optional help text shown in popover
 * @param {React.ReactNode} children - Panel content
 */
const Panel = ({ header, helpText, children, ...props }) => {
  // State for section inclusion checkbox
  const [isChecked, setIsChecked] = useState(true);

  return (
    <PanelContainer>
      <BaseAccordion.Panel
        {...props}
        renderHeader={({ isActive, onToggle, noCollapse }) => (
          <PanelHeader>
            <PanelHeaderTitle onClick={onToggle}>{header}</PanelHeaderTitle>
            <CustomHStack>
              <StandardCheckbox
                
                size="small"
                color={theme.colors.fancyText}
                checked={isChecked}
                onChange={(_, checked) => setIsChecked(checked)}
              />
              <CustomIcons.CircleQuestionThickIcon color={theme.colors.fancyText} size="s"/>
              {!noCollapse && (
                
                  isActive ? 
                    <CustomIcons.ChevronUpIcon size="xs" color={theme.colors.fancyText} onClick={onToggle} /> : 
                    <CustomIcons.ChevronDownIcon size="xs" color={theme.colors.fancyText} onClick={onToggle} />
                  
                
              )}
            </CustomHStack>
          </PanelHeader>
        )}
      >
        {children}
      </BaseAccordion.Panel>
    </PanelContainer>
  );
};

// ...existing exports...

ReportAccordion.Panel = Panel;
export default ReportAccordion;