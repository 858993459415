import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import LoadingText from './LoadingText';

const ANIMATION_INTERVAL = 25;
const CHUNK_SIZE = 5;

const AIText = ({ markdownText, isLoading, css, style, useAnimation = true }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);
    const previousTextRef = useRef('');
    const animationTimeoutRef = useRef(null);
    const currentTokensRef = useRef([]);
    const targetTokensRef = useRef([]);

    const splitIntoTokens = (text) => {
        if (!text) return [];
        let tokens = text.split(' ');
        if (tokens.length === 1) {
            tokens = text.match(new RegExp(`.{1,${CHUNK_SIZE}}`, 'g')) || [];
        }
        return tokens;
    };

    const animateText = () => {
        const currentLength = currentTokensRef.current.length;
        const targetLength = targetTokensRef.current.length;

        if (currentLength < targetLength) {
            const newText = targetTokensRef.current.slice(0, currentLength + 1).join(' ');
            setDisplayedText(newText);
            currentTokensRef.current = targetTokensRef.current.slice(0, currentLength + 1);
            animationTimeoutRef.current = setTimeout(animateText, ANIMATION_INTERVAL);
        } else {
            setIsAnimating(false);
        }
    };

    useEffect(() => {
        if (isLoading || !markdownText || markdownText.trim() === '') {
            setDisplayedText('');
            return;
        }

        if (!useAnimation) {
            setDisplayedText(markdownText);
            return;
        }

        if (animationTimeoutRef.current) {
            clearTimeout(animationTimeoutRef.current);
        }

        // Keep current progress but update target
        targetTokensRef.current = splitIntoTokens(markdownText);
        
        if (!isAnimating) {
            // If not already animating, start from current state
            currentTokensRef.current = splitIntoTokens(displayedText || '');
            setIsAnimating(true);
            animateText();
        }

        previousTextRef.current = markdownText;

        return () => {
            if (animationTimeoutRef.current) {
                clearTimeout(animationTimeoutRef.current);
            }
        };
    }, [markdownText, isLoading, useAnimation]);

    if (isLoading || !markdownText || markdownText.trim() === '') {
        return <LoadingText css={css} style={style} isLoading={true} />;
    }

    return (
        <div css={css} style={style}>
            <ReactMarkdown>
                {displayedText}
            </ReactMarkdown>
        </div>
    );
};

export default AIText;
