import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Message } from "rsuite";
import CustomIcons from '../../ui_reusable/CustomIcons';
import EditClientUser from './EditClientUser';

import useStandardToaster from '../../ui_reusable/StandardToaster';
import VersatileGrid from '../../ui_reusable/VersatileGrid';
import { VerySmallNormalTag } from "../../ui_reusable/styled_comps/Tags";
import { GridCellBoldText, GridCellSmallFadedText, GridCellNormalText,ListItemInVersatileGrid, ExpansiveListInVersatileGrid } from '../../ui_reusable/VersatileGridUtils';
import { UserActionsContainer, ToggleContainer, RadioContainer } from './styled_sub_comps/UsersStyledSubComps';
import { StandardRadio, RadioProvider } from '../../ui_reusable/StandardRadioButtons';
import { EasyIconButton } from '../../ui_reusable/styled_comps/Buttons';
import { StandardToggleButton } from '../../ui_reusable/styled_comps/ToggleButtons';
import StandardModal from '../../ui_reusable/StandardModal';
import { StandardCheckbox } from '../../ui_reusable/StandardInputs';



const ListUser = ({ refresh, setRefresh }) => {
    const location = useLocation();
    const { id } = location.state || {};
    const [clientUsers, setClientUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushToast } = useStandardToaster();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showClientContactWarning, setShowClientContactWarning] = useState(false);
    const [clientProjects, setClientProjects] = useState([]);
    const [projectAssociations, setProjectAssociations] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);

    // Flatten the data structure
    const flattenUserData = (users) => {
        return users.map(userData => ({
            ...userData.user,
            is_active: userData.is_active,
            role: userData.role,
            is_client_contact: userData.is_client_contact,
            client: userData.client || {},
            projects: userData.projects || [], // Include projects array
            _original: userData // Keep original data for reference
        }));
    };

    // Fetch client users
    useEffect(() => {
        const getClientUsers = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/${id}/users?include_projects=true`, // Add query parameter
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const res = await response.json();
                setClientUsers(flattenUserData(res.data));
            } catch (error) {
                console.error('Failed to fetch client users:', error);
            } finally {
                setLoading(false);
            }
        };
        getClientUsers();
    }, [id, refresh]);

    // Add project fetching
    useEffect(() => {
        const getProjects = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/?client=${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const res = await response.json();
                setClientProjects(res.data);
            } catch (error) {
                console.error('Failed to fetch client projects:', error);
            }
        };
        getProjects();
    }, [id]);

    // Add new effect to fetch project associations
    useEffect(() => {
        const getProjectAssociations = async () => {
            try {
                const associations = {};
                for (const project of clientProjects){
                    associations[project.id]=[];
                    const projectUsers = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${project.id}/users/`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    const projectUsersResponse = await projectUsers.json();
                    for (const projectUser of projectUsersResponse.data){
                        associations[project.id].push(projectUser.client_user.user.id);
                    }
                }
                setProjectAssociations(associations);
            } catch (error) {
                console.error('Failed to fetch project associations:', error);
            }
        };

        if (clientProjects.length > 0) {
            getProjectAssociations();
        }
    }, [clientProjects, refresh]);

    // Handle toggle active
    const handleToggleActive = async (userId, currentState) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${id}/users/${userId}/`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ is_active: !currentState, user: { is_active: !currentState } })
                }
            );

            if (response.ok) {
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="success">
                        User status updated successfully
                    </Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to update user status');
            }
        } catch (error) {
            console.error('Failed to toggle user status:', error);
            pushToast(
                <Message type="error">
                    Failed to update user status
                </Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    // Handle toggle client contact
    const handleToggleClientContact = async (userId) => {
        try {
            // First, set the selected user as the client contact
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${id}/users/${userId}/`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ is_client_contact: true })
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update client contact status');
            }

            // Unset other users' client contact status
            const otherUsers = clientUsers.filter(user => user.id !== userId && user.is_client_contact);
            for (const otherUser of otherUsers) {
                await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/${id}/users/${otherUser.id}/`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        },
                        body: JSON.stringify({ is_client_contact: false })
                    }
                );
            }

            setRefresh(new Date().getTime());
            pushToast(
                <Message type="success">
                    Client contact updated successfully
                </Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        } catch (error) {
            console.error('Failed to update client contact status:', error);
            pushToast(
                <Message type="error">
                    Failed to update client contact status
                </Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };


    // Handle showing delete confirmation modal
    const handleShowDeleteModal = (user) => {
        if (user.is_client_contact) {
            setSelectedUser(user);
            setShowClientContactWarning(true);
        } else {
            setSelectedUser(user);
            setShowDeleteModal(true);
        }
    };

    // Handle delete user
    const handleDeleteUser = async () => {
        if (!selectedUser) return;

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${id}/users/${selectedUser.id}/`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            // Check specifically for 204 status code which indicates successful deletion
            if (response.status === 204) {
                setRefresh(new Date().getTime());
                setShowDeleteModal(false);
                pushToast(
                    <Message type="success">User deleted successfully.</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to delete user');
            }
        } catch (error) {
            console.error('Failed to delete user:', error);
            pushToast(
                <Message type="error">{error.message}</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    const handleProjectAccessChange = async (userId, projectId, originalData, checked) => {
        try {
            if(!checked){
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/users/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        client_user_id: originalData._original.id
                    })
                });
    
                if (response.ok) {
                    setRefresh(Date.now());
                    pushToast(
                        <Message type="success">Project access updated successfully</Message>,
                        { placement: 'topCenter', duration: 5000 }
                    );
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to update project access');
                }
            } else { 
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/users/${userId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
    
                if (response.ok) {
                    setRefresh(Date.now());
                    pushToast(
                        <Message type="success">Project access updated successfully</Message>,
                        { placement: 'topCenter', duration: 5000 }
                    );
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to update project access');
                }
            }
        } catch (error) {
            console.error('Failed to update project access:', error);
            pushToast(
                <Message type="error">{error.message}</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    const handleShowEditModal = (user) => {
        setSelectedUser(user);
        setShowEditModal(true);
    };

    // Grid configuration
    const headers = [
        {
            title: 'Name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Role',
            key: 'role',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Email',
            key: 'email',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Project Access',
            key: 'project_access',
            type: 'none',
            isSortable: false,
            isSearchable: false
        },
        {
            title: 'Active',
            key: 'is_active',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            valueMapping: {
                true: 'active',
                false: 'inactive'
            },
            borderLeft: true,
            center: true
        },
        {
            title: 'Client Contact',
            key: 'is_client_contact',
            type: 'boolean',
            isSortable: true,
            isSearchable: true,
            valueMapping: {
                true: 'contact',
                false: 'not contact'
            },
            borderLeft: true,
            center: true
        },
        
        {
            title: 'Actions',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            borderLeft: true
        }
    ];

    // Find the ID of the user who is the client contact
    const clientContactId = clientUsers.find(user => user.is_client_contact)?.id || '';

    return (
        <RadioProvider group="clientContact" initValue={clientContactId} onChange={(value) => handleToggleClientContact(value)}>
            <VersatileGrid
                headers={headers}
                data={clientUsers}
                renderRow={(rowData,rowIdx) => [
                    <GridCellBoldText>{rowData.name}</GridCellBoldText>,
                    <GridCellNormalText>
                        <VerySmallNormalTag>{rowData.role}</VerySmallNormalTag>
                    </GridCellNormalText>,
                    <GridCellSmallFadedText>{rowData.email}</GridCellSmallFadedText>,
                    <ExpansiveListInVersatileGrid>
                        {clientProjects.map(project => (
                            <ListItemInVersatileGrid key={project.id}>
                                <StandardCheckbox
                                    checked={projectAssociations[project.id]?.includes(rowData.id)}
                                    onChange={()=>handleProjectAccessChange(rowData.id, project.id, rowData, projectAssociations[project.id]?.includes(rowData.id))}
                                />
                                {project.name}
                            </ListItemInVersatileGrid>
                        ))}
                    </ExpansiveListInVersatileGrid>,

                    <ToggleContainer>
                        <StandardToggleButton
                            checked={rowData.is_active}
                            onChange={() => handleToggleActive(rowData.id, rowData.is_active)}
                        />
                    </ToggleContainer>,
                    <RadioContainer>
                        <StandardRadio
                            group="clientContact"
                            value={rowData.id}
                        />
                    </RadioContainer>,
                    
                    <UserActionsContainer>
                        <EasyIconButton onClick={() => handleShowDeleteModal(rowData)}>
                            <CustomIcons.TrashIcon size="xs" />
                        </EasyIconButton>
                        <EasyIconButton onClick={() => handleShowEditModal(rowData._original)}>
                            <CustomIcons.PenIcon size="xs" />
                        </EasyIconButton>
                    </UserActionsContainer>
                ]}
                maxDisplayedItems={10}
                loading={loading}
            />

            {/* Delete Confirmation Modal */}
            <StandardModal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title>Confirm Delete</StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    Are you sure you want to delete user{' '}
                    <strong>{selectedUser?.name}</strong>?
                    This action cannot be undone.
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="Delete"
                    cancelText="Cancel"
                    confirmColor="#b71c1c"
                    onConfirm={handleDeleteUser}
                    onCancel={() => setShowDeleteModal(false)}
                />
            </StandardModal>

            {/* Client Contact Warning Modal */}
            <StandardModal
                open={showClientContactWarning}
                onClose={() => setShowClientContactWarning(false)}
            >
                <StandardModal.Header>
                    <StandardModal.Title>Cannot Delete Client Contact</StandardModal.Title>
                </StandardModal.Header>
                <StandardModal.Body>
                    The user <strong>{selectedUser?.name}</strong> is selected as a client contact and can't be deleted. 
                    In order to delete this contact, first select another client contact.
                </StandardModal.Body>
                <StandardModal.DefaultFooter
                    confirmText="OK"
                    onConfirm={() => setShowClientContactWarning(false)}
                />
            </StandardModal>

            {/* Edit Client User Modal */}
            <EditClientUser
                user={selectedUser}
                clientId={id}
                setRefresh={setRefresh}
                open={showEditModal}
                onClose={() => setShowEditModal(false)}
            />
        </RadioProvider>
    );
};

export default ListUser;
