import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Import custom components
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import SectionTitle from "../ui_reusable/SectionTitle";
import {TagFilledActive,TagFilledInactive} from "../ui_reusable/styled_comps/Tags";
import {ThickSelectPicker}  from "../ui_reusable/StandardSelectPickers";
import {HeaderContainer} from '../ui_reusable/styled_comps/PlacementMisc';
// Import existing components
import ClientSettings from "./managements/Settings";
import DeactivateClient from "./managements/Deactivate";
import ListUser from "./managements/ListUser";
import ClientProjects from "./managements/Projects";
import CreateClientUser from "./managements/CreateClientUser";
import ClientDrivers from "./managements/ClientDrivers";
import ContextualDrivers from "./managements/ContextualDrivers";
import { useClientContext } from "../../context/ClientContext";

const ShowClient = () => {
    // Preserve existing logic
    const navigate = useNavigate();
    const location = useLocation();
    const {id, clients} = location.state || {};
    const [client, setClient] = useState(null);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const {setSelectedClient} = useClientContext();
    
    // Fetch client data
    useEffect(() => {
        const getClient = async () => {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${id}`,
                { 
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            const res = await response.json();
            setClient(res.data);
        }
        getClient();
    }, [id, refresh]);

    const handleManage = (id) => {
        const client = clients.find(elem=>elem.id===id);
        setSelectedClient({id: client.id, name: client.name, logo: client.logo});
        navigate('/clients/'+id, {
            state: {
                id, 
                clients: clients.map(e => ({
                    id: e.id, 
                    name: e.name,
                    logo: e.logo
                }))
            }
        });
    };

    if (!client) return null;
    console.log("Render Show.js");
    return (
        <>
            {/* Header section with title, status and client picker */}
            <HeaderContainer>
                <SectionTitle>Manage Client</SectionTitle>
                    
                    {client.is_active ? <TagFilledActive label="Client is active" / > : <TagFilledInactive label="Client is inactive" / >}
                    
                    <ThickSelectPicker 
                        placeholder="Select client"
                        value={client.id}  // Changed from defaultValue to value
                        size="lg" 
                        data={clients.map((client, i) => ({
                            key: i+1,
                            label: client.name,
                            value: client.id
                        }))}
                        onChange={handleManage}
                        style={{ width: 250 }}
                    />

            </HeaderContainer>

            {/* Main content sections using SimpleAccordion */}
            <SimpleAccordion id={"manageClientPageAccordion"} defaultActiveKey={['settings']}>
                <SimpleAccordion.Panel
                    header={'Client settings'}
                    eventKey="settings"
                >
                    <ClientSettings client={client} />
                </SimpleAccordion.Panel>

                <SimpleAccordion.Panel
                    header={'Users'}
                    eventKey="users"

                >
                    <CreateClientUser 
                        clientId={id}
                        setRefresh={setRefresh} 
                    />
                    <ListUser refresh={refresh} setRefresh={setRefresh}/>
                </SimpleAccordion.Panel>

                <SimpleAccordion.Panel
                    header={'Projects'}
                    eventKey="projects"

                >
                    <ClientProjects />
                </SimpleAccordion.Panel>

               
                    
                <SimpleAccordion.Panel
                    header={'Client Drivers'}
                    eventKey="client_drivers"
                >
                    <ClientDrivers clientId={id} />
                </SimpleAccordion.Panel>

                <SimpleAccordion.Panel
                    header={'Contextual Drivers'}
                    eventKey="contextual_drivers"
                >
                    <ContextualDrivers clientId={id} />
                </SimpleAccordion.Panel>

                
{/*
                <SimpleAccordion.Panel
                    header={'Change log'}
                    eventKey="changelog"

                >
                    
                </SimpleAccordion.Panel>
*/}

                <SimpleAccordion.Panel 
                    header={'Deactivate and delete'}
                    eventKey="deactivate"
                    initClosed={true}
                    color="#fffcf5" 
                    >
                    <DeactivateClient client={client} setRefresh={setRefresh}/>
                </SimpleAccordion.Panel>
            </SimpleAccordion>
        </>
    );
}

export default ShowClient;