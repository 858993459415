import React, { useState } from 'react';
import { StyledModalOverlay, StyledModal, ModalTitle, StyledModalHeader, StyledModalBody, StyledModalFooter, CloseButton } from './styled_sub_comps/ModalStyles';
import { ModalButtonConfirm, ModalButtonCancel, ModalButtonStandardGhost } from './styled_comps/Buttons';

export const ModalDefaultButtonFooter = ({ 
    confirmText = 'Confirm', 
    cancelText,
    onConfirm,
    onCancel,
    confirmColor,
    confirmGhost = false,
    flip = false
}) => {
    const buttons = [
        cancelText && (
            <ModalButtonCancel key="cancel" onClick={onCancel}>
                {cancelText}
            </ModalButtonCancel>
        ),
        confirmGhost ? (
            <ModalButtonStandardGhost 
                key="confirm"
                onClick={onConfirm}
            >
                {confirmText}
            </ModalButtonStandardGhost>
        ) : (
            <ModalButtonConfirm 
                key="confirm"
                onClick={onConfirm}
                style={confirmColor ? { backgroundColor: confirmColor } : undefined}
            >
                {confirmText}
            </ModalButtonConfirm>
        )
    ].filter(Boolean);

    return flip ? buttons.reverse() : buttons;
};

const Modal = ({ 
    children,
    title,
    size,
    open = false,
    onClose,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    confirmColor,
    confirmGhost = false,
    flip = false,
    ...props 
}) => {
    const [mouseDownOnOverlay, setMouseDownOnOverlay] = useState(false);

    const handleOverlayMouseDown = (e) => {
        // Only set to true if the click started on the overlay itself
        if (e.target === e.currentTarget) {
            setMouseDownOnOverlay(true);
        } else {
            setMouseDownOnOverlay(false);
        }
    };

    const handleOverlayMouseUp = (e) => {
        // Only close if both mousedown and mouseup occurred on overlay
        if (mouseDownOnOverlay && e.target === e.currentTarget) {
            onClose();
        }
        setMouseDownOnOverlay(false);
    };

    if (!open) return null;

    const modalContent = (
        <>
            {title && (
                <StyledModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                    <CloseButton onClick={onClose} aria-label="Close">
                        ×
                    </CloseButton>
                </StyledModalHeader>
            )}
            {children}
            {(confirmText || cancelText) && (
                <StyledModalFooter>
                    <ModalDefaultButtonFooter
                        confirmText={confirmText}
                        cancelText={cancelText}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        confirmColor={confirmColor}
                        confirmGhost={confirmGhost}
                        flip={flip}
                    />
                </StyledModalFooter>
            )}
        </>
    );

    return (
        <StyledModalOverlay 
            onMouseDown={handleOverlayMouseDown}
            onMouseUp={handleOverlayMouseUp}
        >
            <StyledModal 
                size={size} 
                onClick={e => e.stopPropagation()} 
                {...props}
            >
                {modalContent}
            </StyledModal>
        </StyledModalOverlay>
    );
};

Modal.Header = ({ children, onClose }) => (
    <StyledModalHeader>
        <ModalTitle>{children}</ModalTitle>
        <CloseButton onClick={onClose} aria-label="Close">
            ×
        </CloseButton>
    </StyledModalHeader>
);

Modal.Body = StyledModalBody;
Modal.Footer = StyledModalFooter;
Modal.DefaultFooter = ModalDefaultButtonFooter;

export default Modal;
