import React, { useRef, useEffect, useState } from 'react';

import ReportAccordion from '../../ui_reusable/ReportAccordion';
import ExecutiveSummary from './sections/ExecutiveSummary';
import ModelPerformance from './sections/ModelPerformance';
import DriverImportance from './sections/DriverImportance';
import DriverImpact from './sections/DriverImpact';
import SingleDriver from './sections/SingleDriver';
import MyComments from './sections/MyComments';
import DriverOptimization from './sections/DriverOptimization';
import Header from './sections/Header';
import Setup from './sections/Setup';

import { useLocation } from 'react-router-dom';
import { useClientContext } from '../../../context/ClientContext';

const ShowReport = () => {
    const [reportData, setReportData] = useState(null);
    const [pages, setPages] = useState([]); // Add this state
    const accordionContextId = useRef(null);
    const sectionRefs = useRef({});
    const firstElementRef = useRef(null);
    const [activePanels, setActivePanels] = useState([]); // Initialize empty
    
    // Map of pages with their corresponding configuration
    const pageConfig = [
        {
            title: "Executive Summary",
            id: 'executive_summary',
            ref: useRef(null),
            isLoading: true,
            content: ExecutiveSummary,
            props: { reportData } // Example of component needing props
        },
        {
            title: "Model Performance",
            id: 'model_performance',
            ref: useRef(null),
            isLoading: true,
            content: ModelPerformance,
            props: { plotData: reportData?.predict_plot && JSON.parse(reportData.predict_plot)} // No special props needed
        },
        {
            title: "Driver Importance",
            id: 'driver_importance',
            ref: useRef(null),
            isLoading: true,
            content: DriverImportance,
            props: { plotData: reportData?.feature_importance && JSON.parse(reportData.feature_importance)}
        },
        {
            title: "Driver Impact",
            id: 'driver_impact',
            ref: useRef(null),
            isLoading: true,
            content: DriverImpact,
            props: {}
        },
        {
            title: "Individual Drivers",
            id: 'individual_drivers',
            ref: useRef(null),
            isLoading: true,
            content: SingleDriver,
            props: {}
        },
        {
            title: "Driver optimization",
            id: 'driver_optimization',
            ref: useRef(null),
            isLoading: true,
            content: DriverOptimization,
            props: {}
        },
        {
            title: "My Comments",
            id: 'my_comments',
            ref: useRef(null),
            isLoading: true,
            content: MyComments,
            props: {}
        }
    ];
    const location = useLocation();
    const {id} = location.state;
    const {selectedClient} = useClientContext();
    const [reportTemplates, setReportTemplates] = useState([
        {
            name: 'All',
            panels: ['executive_summary', 'model_performance', 'driver_importance', 'driver_impact', 'individual_drivers','driver_optimization','my_comments']
        },
        {
            name: 'Executive',
            panels: ['executive_summary']
        },
    ]);

    useEffect(() => {
        const getReportData = async () => {
            if(id){
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/artifacts/${id}/report`,
                        { 
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                    );
                    const res = await response.json();
                    const data = res.data;
                    const timeframe = data?.artifact?.timeframe;
                    const timeframe_str = `${timeframe?.from} - ${timeframe?.to}`;
                    const dependent_driver = data?.artifact?.dependent_driver;
                    const independent_drivers = data?.artifact?.independent_drivers;
                    const local_drivers = independent_drivers.filter(e=>e.category==='client');
                    const contextual_drivers = independent_drivers.filter(e=>e.is_contextual);
                    const project_solution = data?.artifact?.project_solution;
            
                    setReportData({
                        name: data?.name,
                        model_fit_score: data?.artifact?.fit_score,
                        model_health_score: data?.artifact?.health_score,
                        run_date: new Date(data?.artifact?.run_at).toISOString(),
                        solution: data?.artifact?.project_solution?.solution.name,
                        outcome_variable: dependent_driver.name,
                        drivers: `${local_drivers.length} local + ${contextual_drivers.length} contextual`,
                        timeframe: timeframe_str,
                        prognosis: timeframe?.prognosis || 'None',
                        features: project_solution?.features || {},
                        predict_plot: data?.report?.predict_plot,
                        feature_importance: data?.report?.feature_importance,
                        level: data?.level,
                        include_plus: data?.include_plus,
                        confidence: data?.confidence,
                    });
                } catch (error) {
                    console.error(error);   
                }
            }
        };
        getReportData();
    }, [id]);

    useEffect(()=>{
        const getCustomTemplates = async()=>{
            if(selectedClient){
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/clients/${selectedClient.id}/report_templates`,
                        { 
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        }
                    );
                    const res =  await response.json();
                    setReportTemplates(prev => [
                        ...prev, 
                        ...res.data.map((template, i)=> {
                            return {
                                key: i+1,
                                name: template.name,
                                panels: template.items.map(e=>e.slug)
                            };
                        })
                    ]);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        getCustomTemplates();
    },[selectedClient]);


    return (
        <>
            <Header 
                solution={reportData?.solution}
                runDate={reportData?.run_date}
                modelFitScore={reportData?.model_fit_score}
                modelHealthScore={reportData?.model_health_score}
            />
            
            {
                reportTemplates && <Setup 
                    pageConfig={pageConfig}  // Pass pageConfig instead of pages
                    templates={reportTemplates}
                    onPanelSelection={setActivePanels}
                    initPanelTemplate="All"
                    accordionContextId={accordionContextId.current}
                    reportData={reportData && reportData}
                />
            }
            
            <ReportAccordion 
                id={"reportAccordion"}
                defaultActiveKey={['executive_summary']}
                contextIdGenerated={(contextId) => {
                    accordionContextId.current = contextId;
                }}
            >
                {pageConfig.map((panel) => (
                    activePanels.includes(panel.id) && (
                        <ReportAccordion.Panel 
                            
                            key={panel.id}
                            eventKey={panel.id}
                            header={panel.title}
                            helpText={panel.helpText}
                        >
                            <panel.content 
                                ref={el => sectionRefs.current[panel.id] = el}
                                {...panel.props}
                            />
                        </ReportAccordion.Panel>
                    )
                ))}
            </ReportAccordion>

        </>
    );
}

export default ShowReport;