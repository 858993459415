import React from 'react';
import {
    ShortInfoContainer,
    ShortInfoLabel,
    ShortInfoValue
} from './styled_sub_comps/InfoBoxStyledSubComps';


const InfoBox = ({ infoLabel, infoValue, children }) => {
    return (
        <ShortInfoContainer>
            <ShortInfoValue>{infoValue}</ShortInfoValue>
            <div style={{display: 'flex', flexDirection: 'row',alignItems: 'flex-end' }}>
                {children}
            </div>
            <ShortInfoLabel>{infoLabel}</ShortInfoLabel>
            
        </ShortInfoContainer>
    );
};

export default InfoBox;