/* VersatileGrid.js
 * A flexible data grid component that supports:
 * - Sortable columns
 * - Advanced search functionality
 * - Pagination
 * - Dynamic column widths
 * - Custom cell rendering
 * - Custom header content
 * 
 * This component is designed to handle both simple and complex data sets
 * while maintaining consistent performance through pagination and
 * optimized rendering strategies.
 */

import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import { Input, InputGroup, Button } from 'rsuite';
import CustomIcons from './CustomIcons';
import SearchIcon from '@rsuite/icons/Search';
import {
    GridWrapper,
    GridTop,
    GridBottom,
    GridContainer,
    CellContent,
    CellStyle,
    SortIcon,
    HeaderCell,
    RegularHeaderCell,
    Cell,
    PaginationContainer,
    PageButton,
    PageIndicator,
    NumberButton,
    GridHeaderContainer,
    GridHeaderLeft,
    GridHeaderRight,
    PagesContainer,
    NavigationButton,
    GridArea,
    NoItemsMessage,
    CellKeeper,
    EmptySpace,
    FullWidthCell
} from './styled_sub_comps/VersatileGridStyledSubComps';
//import font awsome
import theme from '../../styles/theme';
import { performSearch, ensureDataIds } from './logic/SearchLogic';
import {ExpansiveListInVersatileGrid, SmoothFullWidthCell} from './VersatileGridUtils';
/* Data comparison utilities for sorting different data types
 * These functions normalize different data types for consistent sorting behavior
 */
const compareValues = (a, b, type) => {
    if (a === b) return 0;
    if (a === null || a === undefined) return 1;
    if (b === null || b === undefined) return -1;

    switch (type) {
        case 'number':
            return Number(a) - Number(b);
        case 'boolean':
            return a === b ? 0 : a ? -1 : 1;
        case 'datetime':
            return new Date(a) - new Date(b);
        case 'string':
        default:
            return String(a).localeCompare(String(b));
    }
};

/* Data sorting utility
 * Handles sorting of data array based on column configuration
 */
const sortData = (data, key, type, direction) => {
    return [...data].sort((a, b) => {
        const result = compareValues(a[key], b[key], type);
        return direction === 'asc' ? result : -result;
    });
};

/* Column width calculator
 * Uses initial render measurements to establish consistent column widths
 * This prevents layout shifts when data changes or during sorting/filtering
 */
const calculateColumnWidths = (gridElement, columns) => {
    if (!gridElement) return null;
    
    // Get all header cells (first row)
    const headerCells = Array.from(gridElement.children).slice(0, columns);
    
    // Calculate the total width of all columns
    const totalWidth = headerCells.reduce((sum, cell) => sum + cell.offsetWidth, 0);
    
    // Convert each column's width to a relative fraction
    const fractions = headerCells.map(cell => 
        ((cell.offsetWidth / totalWidth) * 100).toFixed(3) + 'fr'
    );
    
    return fractions.join(' ');
};

/* Pagination utilities
 * Generates page numbers with ellipsis for large data sets
 * Shows direct navigation for nearby pages and shortcuts for distant ones
 */
const getPageNumbers = (currentPage, totalPages) => {
    // If we have 5 or fewer pages, just show all of them
    if (totalPages <= 5) {
        return Array.from({ length: totalPages }, (_, i) => i);
    }

    const delta = 2; // Number of pages to show before and after current page
    const range = [];
    const rangeWithDots = [];

    // Always show first page
    range.push(0);

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i > 0 && i < totalPages - 1) {
            range.push(i);
        }
    }

    // Always show last page
    if (totalPages > 1) {
        range.push(totalPages - 1);
    }

    // Sort and remove duplicates
    const uniqueRange = [...new Set(range)].sort((a, b) => a - b);

    // Add ellipsis where needed
    let prev = 0;
    for (const i of uniqueRange) {
        if (i - prev === 2) {
            rangeWithDots.push(prev + 1);
        } else if (i - prev !== 1) {
            rangeWithDots.push('...');
        }
        rangeWithDots.push(i);
        prev = i;
    }

    return rangeWithDots;
};

const checkForExpansiveList = (cells) => {
    return cells.some(cell => 
        React.isValidElement(cell) && 
        cell.type === ExpansiveListInVersatileGrid
    );
};

/* Main grid component
 * @param headers - Array of column definitions with sorting and searching options
 * @param data - Array of data objects to display
 * @param renderRow - Function to render each row's cells
 * @param headerInfo - Optional custom header content
 * @param maxDisplayedItems - Number of items per page
 */
const VersatileGrid = ({ 
    headers, // headers can now include a style property for column-specific styling
    data, 
    renderRow,
    headerInfo,  // New prop for custom header content
    maxDisplayedItems = 10,
    gridContainerStyle, // renamed from style
    renderIntersectingRow, // New prop for rendering intersecting rows
    dataForIntersecting,    // New prop for intersecting row data
    searchPlaceholder = 'Search...',
}) => {
    const columns = headers.length;
    const [activeSort, setActiveSort] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(0);
    const [sortedData, setSortedData] = useState(data);
    const [gridHeight, setGridHeight] = useState(null);
    const gridContainerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchScores, setSearchScores] = useState(new Map());
    const [maxHeight, setMaxHeight] = useState(0);
    const heightObserverRef = useRef(null);
    const columnWidthObserverRef = useRef(null);
    const gridRef = useRef(null);
    const widthCalculationTimeoutRef = useRef(null);
    const [calculatedGridTemplate, setCalculatedGridTemplate] = useState(null);

    // Ensure data has IDs and augment with _originalIndex
    const dataWithIds = useMemo(() => {
        return data.map((item, idx) => ({
            ...item,
            _searchId: `id_${idx}`,  // for search functionality
            _originalIndex: idx      // for maintaining original position reference
        }));
    }, [data]);
    
    // Create a combined dataset that links main data with intersecting data
    const combinedData = useMemo(() => {
        if (!dataForIntersecting) return dataWithIds;
        return dataWithIds.map((item, idx) => ({
            ...item,
            _intersectingData: dataForIntersecting[idx] || {} // Provide empty object as fallback
        }));
    }, [dataWithIds, dataForIntersecting]);

    /* Search handling
     * Processes search input and updates grid data immediately
     * Uses advanced search logic for multi-field matching
     */
    useEffect(() => {
        const { results, scores } = performSearch(combinedData, headers, searchTerm);
        const filteredData = results.map(id => 
            combinedData.find(item => item._searchId === id)
        );
        setSearchScores(scores);
        setSortedData(filteredData);
    }, [searchTerm, combinedData, headers]);
    
    // Calculate pagination values
    const totalPages = Math.ceil(sortedData.length / maxDisplayedItems);
    const startIndex = currentPage * maxDisplayedItems;
    const endIndex = startIndex + maxDisplayedItems;
    
    // Get current page data
    const currentData = sortedData.slice(startIndex, endIndex);

    /* Column width calculation and observation
     * Now only runs when gridTemplateColumns is not provided in gridContainerStyle
     */
    useEffect(() => {
        // First check data
        if (!data.length) return;

        // Then check for provided template columns
        if (gridContainerStyle?.gridTemplateColumns) return;
        
        // Finally check ref (which will exist if data exists)
        if (!gridRef.current) return;

        const calculateAndSetWidths = () => {
            // Get all header cells (first row)
            const headerCells = Array.from(gridRef.current.children).slice(0, columns);
            
            if (!headerCells.length) {

                return false;
            }
            
            const totalWidth = headerCells.reduce((sum, cell) => sum + cell.offsetWidth, 0);
            
            if (!totalWidth) {

                return false;
            }
            
            const fractions = headerCells.map(cell => 
                ((cell.offsetWidth / totalWidth) * 100).toFixed(3) + 'fr'
            );
            
            setCalculatedGridTemplate(fractions.join(' '));
            return true;
        };

        // Rest of the observer setup remains the same
        const intersectionObserver = new IntersectionObserver((entries) => {
            // ...existing observer code...
        }, { threshold: 0.1 });

        const mutationObserver = new MutationObserver(() => {
            // ...existing observer code...
        });

        const success = calculateAndSetWidths();
        if (!success) {
            intersectionObserver.observe(gridRef.current);
            mutationObserver.observe(gridRef.current, {
                childList: true,
                subtree: true,
                attributes: true
            });
        }

        return () => {
            intersectionObserver.disconnect();
            mutationObserver.disconnect();
            if (widthCalculationTimeoutRef.current) {
                clearTimeout(widthCalculationTimeoutRef.current);
            }
        };
    }, [columns, data, gridContainerStyle]); // Added gridContainerStyle as dependency

    /* Height measurement and stabilization
     * Uses IntersectionObserver to detect when grid becomes visible
     * Sets min-height to largest observed height, allowing growth but preventing shrinking
     */
    useEffect(() => {
        if (!gridContainerRef.current) return;

        const measureHeight = () => {
            if (!gridContainerRef.current) return;
            
            const currentHeight = gridContainerRef.current.offsetHeight;
            
            if (currentHeight > maxHeight) {
                setMaxHeight(currentHeight);
                gridContainerRef.current.style.minHeight = `${currentHeight}px`;

            }
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    requestAnimationFrame(measureHeight);
                }
            });
        }, { threshold: 0.1 });

        observer.observe(gridContainerRef.current);
        heightObserverRef.current = observer;

        return () => {
            if (heightObserverRef.current) {
                heightObserverRef.current.disconnect();
            }
        };
    }, [maxHeight, data]);

    // Update sorted data when input data changes
    useEffect(() => {
        setSortedData(dataWithIds);
        setCurrentPage(0);
    }, [dataWithIds]);

    /* Column sorting handler 
     * Manages sort state and direction
     * Resets pagination when sort changes
     */
    const handleSort = (columnIndex) => {
        const header = headers[columnIndex];
        let newDirection = activeSort === columnIndex 
            ? (sortDirection === 'asc' ? 'desc' : 'asc')
            : 'asc';
        
        setActiveSort(columnIndex);
        setSortDirection(newDirection);

        // Sort using the combined data to maintain relationships
        const sorted = sortData(sortedData, header.key, header.type, newDirection);
        setSortedData(sorted);
        setCurrentPage(0);
    };

    /* Render a single row based on type (normal or intersecting)
     * @param rowData - Data for the current row
     * @param rowIdx - Index of the row
     * @param isIntersecting - Whether this is an intersecting row
     * @returns JSX for the row
     */
    const renderGridRow = (rowData, rowIdx, isIntersecting = false) => {

        if (isIntersecting && renderIntersectingRow) {
            
            return (
                <SmoothFullWidthCell 
                    columns={columns}
                    key={`intersecting-row-${rowIdx}`}
                    isLastRow={rowIdx === currentData.length * 2 - 1}
                >
                    {renderIntersectingRow(rowData, rowIdx, rowData._originalIndex)}
                </SmoothFullWidthCell>
            );
        }

        const renderedCells = renderRow(rowData, rowIdx, rowData._originalIndex);
        const hasExpansiveList = checkForExpansiveList(renderedCells);
        const isLastRow = rowIdx === (renderIntersectingRow ? currentData.length * 2 - 1 : currentData.length - 1);

        return renderedCells.map((cell, cellIdx) => (
            <CellKeeper 
                borderLeft={headers[cellIdx].borderLeft}
                key={`cell-${rowIdx}-${cellIdx}`}
                isLastRow={isLastRow}
                style={headers[cellIdx].style}
            >
                <EmptySpace hasExpansiveList={hasExpansiveList} />
                <Cell 
                    columns={columns} 
                    center={headers[cellIdx].center}
                >
                    <CellContent>
                        {cell}
                    </CellContent>
                </Cell>
            </CellKeeper>
        ));
    };

    /* Generate all rows including intersecting rows if enabled
     * Uses flatMap to interleave normal and intersecting rows
     */
    const generateRows = () => {
        if (renderIntersectingRow) {  // Removed dataForIntersecting check
            return currentData.flatMap((rowData, idx) => {
                const originalIdx = rowData._originalIndex;
                
                const normalRow = renderGridRow(rowData, idx * 2, false);
                
                // Always generate intersecting row if renderIntersectingRow is provided
                const intersectingRow = renderGridRow(
                    rowData,  // Pass the original rowData instead of _intersectingData
                    idx * 2 + 1,
                    true
                );
                
                // Only include intersecting row if it returns content
                const intersectingContent = renderIntersectingRow(rowData, idx * 2 + 1, originalIdx);
                return intersectingContent ? [normalRow, intersectingRow] : [normalRow];
            });
        }

        return currentData.map((rowData, idx) => 
            renderGridRow(rowData, idx, false)
        );
    };

    return (
        <GridWrapper>
            <GridTop>
                <GridHeaderContainer>
                    <GridHeaderLeft>
                        {headerInfo}  {/* Render custom header content */}
                    </GridHeaderLeft>
                    <GridHeaderRight>
                        {data.length > 1 && (
                            <InputGroup inside style={{ width: '100%', height: '2.3rem'}}>
                                <Input 
                                    placeholder={searchPlaceholder} 
                                    value={searchTerm}
                                    onChange={value => setSearchTerm(value)}
                                />
                                <InputGroup.Button>
                                    <SearchIcon />
                                </InputGroup.Button>
                            </InputGroup>
                        )}
                    </GridHeaderRight>
                </GridHeaderContainer>
            </GridTop>

            {data.length > 0 ? (
                <>
                    <GridArea
                        ref={gridContainerRef}
                        style={{ height: 'auto' }} // Always auto, min-height handles the rest
                    >
                        <GridContainer 
                            ref={gridRef}
                            columns={columns}
                            style={{
                                ...(calculatedGridTemplate && !gridContainerStyle?.gridTemplateColumns ? {
                                    gridTemplateColumns: calculatedGridTemplate
                                } : {}),
                                ...gridContainerStyle // renamed from style
                            }}
                        >
                            {/* Render headers with column-specific styles */}
                            {headers.map((header, idx) => (
                                header.isSortable ? (
                                    <HeaderCell 
                                        columns={columns}   
                                        key={`header-${idx}`}
                                        onClick={() => handleSort(idx)}
                                        className={activeSort === idx ? 'active' : ''}
                                        borderLeft={header.borderLeft}
                                        center={header.center}
                                        style={header.style} // Apply column-specific style
                                    >
                                        <CellContent>
                                            {header.title}
                                            <SortIcon>
                                                {activeSort === idx ? (
                                                    sortDirection === 'asc' ? 
                                                         <CustomIcons.ChevronUpIcon  color={theme.colors.veryVeryFadedText} size={"xxs"}  /> : 
                                                        <CustomIcons.ChevronDownIcon  color={theme.colors.veryVeryFadedText} size={"xxs"}/>
                                                ) : (
                                                    <CustomIcons.ChevronUpIcon color={theme.colors.veryVeryFadedText} size={"xxs"} />
                                                )}
                                            </SortIcon>
                                        </CellContent>
                                    </HeaderCell>
                                ) : (
                                    <HeaderCell 
                                        columns={columns} 
                                        key={`header-${idx}`}
                                        borderLeft={header.borderLeft}
                                        center={header.center}
                                        style={header.style} // Apply column-specific style
                                    >
                                        <CellContent>{header.title}</CellContent>
                                    </HeaderCell>
                                )
                            ))}

                            {/* Render all rows using the new generator */}
                            {generateRows()}
                        </GridContainer>
                    </GridArea>

                    <GridBottom>
                        {/* Pagination controls */}
                        {sortedData.length > 0 && totalPages > 1 && (
                            <PaginationContainer>
                                <NavigationButton 
                                    onClick={() => setCurrentPage(p => p - 1)}
                                    disabled={currentPage === 0}
                                >
                                    Previous
                                </NavigationButton>

                                <PagesContainer>
                                    {getPageNumbers(currentPage, totalPages).map((pageNum, idx) => (
                                        pageNum === '...' ? (
                                            <PageIndicator key={`ellipsis-${idx}`}>...</PageIndicator>
                                        ) : (
                                            <NumberButton
                                                key={`page-${pageNum}`}
                                                onClick={() => setCurrentPage(pageNum)}
                                                className={currentPage === pageNum ? 'active' : ''}
                                            >
                                                {pageNum + 1}
                                            </NumberButton>
                                        )
                                    ))}
                                </PagesContainer>

                                <NavigationButton 
                                    onClick={() => setCurrentPage(p => p + 1)}
                                    disabled={currentPage >= totalPages - 1}
                                >
                                    Next
                                </NavigationButton>
                            </PaginationContainer>
                        )}
                    </GridBottom>
                </>
            ) : (
                <GridArea>
                    <NoItemsMessage>No items</NoItemsMessage>
                </GridArea>
            )}
        </GridWrapper>
    );
};

export default VersatileGrid;