import React, { useEffect, useState } from "react";
import { Message } from "rsuite"; // Add Message import back
import useStandardToaster from '../../ui_reusable/StandardToaster';
import SimpleAccordion from '../../ui_reusable/SimpleAccordion';
import FAQAccordion from '../ui_reusable/FAQAccordion';

import {
    FAQContainer,
    FAQPanel,
    FAQHeader,
    FAQContent,
    ActionButtons,
    FormGroup,
    MainButtonContainer,
    FormLabel,
    ModalText,
    ModalHighlight
} from './styled_sub_comps/ManageFAQStyledSubComps';

const ListFAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { pushToast } = useStandardToaster();

    useEffect(() => {
        const getLearning = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { 
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const res = await response.json();
                
                // Check if learning exists and has faq property, otherwise use empty array
                setFaqData(res.data?.faq || []);
                
                // If no learning object exists yet, create one with empty FAQ
                if (!res.data) {
                    await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        },
                        body: JSON.stringify({ faq: [] })
                    });
                }
            } catch (error) {
                console.error('Failed to fetch FAQs:', error);
                pushToast(
                    <Message type="error">Failed to load FAQs</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                setFaqData([]); // Set empty array as fallback
            }
        };
        
        getLearning();
    }, [refresh]);

 

    return (
        <>
            <SimpleAccordion id={"showFAQPageAccordion"}  defaultActiveKey={["1"]}>
                <SimpleAccordion.Panel 
                    header="FAQ"
                    eventKey="1"
                    noCollapse
                >
                    <FAQContainer>
                    
                        <FAQAccordion id={"showFAQAccordion"}>
                            {faqData.map((elem, i) => (
                                <FAQAccordion.Panel
                                    key={i+1}
                                    eventKey={`faq-${elem.id}`}
                                    header={elem.question}
                                    text={elem.answer}
                                    showActions={false}
                                />
                            ))}
                        </FAQAccordion>
                    </FAQContainer>
                </SimpleAccordion.Panel>
            </SimpleAccordion>
  
        </>
    );
};

export default ListFAQ;
