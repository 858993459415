import { useNavigate } from "react-router-dom";
import React from "react";
import { useProjectContext } from "../../context/ProjectContext";
import { useEffect, useState } from "react";
import { HeaderContainer } from "../ui_reusable/styled_comps/PlacementMisc";
import SectionTitle from "../ui_reusable/SectionTitle";
import { StandardButtonFaded } from "../ui_reusable/styled_comps/Buttons";
import { SolutionPoster, SolutionPosterContainer, SolutionPosterTitle } from "./styled_sub_comps/DashboardStyledSubComps";
import ClientProjectPicker from '../comp_reusable/ClientProjectPicker';
import SimpleAccordion from "../ui_reusable/SimpleAccordion";

const ModelDashboard = ()=>{
    const navigate = useNavigate();
    const [solutions, setSolutions] = useState([]);
    const [projectSolutions, setProjectSolutions] = useState([]);
    const {selectedProject} = useProjectContext();

    useEffect(()=>{
        const getSolutions = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/solutions`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setSolutions(res.data);
        }
        const getProjectSolutions = async () =>{
            if(selectedProject){
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${selectedProject.id}/solutions`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                const res = await response.json();
                setProjectSolutions(res.data);
            }
        }
        getSolutions();
        getProjectSolutions();
    },[selectedProject]);

    const handleClick = (solutionId) => {
        const isSolutionPresent = projectSolutions.some(elem => elem.solution.id === solutionId);
      
        if (isSolutionPresent) {
          navigate('/models/build', { state: { solutionId: solutionId } });
        }
    };

    return (
        <>
            <HeaderContainer>
                <SectionTitle>Build new model</SectionTitle>
                <ClientProjectPicker />
            </HeaderContainer>
           { <SimpleAccordion id={"dashboardPageAccordion"} defaultActiveKey={['select_model']}>   
                <SimpleAccordion.Panel
                    header={'Select model'}
                    eventKey="select_model"
                    noCollapse
                >
                    <SolutionPosterContainer>
                        {solutions && solutions.map((solution, i)=>(
                            <SolutionPoster key={i+1}>
                                <SolutionPosterTitle>{solution.name}</SolutionPosterTitle>
                                <StandardButtonFaded 
                                    disabled={!projectSolutions.find(elem=>elem.solution.id===solution.id)} 
                                    onClick={()=>handleClick(solution.id)}
                                >
                                    Build model
                                </StandardButtonFaded>
                            </SolutionPoster>
                        ))}
                    </SolutionPosterContainer>
                </SimpleAccordion.Panel>
                </SimpleAccordion>  
            }

        </>
    )
}
export default ModelDashboard;