/**
 * BaseAccordion Component
 * 
 * The core logic of our custom accordion. This component provides smooth animation and dynamic height adjustment.
 * This component serves as the base for all accordion variants in the application.
 * 
 * Key features:
 * - Smooth height animations using react-spring
 * - Dynamic content height adjustment using ResizeObserver
 * - Support for controlled and uncontrolled states
 * - Customizable header rendering
 * - Support for non-collapsible panels
 * 
 * ScrollContext Integration:
 * - Each accordion registers itself with ScrollContext using a unique contextId
 * - Provides an API for ScrollContext to:
 *   1. Access individual panels
 *   2. Open/close panels programmatically
 *   3. Access panel header elements for scrolling
 * - This enables external control of accordion behavior through ScrollContext
 */

import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useScroll } from '../../context/ScrollContext';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

// Create a context to manage the accordion's state across components
const AccordionContext = createContext();

/**
 * Main Accordion container that manages the state of all panels.
 * @param {Object} props
 * @param {React.ReactNode} props.children - Accordion panels
 * @param {string|null} props.defaultActiveKey - Key of initially active panel
 * @param {Function} props.onToggle - Optional callback for panel state changes
 */
const BaseAccordion = ({
  children,
  id, // New prop for explicit ID
  defaultActiveKey = null,
  onToggle = null,
  contextIdGenerated = ()=>{}
}) => {
  const location = useLocation();
  const { registerAccordionContext, registerAccordionState, isNavigatingBack } = useScroll(); // Add isNavigatingBack here

  const [contextId] = useState(() => {
    if (!id) {
      throw new Error(
        'ID prop is required for BaseAccordion. This is necessary for navigation state persistence. ' +
        'Please provide a unique ID that represents this accordion\'s position in the UI hierarchy. ' +
        'Example: <SimpleAccordion id="client_settings_accordion">'
      );
    }
    const generatedId = `${location.pathname}_${id}`;
    ////console.log('🆔 Generated accordion ID:', generatedId);
    contextIdGenerated(generatedId);
    return generatedId;
  });

  // Initialize activeKeys with saved state or default
  const [activeKeys, setActiveKeys] = useState(() => {
    // Check if we have saved state in history
    const savedState = window.history.state?.navigationInfo?.accordionStates?.[contextId];
    if (savedState) {
      //console.log('📦 Restoring saved state for', contextId, ':', savedState);
      return savedState;
    }
    
    // Otherwise use defaultActiveKey or initialize from children
    const initialKeys = defaultActiveKey || React.Children.map(children, child => child.props.eventKey) || [];
    ////console.log('🔑 Using initial active keys for', contextId, ':', initialKeys);
    return initialKeys;
  });

  const accordionsRef = useRef(new Map());

  // Register this accordion context when mounted
  useEffect(() => {
    if (contextId) {
      ////console.log('📥 Registering accordion context:', contextId);
      const contextApi = {
        getAccordion: (key) => {
          //console.log('🔍 Getting accordion:', key);
          return accordionsRef.current.get(key);
        },
        activateAccordion: async (key) => {
          //console.log('🎯 Activating single accordion:', { contextId, key });
          setActiveKeys(prev => 
            prev.includes(key) ? prev : [...prev, key]
          );
          return new Promise(resolve => setTimeout(resolve, 300));
        },
        activateMultipleAccordions: async (keys) => {
          //console.log('🔓 Activating multiple accordions for', contextId, 'with keys:', keys);
          setActiveKeys(keys);
          return Promise.resolve();
        }
      };
      registerAccordionContext(contextId, contextApi);
    }
  }, [contextId, registerAccordionContext]); // Add registerAccordionContext to deps

  // Function to handle toggling of panels - now removes from activeKeys when closing
  const handleToggle = (eventKey) => {
    setActiveKeys(prev => {
      const newKeys = prev.includes(eventKey)
        ? prev.filter(key => key !== eventKey)
        : [...prev, eventKey];
      
      //console.log('🔄 Toggle accordion:', { contextId, eventKey, newKeys });
      // Move registerAccordionState to after the state update
      setTimeout(() => {
        registerAccordionState(contextId, newKeys);
      }, 0);
      return newKeys;
    });
  };
  ////console.log("rendering with activeKeys", activeKeys);

  return (
    // Provide active keys and toggle function to all children via context
    <AccordionContext.Provider value={{ 
      activeKeys, 
      handleToggle,
      registerAccordion: (key, ref) => {
        accordionsRef.current.set(key, ref);
      },
      unregisterAccordion: (key) => {
        accordionsRef.current.delete(key);
      },
      isRestoringState: isNavigatingBack // Use the global navigation state
    }}>
      {children}
    </AccordionContext.Provider>
  );
};

/**
 * Individual accordion panel that handles its own animations and content rendering.
 * @param {Object} props
 * @param {string} props.eventKey - Unique identifier for the panel
 * @param {Function} props.renderHeader - Function to render custom header
 * @param {boolean} props.noCollapse - Whether panel can be collapsed
 * @param {React.ReactNode} props.children - Panel content
 */
const BasePanel = forwardRef(({
  eventKey = '1', // Unique identifier for the panel
  children,
  renderHeader, // Function to render the panel's header
  noCollapse = false, // If true, the panel cannot be collapsed
}, ref) => {
  // Access the accordion context
  const { activeKeys, handleToggle, registerAccordion, unregisterAccordion, isRestoringState } = useContext(AccordionContext);
  const contentRef = useRef(); // Reference to the panel's content DOM element
  const observerRef = useRef(null); // Reference to the ResizeObserver
  const headerRef = useRef(null);

  // Initialize isActive based on activeKeys only
  const [isActive, setIsActive] = useState(() => activeKeys.includes(eventKey));

  // Update isActive when activeKeys changes
  useEffect(() => {
    setIsActive(activeKeys.includes(eventKey));
  }, [activeKeys, eventKey]);

  // Custom function to handle panel toggling
  const handlePanelToggle = () => {
    if (noCollapse) return; // Prevent toggling if collapsing is disabled
    handleToggle(eventKey); // Update active keys in context
    setIsActive(!isActive); // Toggle local isActive state
  };

  // Initialize react-spring animation
  const [style, api] = useSpring(() => ({
    height: 0,
    opacity: 0,
    config: { 
      tension: 300, // Increase tension
      friction: 20,
      duration: 200 // Add a max duration
    }
  }));

  // Add this effect for initial render display state
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.display = isActive ? "block" : "none";
    }
  }, []); // Empty dependency array for first render only

  /**
   * Handles measurement and animation of panel content height.
   * Uses ResizeObserver to track content size changes and updates
   * animation accordingly.
   */
  useLayoutEffect(() => {
    if (!contentRef.current) return;

    const measureHeight = () => {
      const element = contentRef.current;
      if (!element) return 0;
      let totalHeight = element.getBoundingClientRect().height;
      Array.from(element.children).forEach((child) => {
        const style = window.getComputedStyle(child);
        totalHeight += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      });
      //console.log('📏 Measured height:', totalHeight, 'for panel:', eventKey);
      return totalHeight;
    };

    const updateHeight = () => {
      const height = measureHeight();
      //console.log('🎭 Animation update:', { 
      // isActive, 
      //  isRestoringState, 
      //  height, 
      //  eventKey,
      //  immediate: isRestoringState
     // });

      if (isActive) {
        api.start({ 

          overflow: 'hidden',


          height, 
          opacity: 1,
          immediate: isRestoringState,
          onRest: () => {
            //console.log('✨ Animation completed (open):', { eventKey, height, isRestoringState });
            if (contentRef.current) {
              contentRef.current.style.display = 'block';
              contentRef.current.style.pointerEvents = 'auto';
              api.start({ overflow: 'visible' });

            }
          }
        });
      } else {
        api.start({
          overflow: 'hidden',

          height: 0,
          opacity: 0,
          immediate: isRestoringState,
          onRest: () => {
            //console.log('✨ Animation completed (close):', { eventKey, isRestoringState });
            if (contentRef.current) {
              contentRef.current.style.display = 'none';
              contentRef.current.style.pointerEvents = 'none';
              api.start({ overflow: 'visible' });
            }
          }
        });
      }
    };

    // Log ResizeObserver setup
    //console.log('🔄 Setting up ResizeObserver:', { eventKey, isRestoringState });
    
    observerRef.current = new ResizeObserver(() => {
      //console.log('📐 ResizeObserver triggered for panel:', eventKey);
      window.requestAnimationFrame(updateHeight);
    });

    observerRef.current.observe(contentRef.current);
    Array.from(contentRef.current.getElementsByTagName('*')).forEach((element) => {
      observerRef.current.observe(element);
    });

    // Initial height update
    updateHeight();

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isActive, api, isRestoringState, eventKey]);

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    tryCollapse: () => {
      if (isActive && !noCollapse) {
        handlePanelToggle();
      }
    }
  }));

  // Register this panel with the accordion context
  useEffect(() => {
    const panelApi = {
      isActive,
      toggle: handlePanelToggle,
      getHeaderElement: () => headerRef.current
    };
    ////console.log('Registering panel:', eventKey);
    registerAccordion(eventKey, panelApi);
    return () => unregisterAccordion(eventKey);
  }, [eventKey, isActive]);
  //console.log("RENDERING ACCORDION");
  return (
    <>
      <div data-name="ref={headerRef}" ref={headerRef}>
        {renderHeader({
          isActive,
          onToggle: handlePanelToggle,
          noCollapse,
        })}
      </div>
      <animated.div data-name="animated.div" style={style}>
        <div data-name="ref={contentRef}"  ref={contentRef}>{children}</div>
      </animated.div>
    </>
  );
});

BaseAccordion.Panel = BasePanel;
export default BaseAccordion;

