import styled from '@emotion/styled';
import theme from '../../../styles/theme';

export const HStack = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const VStack = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

`;

export const ParameterGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 1.7rem 2.6rem 2.8rem 1.8rem;
    width: 40rem;
    //add horizontal gap
    column-gap: 1rem;

`;

export const ErrorMessage = styled.div`
    color: ${theme.colors.error};
    font-size: 0.8rem;
    margin-top: 0.25rem;
    min-height: 1rem;
`;

export const ErrorSummary = styled.div`
    margin-bottom: 1rem;
    padding: 1.4rem;
    border: 1px solid ${theme.colors.error};
    border-radius: 1rem;
    background-color: ${theme.colors.errorLight};
    width: 95%;
    h4 {
        color: ${theme.colors.error};
        margin-bottom: 0.5rem;
    }
    
    ul {
        margin: 0;
        padding-left: 1.5rem;
        color: ${theme.colors.error};
    }
`;