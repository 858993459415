import React, { useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import CustomIcons from './CustomIcons';
import theme from '../../styles/theme';
import { StandardButton } from './styled_comps/Buttons';
import {
    FilePanel,
    DropZone,
    FileInfo,
    FileName,
    UploadText,
    RemoveButton,
    FileContainer,
    UploaderContainer
} from './styled_comps/CustomFileUploaderStyledComps';

const CustomFileUploader = forwardRef(({ 
    onFileSelect,
    onRemove,
    acceptedTypes,
    disabled = false,
    showBtn = false,
    btnText = "Select file",
    ValidationModal = null,  // New prop for modal component
    onHideInFutureChanged  // Add this prop
}, ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const [file, setFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const fileInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        reset: () => {
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }));

    const triggerFileInput = () => {
        if (!disabled) {
            fileInputRef.current?.click();
        }
    };

    const handleModalProceed = () => {
        triggerFileInput();
    };

    const handleClick = () => {
        if (disabled) return;
        
        if (ValidationModal) {
            setShowModal(true);
        } else {
            triggerFileInput();
        }
    };

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    }, []);

    const handleDragOut = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (disabled) return;

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            handleFile(droppedFile);
            e.dataTransfer.clearData();
        }
    }, [disabled]);

    const handleFile = (selectedFile) => {
        setFile(selectedFile);
        if (onFileSelect) {
            onFileSelect([{
                name: selectedFile.name,
                blobFile: selectedFile,
                fileKey: Date.now()
            }]);
        }
    };

    const handleInputChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            handleFile(e.target.files[0]);
        }
    };

    const handleRemove = (e) => {
        e.stopPropagation();
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (onRemove) {
            onRemove();
        }
    };

    return (
        <>
            <UploaderContainer>
                <FilePanel>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleInputChange}
                        accept={acceptedTypes}
                    />
                    <DropZone
                        onClick={handleClick}
                        onDragEnter={handleDragIn}
                        onDragLeave={handleDragOut}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        isDragging={isDragging}
                        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                    >
                        {file ? (
                            <FileContainer>
                                <FileInfo>
                                    <CustomIcons.faFile 
                                        size="small" 
                                        color={theme.colors.backgroundDark}
                                    />
                                    <FileName>{file.name}</FileName>
                                </FileInfo>
                                {!disabled && (
                                    <RemoveButton
                                        onClick={handleRemove}
                                        type="button"
                                        title="Remove file"
                                    >
                                        <CustomIcons.faTimes 
                                            size="small"
                                            color={theme.colors.backgroundVeryDark}
                                            hoverColor={theme.colors.error}
                                        />
                                    </RemoveButton>
                                )}
                            </FileContainer>
                        ) : (
                            <UploadText>Click or drag a file to upload</UploadText>
                        )}
                    </DropZone>
                </FilePanel>
                {showBtn && (
                    <StandardButton 
                        type="button" 
                        onClick={handleClick}
                        disabled={disabled}
                    >
                        {btnText}
                    </StandardButton>
                )}
            </UploaderContainer>
            {ValidationModal && (
                <ValidationModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    onProceed={handleModalProceed}
                    onHideInFutureChanged={onHideInFutureChanged}  // Pass it through here
                />
            )}
        </>
    );
});

export default CustomFileUploader;
