import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Container } from 'rsuite';

function ProtectedRoute({ children, roles }) {
  const { isAuthenticated, user, isAdmin, isClient, isClientManager, isClientUser } = useAuth();

  const hasRole = (roles) => {
    if (isAdmin) return true;
    if (isClient && isClientManager) return roles.includes('clientManager');
    if (isClient && isClientUser) return roles.includes('clientUser');
    return false;
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else if (roles && !hasRole(roles)) {
    return (
      <Container className='m-20'>
        <p className="h1 text-danger">Access Denied</p>
        <p>You don't have permission to view this resource.</p>
        <p className="text-danger">Error Code: 403 forbidden</p>
      </Container>
    );
  } else {
    return children;
  }
}

export default ProtectedRoute;
