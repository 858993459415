import React from 'react';
import styled from '@emotion/styled';
import AIText from '../AIText';
import { css } from '@emotion/react';
import { 
    InfoMedium500Style, 
    InfoSmall500Style,
    HeaderXXLarge600Style,
    HeaderLarge600Style,
    HeaderMedium600Style,
} from '../../styles/TextStyles';
import theme from '../../../styles/theme';

// Base styles that can be shared across variants
const baseAITextStyle = css`
    /* Basic text elements */
    p {
        margin: 0.5rem 0;
        line-height: 1.5;
    }
    
    /* Lists */
    ul, ol {
        margin: 0.5rem 0;
        padding-left: 1.5rem;
    }

    li {
        margin: 0.25rem 0;
    }

    /* Headers */
    h1 {
        ${HeaderXXLarge600Style};
        margin: 1rem 0 0.5rem 0;
        color: ${theme.colors.standardText};
    }

    h2 {
        ${HeaderLarge600Style};
        margin: 0.8rem 0 0.4rem 0;
        color: ${theme.colors.standardText};
    }

    h3 {
        ${HeaderMedium600Style};
        margin: 0.6rem 0 0.3rem 0;
        color: ${theme.colors.standardText};
    }

    /* Emphasis */
    em {
        color: ${theme.colors.primary};
        font-style: italic;
    }

    strong {
        color: ${theme.colors.standardText};
        font-weight: 600;
    }

    /* Code blocks */
    code {
        background: ${theme.colors.surfaceSecondary};
        padding: 0.2rem 0.4rem;
        border-radius: 0.25rem;
        font-family: monospace;
    }

    /* Blockquotes */
    blockquote {
        border-left: 3px solid ${theme.colors.primary};
        margin: 0.5rem 0;
        padding-left: 1rem;
        color: ${theme.colors.fadedText};
    }

    /* Links */
    a {
        color: ${theme.colors.primary};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    /* Tables */
    table {
        border-collapse: collapse;
        margin: 0.5rem 0;
        width: 100%;
    }

    th, td {
        border: 1px solid ${theme.colors.border};
        padding: 0.5rem;
    }

    th {
        background: ${theme.colors.surfaceSecondary};
    }
`;

// Standard size AI text with medium weight
export const StandardAIText = styled(({ style, className, ...props }) => (
    <AIText 
        {...props} 
        style={style}
        className={className}
        css={css`
            ${InfoMedium500Style};
            ${baseAITextStyle};
            
            strong {
                color: ${theme.colors.standardText};
            }
            
            em {
                color: ${theme.colors.primary};
            }
        `}
    />
))``;

// Small description style AI text
export const SmallDescriptionAIText = styled(({ style, className, ...props }) => (
    <AIText 
        {...props}
        style={style}
        className={className}
        css={css`
            ${InfoSmall500Style};
            ${baseAITextStyle};
            color: ${theme.colors.fadedText};
            
            strong {
                color: ${theme.colors.standardText};
            }
            
            em {
                color: ${theme.colors.primary};
            }
            
            h1, h2, h3, h4, h5, h6 {
                font-size: inherit;
                margin: 0;
                color: ${theme.colors.standardText};
            }
        `}
    />
))``;
