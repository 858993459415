import React from "react";
import { Message } from "rsuite";
import Modal from "../../ui_reusable/Modal";
import FormClientUser from "./FormClientUser";

const EditClientUser = ({ user, clientId, setRefresh, open, onClose }) => {


    // Prepare initial form data if user exists
    const initialFormData = user ? {
        ...user.user,          // Spread the nested user object (contains name, email, phone)
        role: user.role       // Add the role from the parent object
    } : null;

    // Handle form submission
    const handleSubmit = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${clientId}/users/${user.user.id}/`, { 
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    user: {
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                    },
                    role: formData.role
                })
            });

            const responseData = await response.json();

            if (response.status !== 200) {
                throw new Error(responseData.message || 'Failed to update user');
            }

            await setRefresh(Date.now());
            return true;
        } catch (error) {
            throw error;
        }
    };

    // Handle success after form submission
    const handleSuccess = () => {
        setTimeout(() => {
            onClose();
        }, 100);
    };

    // Render loading state if user data is not available
    if (!user) {
        return (
            <Modal
                open={open}
                onClose={onClose}
                title="Edit User"
            >
                <Modal.Body>
                    Loading...
                </Modal.Body>
            </Modal>
        );
    }

    // Render the form with initial data
    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Edit User"
        >
            <Modal.Body>
                <FormClientUser 
                    onSubmit={handleSubmit}
                    initialData={initialFormData}   // Pass the prepared initial data
                    submitButtonText="Update User"
                    successMessage="User updated successfully!"
                    isEdit={true}
                    onSuccess={handleSuccess}
                />
            </Modal.Body>
        </Modal>
    );
};

export default EditClientUser;
