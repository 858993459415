import styled from '@emotion/styled';
import theme from '../../../styles/theme';

export const FilePanel = styled.div`
    width: 300px;
    border: 2px dashed ${theme.colors.backgroundDark};
    border-radius: 6px;
    transition: all 0.2s ease;
    background: white;

    &:hover {
        border-color: ${theme.colors.primary};
    }
`;

export const DropZone = styled.div`
    padding: 20px;
    text-align: center;
    cursor: pointer;
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.isDragging ? 'rgba(0, 0, 255, 0.05)' : 'transparent'};
    border: ${props => props.isDragging ? `2px solid ${theme.colors.primary}` : 'none'};
`;

export const FileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
`;

export const FileInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const FileName = styled.span`
    font-size: 14px;
    color: ${theme.colors.backgroundVeryDark};
`;

export const UploadText = styled.div`
    color: ${theme.colors.backgroundVeryDark};
    font-size: 14px;
`;

export const RemoveButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.8;
    }
`;

export const UploaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;
