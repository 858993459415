export const formats = [
    {
        key: 1,
        label: 'Standard',
        value: 'standard'
    },
    {
        key: 1,
        label: 'Semi-standard',
        value: 'semi-standard'
    },
    {
        key: 1,
        label: 'Non-standard',
        value: 'non-standard'
    },
    {
        key: 1,
        label: 'Unformatted',
        value: 'unformatted'
    }
];

export const intervalOptions = [
    {
        key: 1,
        label: 'Monthly (default)',
        value: 'monthly'
    },
    {
        key: 2,
        label: 'Daily',
        value: 'daily'
    },
    {
        key: 2,
        label: 'Weekly',
        value: 'weekly'
    }
];

export const unitOptions = [
    {
        key: 1,
        label: 'Value',
        value: 'value'
    },
    {
        key: 2,
        label: 'Percentage',
        value: 'percentage'
    },
    {
        key: 3,
        label: 'Currency',
        value: 'currency'
    }
]

export const roles_list = [
    {
        key: 1,
        label: 'User',
        value: 'user'
    },
    {
        key: 2,
        label: 'Manager',
        value: 'manager'
    }
]

export const aggregationMethodOptions = [
    {
        key:1, value:'mean', label:'Mean (default)'
    },
    {
        key:2, value:'median', label:'Median'
    }
]

export const influenceOptions = [
    {
        key:1, value:'high', label:'High'
    },
    {
        key:2, value:'medium', label:'Medium'
    },
    {
        key:3, value:'none', label:'None'
    }
]

export const impactOptions = [
    {
        key:1, value:'high', label:'High'
    },
    {
        key:2, value:'medium', label:'Medium'
    },
    {
        key:3, value:'low', label:'Low'
    },
    {
        key:4, value:'manual', label:'Manual'
    }
]