import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { InfoXXXSmall600Style ,InfoMedium700Style} from '../../styles/TextStyles';
import styled from '@emotion/styled';

export const InfoDisplayerTitleStyle = css`
    ${InfoXXXSmall600Style};
    color: ${theme.colors.veryFadedText};

`


export const InfoDisplayerValueStyle = css`
    ${InfoMedium700Style};
    color: ${theme.colors.veryVeryVeryFadedText};

`
export const ExpandedRowStyle = css`
    padding: 1.5rem;


`;

/**
 * DrawerInfo - A container component for displaying information in drawer-like format
 * Uses theme background color and provides flexible layout for child elements
 * Children will be arranged in a centered, wrapping flow layout
 */
export const DrawerInfoStyle = css`
  /* Use theme background color and add border radius */
  background-color: ${theme.colors.backgroundLight};
  border-radius: 1rem;

  /* Make container expand to available space while fitting content */
  width: 100%;
  height: fit-content;

  /* Center content and allow wrapping */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  /* Add some padding for inner content */
  padding-top: 0rem;
  padding-bottom: 0rem;
`;

/**
 * DrawerInfoDisplayer - A fixed-width container with outlined sides
 * Used for displaying specific information in a drawer-like format
 * Width is set to 10rem and adjusts height to content
 */
export const DrawerInfoDisplayerStyle = css`
  /* Set fixed width and auto height */
  width: 8.3rem;
  height: fit-content;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;  


`;

export const getBorderStyle = (isLeft, isRight) => {
  return css`
    position: relative; // Make parent relative for absolute positioning

    ${isLeft && `
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: ${theme.colors.backgroundDark};
        pointer-events: none; // Ensure it doesn't interfere with interactions
      }
    `}
    
    ${isRight && `
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: ${theme.colors.backgroundDark};
        pointer-events: none; // Ensure it doesn't interfere with interactions
      }
    `}
  `;
};
