import styled from '@emotion/styled';
import theme from '../../../../styles/theme';
import { InfoMedium600Style, InfoMedium500Style } from '../../../styles/TextStyles';

export const FAQContainer = styled.div`
    margin: 20px 0;
`;

export const MainButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 0.5rem;

`

export const FAQPanel = styled.div`
    margin-bottom: 10px;
    border: 1px solid ${theme.colors.border};
    border-radius: 0.5rem;
    overflow: hidden;
`;

export const FAQHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: ${theme.colors.surface};
    ${InfoMedium600Style};
`;

export const FAQContent = styled.div`
    padding: 1rem;
    border-top: 1px solid ${theme.colors.border};
`;

export const ActionButtons = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const FormGroup = styled.div`
    margin-bottom: 1.5rem;
`;

export const FormLabel = styled.div`
    ${InfoMedium500Style};
    color: ${theme.colors.standardText};
    margin-bottom: 0.5rem;
`;

export const ModalText = styled.div`
    ${InfoMedium500Style};
    margin-bottom: 1rem;
`;

export const ModalHighlight = styled.span`
    ${InfoMedium600Style};
`;
