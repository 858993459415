import React, { useRef, useEffect, useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DrawerInfoStyle, DrawerInfoDisplayerStyle, getBorderStyle, ExpandedRowStyle,InfoDisplayerTitleStyle,InfoDisplayerValueStyle } from './styles/DawerInfoDisplayerStyles';
import theme from '../../styles/theme';
export const ExpandedRow = styled.div`
  ${ExpandedRowStyle};
`;

export const InfoDisplayerTitle = styled.span`
  ${InfoDisplayerTitleStyle};
  margin-bottom: 0.2rem;
`;

export const infoDisplayerValueStyle = css`
  ${InfoDisplayerValueStyle};

`;

const StyledDrawerInfoDisplayer = styled.div`
  ${DrawerInfoDisplayerStyle};
  ${props => getBorderStyle(props.isLeft, props.isRight)};
`;

export const DrawerInfoDisplayer = forwardRef(({ isLeft = true, isRight = true, ...props }, ref) => (
  <StyledDrawerInfoDisplayer ref={ref} isLeft={isLeft} isRight={isRight} {...props} />
));

DrawerInfoDisplayer.displayName = 'DrawerInfoDisplayer';

const StyledDrawerInfo = styled.div`
  ${DrawerInfoStyle};
`;

export const DrawerInfo = ({ children, style, ...props }) => {
  const containerRef = useRef(null);
  const [childPositions, setChildPositions] = useState([]);
  const childRefs = useRef([]);

  useEffect(() => {
    // Reset refs array when children change
    const childCount = React.Children.count(children);
    childRefs.current = Array(childCount).fill(null);
  }, [children]);

  useEffect(() => {
    const updateChildPositions = () => {
      if (!containerRef.current) return;

      // Clean up any stale refs
      childRefs.current = childRefs.current.slice(0, React.Children.count(children));

      // Get positions of all children
      const positions = childRefs.current.map((ref, index) => {
        if (!ref || !ref.getBoundingClientRect) return null;
        
        const rect = ref.getBoundingClientRect();
        return {
          index,
          x: rect.left,
          y: rect.top,
          width: rect.width,
          right: rect.right
        };
      }).filter(pos => pos !== null);

      // Group by Y position (within 1px tolerance for float point differences)
      const rowGroups = positions.reduce((groups, pos) => {
        const row = Math.round(pos.y); // Round to handle minor float differences
        if (!groups[row]) groups[row] = [];
        groups[row].push(pos);
        return groups;
      }, {});


      // For each row, determine leftmost and rightmost elements
      const newPositions = positions.map(pos => ({
        isLeft: true,
        isRight: true
      }));

      Object.entries(rowGroups).forEach(([y, row]) => {

        // Sort by X position
        row.sort((a, b) => a.x - b.x);
        
        // Update border flags for this row
        row.forEach((pos, idx) => {
          const isFirstInRow = idx === 0;
          const isLastInRow = idx === row.length - 1;
          
          newPositions[pos.index] = {
            isLeft: !isFirstInRow,
            isRight: !isLastInRow
          };


        });
      });

      setChildPositions(newPositions);
    };

    const observer = new ResizeObserver(updateChildPositions);
    if (containerRef.current) {
      observer.observe(containerRef.current);
      // Initial update after a short delay to ensure refs are set
      setTimeout(updateChildPositions, 0);
    }

    return () => {
      observer.disconnect();
    };
  }, [children]);

  return (
    <StyledDrawerInfo ref={containerRef} style={style} {...props}>
      {React.Children.map(children, (child, index) => {
        if (!child) return null;

        const borders = {
          isLeft: childPositions[index]?.isLeft ?? true,
          isRight: childPositions[index]?.isRight ?? true
        };

        return React.cloneElement(child, {
          ref: (el) => {
            childRefs.current[index] = el;
          },
          ...borders,
          key: index // Ensure each child has a stable key
        });
      })}
    </StyledDrawerInfo>
  );
};
