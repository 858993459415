import React, { useState, useRef, useEffect } from "react";
import Cookies from 'js-cookie';
import { useForm } from "react-hook-form";
import { IconButton } from "rsuite";
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import useStandardToaster from '../../ui_reusable/StandardToaster';
import FileValidationModal from "../../datasets/FileValidationModal";
import { FormSelectPicker } from "../../ui_reusable/StandardSelectPickers";
import { aggregationMethodOptions, influenceOptions, intervalOptions, impactOptions, unitOptions } from "../../../utils/dropdowns";
import { Container, FormGroup, Label, LabelWithoutMarginNoWrap,ButtonContainer, ErrorMessage, SuccessContainer, ServerErrorMessage } from '../../ui_reusable/styled_comps/CreateInputUtils';
import { VStack, HStack ,DescriptionContainer,VStackBroad,HStackRadioComplex,TopContainer,LeftChildOfTopContainer,RightChildOfTopContainer} from './styled_sub_comps/FormStyledSubComps';
import { StandardButton } from '../../ui_reusable/styled_comps/Buttons';
import { StandardInput } from '../../ui_reusable/StandardInputs';
import CustomFileUploader from '../../ui_reusable/CustomFileUploader';
import CheckmarkAnimation from '../../ui_reusable/CheckmarkAnimation';
import UniversalLoader from '../../ui_reusable/UniversalLoader';
import StandardSearchField from '../../ui_reusable/StandardSearchField';
import { StandardRadio, RadioProvider } from '../../ui_reusable/StandardRadioButtons';
import { useAuth } from '../../../context/AuthContext';  // Fix the path from contexts to context
const COOKIE_NAME = 'hideFileValidation';

const Form = ({ 
    initialData,                // Data for edit mode
    onSubmit,                  // Custom submit handler
    submitButtonText = "SAVE DATASET",  // Button text
    successMessage = "Dataset saved successfully!", // Success message
    isEdit = false,             // Mode flag
    onSuccess,    // Add this new prop
    hasFileUploader = true,  // Add this prop with default true
    resetOnSuccess = false  // Add this prop with default false
}) => {
    const [uploading, setUploading] = useState(false);
    const [fileInfo, setFileInfo] = useState();
    const [open, setOpen] = useState(false);
    const [read, setRead] = useState(false);
    const [pendingUpload, setPendingUpload] = useState(null);
    const customFileUploaderRef = useRef(null);
    const { pushToast } = useStandardToaster();
    const { register, handleSubmit, setValue, watch, reset: formReset, formState: { errors }, setError, clearErrors, unregister } = useForm({
        defaultValues: {
            file: "",
            name: "",
            source: "",
            description: "",
            interval: "monthly",
            aggregation: "mean",
            units: "",
            influence: "",
            impact: "manual",
            is_contextual: true,
            client_id: ""
        }
    });
    const [showSuccess, setShowSuccess] = useState(false);
    const [removeContainer, setRemoveContainer] = useState(true);
    const [serverError, setServerError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [hideValidationInfo, setHideValidationInfo] = useState(() => {

        return Cookies.get(COOKIE_NAME) === 'true';
    });
    const [clients, setClients] = useState([]);
    const [selectedSearchItems, setSelectedSearchItems] = useState([]);
    const [driverType, setDriverType] = useState('contextual');
    const { user, isAdmin, isClientManager } = useAuth();

    // Add state to store original values
    const [originalValues, setOriginalValues] = useState(null);

    useEffect(()=>{
        if(isClientManager){
            setDriverType('client');
            setValue("category", 'client');
        }
    },[isClientManager]);
    
    useEffect(() => {
        const fetchClients = async () => {
            if (isAdmin) {
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/clients/`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                            },
                        }
                    );
                    if (response.ok) {
                        const data = await response.json();
                        // Extract only id and name from clients
                        const clientList = data.data.map(client => ({
                            id: client.id,
                            name: client.name
                        }));
                        setClients(clientList);
                    }
                } catch (error) {
                    console.error('Error fetching clients:', error);
                }
            }
        };

        fetchClients();
    }, [isAdmin]);

    const handleSearchChange = (items) => {
        setSelectedSearchItems(items);
        if (items && items.length > 0) {
            clearErrors("client");
            // Update form data with selected client
            // Note: This won't affect the payload currently being sent to the server
            setValue("client_id", items[0].id);
        }

    };

    useEffect(() => {

    }, [fileInfo]);

    
    const handleHideInfoChanged = (hide) => {

        if (hide) {
            Cookies.set(COOKIE_NAME, 'true', { expires: 7 });
        } else {
            Cookies.remove(COOKIE_NAME);
        }
        setHideValidationInfo(hide);
    };

    const handleFileChange = (files) => {
        if (!files || files.length === 0) {
            setFileInfo(null);
            return;
        }
        setUploading(true);
        setFileInfo(files[0]);
        clearErrors("file");  // Clear the file error when a file is selected
        setUploading(false);
    };

    const handleRemoveFile = () => {

        setFileInfo(null);
        // Only set error if the field has been touched
        if (fileInfo) {
            setError("file", { type: "manual", message: "File is required" });
        }
    };


    const resetForm = () => {
        // Reset form fields
        formReset({
            file: "",
            name: "",
            source: "",
            description: "",
            interval: "monthly",
            aggregation: "mean",
            units: "",
            influence: "",
            impact: "manual",
            is_contextual: true,
            client_id: ""
        });

        // Reset file upload states
        setFileInfo(null);
        setUploading(false);
        setPendingUpload(null);
        setOpen(false);
        setRead(false);

        // Reset driver type and search field
        setDriverType('contextual');
        setSelectedSearchItems([]);

        // Reset CustomFileUploader
        if (customFileUploaderRef.current) {
            customFileUploaderRef.current.reset();
        }
    };

    const validateForm = (data) => {
        let isValid = true;
        
        // Only validate file if file uploader is enabled
        if (hasFileUploader && !fileInfo) {
            setError("file", { type: "manual", message: "File is required" });
            isValid = false;
        }
        
        if (!data.units) {
            setError("units", { type: "manual", message: "Units are required" });
            isValid = false;
        }

        if(!data.influence){
            setError("influence", { type: "manual", message: "Need to select influence" });
            isValid = false;
        }
        if(!data.impact){
            setError("impact", { type: "manual", message: "Need to select impact" });
            isValid = false;
        }

        // Add client validation only if we're in client mode
        if (!data.is_contextual && (!selectedSearchItems || selectedSearchItems.length === 0)) {
            setError("client", { type: "manual", message: "Need to select client" });
            isValid = false;
        }
        
        return isValid;
    };

    // Initialize form with initial data if provided
    useEffect(() => {
        if (initialData && isEdit) {
            const initialFormValues = {
                name: initialData.name || "",
                source: initialData.source || "",
                description: initialData.description || "",
                interval: initialData.interval || "monthly",
                aggregation: initialData.aggregation || "mean",
                units: initialData.units || "",
                influence: initialData.influence || "",
                impact: initialData.impact || "manual",
                is_contextual: initialData.is_contextual || true,
                client_id: initialData.client?.id || ""
            };
            
            // Store original values
            setOriginalValues(initialFormValues);
            
            // Set form values
            formReset(initialFormValues);
            
            // If there's an existing file, set it
            if (initialData.filename) {
                setFileInfo({
                    name: initialData.filename,
                    existingFile: true
                });
            }

            // Set driver type from category
            if (initialData.is_contextual) {
                setDriverType(initialData.is_contextual ? 'contextual' : 'client');
            }

            // Set client data if it's a client driver and client data exists
            if (!initialData.is_contextual && initialData.client) {
                setSelectedSearchItems([{ 
                    id: initialData.client.id, 
                    name: initialData.client.name 
                }]);
                setValue("client_id", initialData.client.id);
            }
        }
    }, [initialData, isEdit, formReset, setValue]);

    // Add function to get changed values
    const getChangedValues = (formData) => {
        if (!isEdit || !originalValues) return formData;
    
        const changes = {};
        Object.keys(formData).forEach(key => {
 
            // Only include if value has changed
            if (formData[key] !== originalValues[key]) {
                changes[key] = formData[key];
            }
        });
    
        // Always include category in edit mode to ensure proper handling
        if (isEdit && formData.category !== originalValues.category) {
            changes.category = formData.category;
        }
    
        return changes;
    };

    // Modify onFormSubmit
    const onFormSubmit = async (formData) => {
        setServerError(null);
        
        if (!validateForm(formData)) {
            return;
        }
        
        // Get only changed values for PATCH
        const payload = isEdit ? getChangedValues(formData) : formData;
        
        
        setIsSubmitting(true);
        
        try {
            const success = await onSubmit(payload, fileInfo);
            
            if (success) {
                setShowSuccess(true);
                if (resetOnSuccess) {  // Only reset if resetOnSuccess is true
                    resetForm();
                }
                
                // Handle success animation and cleanup
                setTimeout(() => {
                    setShowSuccess(false);
                    if (onSuccess) {
                        onSuccess(); // Call the success callback
                    }
                }, 2000); // Show success message for 2 seconds

                // Additional cleanup after panel collapse animation
                setTimeout(() => {
                    setRemoveContainer(true);
                }, 2300); // Wait for both success message and collapse animation
            }
        } catch (error) {
            setServerError(error.message || 'An unexpected error occurred');
            // Removed the auto-clearing of error message
        } finally {
            setIsSubmitting(false);
        }
    };

    const acceptedFileTypes = ".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const handleInfoClick = (e) => {
        e.preventDefault();
        setShowInfoModal(true);
    };

    const handleDriverTypeChange = (type) => {
        setDriverType(type);
        if (type === 'contextual') {
            setValue("is_contextual", true);
            setValue("client_id", "");
            // Clear any client-related errors when switching to contextual
            clearErrors("client");
            //setValue("client_id", "");
            setSelectedSearchItems([]); // Clear the search field
        }
        if (type === 'client') {
            setValue("is_contextual", false);
        }
    };

    return (
        <Container>
            <UniversalLoader show={isSubmitting} content={isEdit ? "Updating dataset..." : "Creating dataset..."} />
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <FormGroup>
                    <VStackBroad>
                        <HStack>
                            <TopContainer>
                                {hasFileUploader ? (
                                    <LeftChildOfTopContainer>
                                        <Label>File</Label>
                                        <HStack >
                                            <CustomFileUploader
                                                ref={customFileUploaderRef}
                                                fileInfo={fileInfo}
                                                onFileSelect={handleFileChange}
                                                onRemove={handleRemoveFile}
                                                uploading={uploading}
                                                acceptedTypes={acceptedFileTypes}
                                                showBtn={true}
                                                ValidationModal={hideValidationInfo ? null : FileValidationModal}
                                                onHideInFutureChanged={handleHideInfoChanged}
                                            />
                                            <IconButton 
                                                type="button" 
                                                onClick={handleInfoClick} 
                                                icon={<InfoOutlineIcon />} 
                                            />
                                        </HStack>
                                        <ErrorMessage>{errors.file?.message}</ErrorMessage>
                                    </LeftChildOfTopContainer>
                                ) : null}
                                <RightChildOfTopContainer>
                                    {(isAdmin || user?.role === 'owner') && (
                                            <RadioProvider group="driverType" initValue={driverType} onChange={(value) => handleDriverTypeChange(value)}>
                                                <HStackRadioComplex>
                                                    <StandardRadio
                                                        group="driverType"
                                                        value="contextual"
                                                    />
                                                    <LabelWithoutMarginNoWrap>Contextual driver</LabelWithoutMarginNoWrap>
                                                </HStackRadioComplex>
                                                <HStackRadioComplex>
                                                    <StandardRadio
                                                        group="driverType"
                                                        value="client"
                                                    />
                                                    <LabelWithoutMarginNoWrap>Client driver</LabelWithoutMarginNoWrap>
                                                </HStackRadioComplex>
                                            </RadioProvider>
                                        )}
                                        
                                        {(isAdmin || user?.role === 'owner') && driverType === 'client' && (
                                            <StandardSearchField 
                                                data={clients}
                                                onChange={handleSearchChange}
                                                placeholder="Search clients..."
                                                maxSelected={1}
                                                alwaysShowList={true}
                                                initValueList={selectedSearchItems}  // Add this line
                                            />
                                    )}
                                    <div /> {/* Empty div for first column second row */}
                                    <div /> {/* Empty div for second column second row */}
                                    {driverType === 'client' && errors.client && (
                                        <ErrorMessage>{errors.client.message}</ErrorMessage>
                                    )}
    
                                </RightChildOfTopContainer>
                            </TopContainer>
                        </HStack>
                        <DescriptionContainer>
                            <VStack style={{flexGrow: 0.5}}>
                                <Label>Dataset name</Label>
                                <StandardInput 
                                    {...register("name", { required: "Dataset name is required" })} 
                                />
                                <ErrorMessage>{errors.name?.message}</ErrorMessage>
                            </VStack>
                            <VStack style={{flexGrow: 0.5}}>
                                <Label>Source</Label>
                                <StandardInput 
                                    {...register("source", { required: "Source is required" })} 
                                    
                                />
                                <ErrorMessage>{errors.source?.message}</ErrorMessage>
                            </VStack>
                            <VStack style={{flexGrow: 2}}>
                                <Label>Description</Label>
                                <StandardInput 
                                    {...register("description")} 
                                />
                                <ErrorMessage>{errors.description?.message}</ErrorMessage>
                            </VStack>
                        </DescriptionContainer>
                        <HStack>
                            <VStack>
                                <Label>Interval</Label>
                                <FormSelectPicker 
                                    data={intervalOptions} 
                                    value={watch("interval")} 
                                    onChange={value => setValue("interval", value)} 
                                    style={{ width: '12rem' }}
                                />
                                <ErrorMessage>{errors.interval?.message}</ErrorMessage>
                            </VStack>
                            {watch("interval") && watch("interval") !== 'monthly' && (
                                <VStack>
                                    <Label>Aggregation methods</Label>
                                    <FormSelectPicker 
                                        data={aggregationMethodOptions} 
                                        value={watch("aggregation")} 
                                        onChange={value => setValue("aggregation", value)} 
                                        style={{ width: '12rem' }}
                                    />
                                    <ErrorMessage>{errors.aggregation?.message}</ErrorMessage>
                                </VStack>
                            )}
                            <VStack>
                                <Label>Units</Label>
                                <FormSelectPicker 
                                    data={unitOptions} 
                                    value={watch("units")} 
                                    onChange={value => {
                                        setValue("units", value);
                                        if (value) {
                                            clearErrors("units");
                                        }
                                    }}
                                    style={{ width: '12rem' }}
    
                                />
                                <ErrorMessage>{errors.units?.message}</ErrorMessage>
                            </VStack>
                            <VStack>
                                <Label>Influence</Label>
                                <FormSelectPicker 
                                    data={influenceOptions} 
                                    value={watch("influence")} 
                                    onChange={value => {
                                        setValue("influence", value);
                                        if (value) {
                                            clearErrors("influence");
                                        }
                                    }} 
                                    style={{ width: '12rem' }}
                                />
                                <ErrorMessage>{errors.influence?.message}</ErrorMessage>
                            </VStack>
                            <VStack>
                                <Label>Impact</Label>
                                <FormSelectPicker 
                                    defaultValue={(driverType === 'contextual')?'manual':''}
                                    data={impactOptions} 
                                    value={watch("impact")} 
                                    onChange={value => {
                                        setValue("impact", value);
                                        if (value) {
                                            clearErrors("impact");
                                        }
                                    }} 
                                    style={{ width: '12rem' }}
                                />
                                <ErrorMessage>{errors.impact?.message}</ErrorMessage>
                            </VStack>
                        </HStack>
                    </VStackBroad>
                    <ButtonContainer>
                        <StandardButton type="submit">{submitButtonText}</StandardButton>
                    </ButtonContainer>
                </FormGroup>
            </form>
            {(serverError || showSuccess) && (
                <SuccessContainer>
                    {serverError ? (
                        <ServerErrorMessage>
                            {serverError}
                        </ServerErrorMessage>
                    ) : (
                        <CheckmarkAnimation 
                            show={showSuccess} 
                            text={successMessage}
                        />
                    )}
                </SuccessContainer>
            )}
            
            <FileValidationModal 
                open={showInfoModal}
                handleClose={() => setShowInfoModal(false)}
                mode="info"
            />
        </Container>
    );
};

export default Form;

