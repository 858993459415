import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { HeaderMediumMaxi600Style,InfoMediumMini500Style,InfoMedium500Style } from '../../../styles/TextStyles';
import theme from '../../../../styles/theme';


export const HStack = styled.div`
    padding-top: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.2rem;
`;

export const HStackSecondRow = styled.div`
    padding-top: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 3rem;
`;
