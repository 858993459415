import { TagSuccess, TagWarning, TagDanger } from '../components/ui_reusable/styled_comps/Tags';

export const SCORE_THRESHOLDS = {
    GOOD: 80,
    MODERATE: 50
};

export const QUALITY_MAPPING = {
    good: { tag: <TagSuccess label="good" />, label: "good" },
    check: { tag: <TagWarning label="check" />, label: "warning" },
    poor: { tag: <TagDanger label="Fix" />, label: "bad" }
};

// For components that need the tag component
export const getFitnessTag = (score, quality) => {
    // If quality is provided, use quality-based logic
    if (quality) {
        return QUALITY_MAPPING[quality]?.tag || <TagDanger label="unknown" />;
    }
    
    // If no quality, use score-based logic
    if (score >= SCORE_THRESHOLDS.GOOD) return <TagSuccess label="good" />;
    if (score >= SCORE_THRESHOLDS.MODERATE) return <TagWarning label="moderate" />;
    return <TagDanger label="poor" />;
};

// For components that need just the quality string
export const getQualityString = (score, quality) => {
    // If quality is provided, use quality-based logic
    if (quality) {
        return QUALITY_MAPPING[quality]?.label || "bad";
    }
    
    // If no quality, use score-based logic
    if (score >= SCORE_THRESHOLDS.GOOD) return "good";
    if (score >= SCORE_THRESHOLDS.MODERATE) return "warning";
    return "bad";
};
