import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { scrollbarStyle } from '../../../styles/ScrollbarStyles';
import { standardInputStyle } from '../styles/StandardInputStyles';
export const SearchContainer = styled.div`
    position: relative;
    width: 100%;
    font-family: Inter, sans-serif;
`;

export const InputWrapper = styled.div`
    ${standardInputStyle({ highlightError: false })}
    display: flex;
    align-items: center;
    min-height: 38px;
    padding: 0.5rem;
    background: transparent;
    width: 100%;
`;

export const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.4rem;
    gap: 6px;
    flex: 1;
`;

export const SearchInput = styled.input`
    border: none;
    outline: none;
    flex-grow: 1;
    min-width: 120px;
    font-size: 14px;
    color: #344054;
    background: transparent;

    &::placeholder {
        color: #667085;
    }
`;

export const SearchDropdown = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid ${theme.colors.backgroundDark};
    border-radius: 0.5rem;
    box-shadow: ${theme.shadows.light};
    max-height: 200px;
    overflow-y: auto;
    ${scrollbarStyle}
    z-index: 1000;
`;

export const DropdownItem = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    color: #344054;
    font-size: 14px;
    padding-left: 2rem;
    &:hover {
        background-color: #F9FAFB;
    }
`;

export const Tag = styled.span`
    display: inline-flex;
    align-items: center;
    background: ${theme.colors.primaryVeryVeryLight};
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 14px;
    color: ${theme.colors.standardText};
    gap: 4px;
`;

export const TagCloseButton = styled.button`
    background: none;
    border: none;
    color: #667085;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    &:hover {
        color: #344054;
    }
`;
