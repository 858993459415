import React, { useState, useEffect } from 'react';
import SimpleAccordion from "../../../ui_reusable/SimpleAccordion";
import { PanelSpecialIndent } from "../../../ui_reusable/styled_comps/PlacementMisc";
import OneLineBtnHStack from "../../../ui_reusable/OneLineBtnHStack";
import { StandardRadio, RadioProvider } from '../../../ui_reusable/StandardRadioButtons';
import { FormSelectPicker } from '../../../ui_reusable/StandardSelectPickers';
import { StandardCheckbox } from '../../../ui_reusable/StandardInputs';
import { HStack, VStack, LinkVStack, ListHeader, ListItemInfo, LinkBtn } from '../styled_sub_comps/SetupStyledSubComps';
import { useScroll } from '../../../../context/ScrollContext';

const Setup = ({ pageConfig, templates, onPanelSelection, initPanelTemplate, accordionContextId, reportData }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(initPanelTemplate);
    const [selectedRadioButton, setSelectedRadioButton] = useState(initPanelTemplate);
    const { scrollTo, scrollToPanel } = useScroll();  // Add scrollToPanel

    const standardTemplates = templates.filter(t => ['All', 'Executive'].includes(t.name));
    const customTemplates = templates.filter(t => !['All', 'Executive'].includes(t.name))
        .map(t => ({ label: t.name, value: t.name }));

    useEffect(() => {
        const template = templates.find(t => t.name === selectedTemplate);
        if (template) {
            onPanelSelection(template.panels);
        }
    }, []);

    useEffect(() => {
        const template = templates.find(t => t.name === initPanelTemplate);
        if (template) {
            onPanelSelection(template.panels);
        }
    }, [initPanelTemplate]); // Run only once on mount

    const handlePageClick = (panelConfig) => {
        if (!panelConfig) return;

        if (panelConfig.ref) {
            // Use scrollToPanel when we just want to open the panel
            scrollToPanel(
                accordionContextId,
                panelConfig.id
            );
        }
    };

    const handleRadioSelection = (value) => {
        setSelectedRadioButton(value);
        if (value !== 'Custom') {
            // Only set template if it's not "Custom"
            setSelectedTemplate(value);
            const template = templates.find(t => t.name === value);
            if (template) {
                onPanelSelection(template.panels);
            }
        }
        // console.log("selectedRadioButton", value);
    };

    const handleCustomTemplateSelection = (value) => {
        if (value) {
            setSelectedTemplate(value);
            const template = customTemplates.find(t => t.name === value);
            if (template) {
                onPanelSelection(template.panels);
            }
        }
    };

    // Get current template's panels
    const getCurrentTemplatePanels = () => {
        const template = templates.find(t => t.name === selectedTemplate);
        return template ? template.panels : [];
    };

    // Map panel ID to its configuration (including title and refs)
    const getPanelConfig = (panelId) => {
        return pageConfig.find(config => config.id === panelId);
    };

    return (
        <SimpleAccordion id={"setupModelPageAccordion"}  defaultActiveKey={['1']}>
            <SimpleAccordion.Panel header="Set up report" noCollapse>
                <PanelSpecialIndent>
                    <HStack>
                        <VStack>
                            <ListHeader>Report level</ListHeader>
                            <RadioProvider 
                                initValue={selectedRadioButton}  // Changed from value to initValue
                                onChange={handleRadioSelection}
                                group="reportLevel"  // Explicit group name
                            >
                                {standardTemplates.map((template) => (
                                    <OneLineBtnHStack key={template.name}>
                                        <StandardRadio 
                                            size='s' 
                                            group="reportLevel" 
                                            value={template.name}
                                        />
                                        <ListItemInfo>{template.name}</ListItemInfo>
                                    </OneLineBtnHStack>
                                ))}
                                <OneLineBtnHStack style={{width: '8rem'}}>
                                    <StandardRadio 
                                        size='s' 
                                        group="reportLevel" 
                                        value="Custom"
                                    />
                                    <ListItemInfo>Custom</ListItemInfo>
                                </OneLineBtnHStack>
                            </RadioProvider>
                            {
                                selectedRadioButton === 'Custom' && customTemplates.length > 0 && <FormSelectPicker 
                                    disabled={selectedRadioButton !== 'Custom'}
                                    searchable={false} 
                                    cleanable={false} 
                                    value={selectedRadioButton === 'Custom' ? selectedTemplate : undefined}
                                    onChange={handleCustomTemplateSelection}
                                    data={customTemplates} 
                                    style={{ width: '100%' }}
                                />
                            }
                        </VStack>
                        <VStack>
                            <ListHeader>Include Plus</ListHeader>
                            <OneLineBtnHStack disabled={!reportData?.features?.ai}>
                                <StandardCheckbox 
                                    size='small' 
                                    value="AI+"
                                    checked={(reportData?.include_plus==='ai+') ? true : false}
                                />
                                <ListItemInfo>AI+</ListItemInfo>
                            </OneLineBtnHStack>
                            <OneLineBtnHStack disabled={!reportData?.features?.kpi}>
                                <StandardCheckbox 
                                    size='small' 
                                    value="KPI+" 
                                    checked={(reportData?.include_plus==='kpi+') ? true : false}
                                />
                                <ListItemInfo>KPI+</ListItemInfo>
                            </OneLineBtnHStack>
                            <OneLineBtnHStack disabled={!reportData?.features?.segment}>
                                <StandardCheckbox 
                                    size='small' 
                                    value="Segment+" 
                                    checked={(reportData?.include_plus==='segment+') ? true : false}
                                />
                                <ListItemInfo>Segment+</ListItemInfo>
                            </OneLineBtnHStack>
                            <OneLineBtnHStack disabled={!reportData?.features?.geo}>
                                <StandardCheckbox 
                                    size='small' 
                                    value="Geo+" 
                                    checked={(reportData?.include_plus==='geo+') ? true : false}
                                />
                                <ListItemInfo>Geo+</ListItemInfo>
                            </OneLineBtnHStack>
                        </VStack>
                        <VStack>
                            <ListHeader>Confidence level</ListHeader>
                            <RadioProvider initValue={reportData?.confidence} group="confidenceLevel">
                                <OneLineBtnHStack>
                                    <StandardRadio size='s' group="confidenceLevel" value="high" />
                                    <ListItemInfo>High</ListItemInfo>
                                </OneLineBtnHStack>
                                <OneLineBtnHStack>
                                    <StandardRadio size='s' group="confidenceLevel" value="normal" />
                                    <ListItemInfo>Normal</ListItemInfo>
                                </OneLineBtnHStack>
                                <OneLineBtnHStack>
                                    <StandardRadio size='s' group="confidenceLevel" value="lowest" />
                                    <ListItemInfo>Lowest allowed</ListItemInfo>
                                </OneLineBtnHStack>
                            </RadioProvider>
                        </VStack>
                        <VStack>
                            <ListHeader>Report contains</ListHeader>
                            <LinkVStack>
                                {getCurrentTemplatePanels().map(panelId => {
                                    const panelConfig = getPanelConfig(panelId);
                                    return panelConfig && (
                                        <LinkBtn 
                                            key={panelId}
                                            onClick={() => handlePageClick(panelConfig)}
                                        >
                                            {panelConfig.title}
                                        </LinkBtn>
                                    );
                                })}
                            </LinkVStack>
                        </VStack>
                    </HStack>
                </PanelSpecialIndent>
            </SimpleAccordion.Panel>
        </SimpleAccordion>
    );
};

export default Setup;
