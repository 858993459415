import React, { useEffect, useState } from "react";
import { Message } from "rsuite"; // Add Message import back
import useStandardToaster from '../../ui_reusable/StandardToaster';
import SimpleAccordion from '../../ui_reusable/SimpleAccordion';
import Modal from '../../ui_reusable/Modal';
import { StandardButton } from '../../ui_reusable/styled_comps/Buttons';
import { StandardInput, StandardDescription } from '../../ui_reusable/StandardInputs';
import { EasyIconButton } from '../../ui_reusable/styled_comps/Buttons';
import CustomIcons from '../../ui_reusable/CustomIcons';
import theme from '../../../styles/theme';  // Add theme import
import FAQAccordion from '../ui_reusable/FAQAccordion';

import {
    FAQContainer,
    FAQPanel,
    FAQHeader,
    FAQContent,
    ActionButtons,
    FormGroup,
    MainButtonContainer,
    FormLabel,
    ModalText,
    ModalHighlight
} from './styled_sub_comps/ManageFAQStyledSubComps';

const ManageFAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [newFaq, setNewFaq] = useState({ id: 0, question: '', answer: '' });
    const [editedFaq, setEditedFaq] = useState({ id: 0, question: '', answer: '' });
    const [selectedFaq, setSelectedFaq] = useState(null);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { pushToast } = useStandardToaster();

    useEffect(() => {
        const getLearning = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { 
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const res = await response.json();
                
                // Check if learning exists and has faq property, otherwise use empty array
                setFaqData(res.data?.faq || []);
                
                // If no learning object exists yet, create one with empty FAQ
                if (!res.data) {
                    await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        },
                        body: JSON.stringify({ faq: [] })
                    });
                }
            } catch (error) {
                console.error('Failed to fetch FAQs:', error);
                pushToast(
                    <Message type="error">Failed to load FAQs</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                setFaqData([]); // Set empty array as fallback
            }
        };
        
        getLearning();
    }, [refresh]);

    const handleEditClick = (id) => {
        setEditedFaq(faqData.find(e=>e.id===id));
        setEditModalOpen(true);
    };

    const handleAddClick = () => {
        setNewFaq({ id: faqData.length+1, question: '', answer: '' });
        setAddModalOpen(true);
    };

    const handleChange = (value, field, isEdit = false) => {
        // Extract the value from the event object if it exists
        const actualValue = value?.target?.value ?? value;
        
        if (isEdit) {
            setEditedFaq((prev) => ({ ...prev, [field]: actualValue }));
        } else {
            setNewFaq((prev) => ({ ...prev, [field]: actualValue }));
        }
    };

    const handleSaveEdit = async(id) => {
        try {
            // Create updated data first
            const updatedData = [...faqData];
            const objIndex = updatedData.findIndex(obj => obj.id === id);
            updatedData[objIndex] = editedFaq;

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ faq: updatedData }),
            });
            
            if (response.ok) {
                setFaqData(updatedData); // Update state immediately
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="success">FAQ updated successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                setEditModalOpen(false); // Close modal after successful update
            } else {
                throw new Error('Failed to update FAQ');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to update FAQ</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    const handleSaveNew = async() => {
        try {
            const updatedData = [...faqData, newFaq];
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ faq: updatedData }),
            });
            
            if (response.ok) {
                setFaqData(updatedData); // Update state immediately
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="success">FAQ added successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
                setAddModalOpen(false);
            } else {
                throw new Error('Failed to add FAQ');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to add FAQ</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    const handleDelete = async(id) => {
        const updatedData = faqData.filter(e=>e.id !== id);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ faq: updatedData }),
            });
            
            if (response.ok) {
                setFaqData(updatedData); // Update state immediately
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="success">FAQ deleted successfully</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                throw new Error('Failed to delete FAQ');
            }
        } catch (error) {
            pushToast(
                <Message type="error">Failed to delete FAQ</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    const handleDeleteClick = (id) => {
        const faqToDelete = faqData.find(e => e.id === id);
        setSelectedFaq(faqToDelete);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async() => {
        if (!selectedFaq) return;
        
        await handleDelete(selectedFaq.id);
        setDeleteModalOpen(false);
        setSelectedFaq(null);
    };

    return (
        <>
            <SimpleAccordion id={"manageFAQPageAccordion"}  defaultActiveKey={["1"]}>
                <SimpleAccordion.Panel 
                    header="Manage FAQ"
                    eventKey="1"
                    noCollapse
                >
                    <FAQContainer>
                        <MainButtonContainer>   
                            <StandardButton 
                                onClick={handleAddClick}
                                style={{ marginBottom: "20px", width: "10%" }}
                            >
                                Add FAQ
                            </StandardButton>
                        </MainButtonContainer>
                        <FAQAccordion id={"manageFAQAccordion"}>
                            {faqData.map((elem, i) => (
                                <FAQAccordion.Panel
                                    key={i+1}
                                    eventKey={`faq-${elem.id}`}
                                    header={elem.question}
                                    text={elem.answer}
                                    onEdit={() => handleEditClick(elem.id)}
                                    onDelete={() => handleDeleteClick(elem.id)}
                                />
                            ))}
                        </FAQAccordion>
                    </FAQContainer>

                    {/* Edit Modal */}
                    <Modal
                        open={editModalOpen}
                        onClose={() => setEditModalOpen(false)}
                        title="Edit FAQ"
                        size={'lg'}
                        confirmText="Save"
                        cancelText="Cancel"
                        onConfirm={() => handleSaveEdit(editedFaq.id)}
                        onCancel={() => setEditModalOpen(false)}
                    >
                        <Modal.Body >
                            <FormGroup>
                                <FormLabel>Question</FormLabel>
                                <StandardInput style={{width: '100%'}}
                                    value={editedFaq.question}
                                    onChange={(value) => handleChange(value, 'question', true)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Answer</FormLabel>
                                <StandardDescription
                                    value={editedFaq.answer}
                                    onChange={(value) => handleChange(value, 'answer', true)}
                                    rows={4}
                                />
                            </FormGroup>
                        </Modal.Body>
                    </Modal>

                    {/* Add Modal */}
                    <Modal
                        open={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        title="Add FAQ"
                        confirmText="Add"
                        size={'lg'}
                        cancelText="Cancel"
                        onConfirm={handleSaveNew}
                        onCancel={() => setAddModalOpen(false)}
                    >
                        <Modal.Body>
                            <FormGroup>
                                <FormLabel>Question</FormLabel>
                                <StandardInput style={{width: '100%'}}
                                    value={newFaq.question}
                                    onChange={(value) => handleChange(value, 'question')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Answer</FormLabel>
                                <StandardDescription
                                    value={newFaq.answer}
                                    onChange={(value) => handleChange(value, 'answer')}
                                    rows={4}
                                />
                            </FormGroup>
                        </Modal.Body>
                    </Modal>

                    {/* Delete Confirmation Modal */}
                    <Modal
                        open={deleteModalOpen}
                        onClose={() => setDeleteModalOpen(false)}
                        title="Delete FAQ"
                        confirmText="Delete"
                        cancelText="Cancel"
                        onConfirm={handleConfirmDelete}
                        onCancel={() => setDeleteModalOpen(false)}
                        confirmColor={theme.colors.error}
                    >
                        <Modal.Body>
                            <ModalText>
                                Are you sure you want to delete this FAQ?
                            </ModalText>
                            <ModalText>
                                <ModalHighlight>Question:</ModalHighlight> {selectedFaq?.question}
                            </ModalText>
                        </Modal.Body>
                    </Modal>
                </SimpleAccordion.Panel>
            </SimpleAccordion>

        </>
    );
};

export default ManageFAQ;
