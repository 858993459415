import React, { useEffect, useState, useCallback } from "react";
import theme from "../../styles/theme";
import { TagSuccess, TagDanger, TagWarning } from '../ui_reusable/styled_comps/Tags';

import VersatileGrid from '../ui_reusable/VersatileGrid';
import { GridCellBoldText, GridCellNormalText, GridCellSmallFadedText } from '../ui_reusable/VersatileGridUtils';
import { EasyIconButton } from '../ui_reusable/styled_comps/Buttons';
import SectionTitle from "../ui_reusable/SectionTitle";
import CreateDataset from "./Create";
import ShowDataset from "../comp_reusable/drivers/Show";
import { HeaderContainer } from '../ui_reusable/styled_comps/PlacementMisc';
import { HeaderMedium600Style } from "../styles/TextStyles";

import { StatsContainer } from '../clients/styled_sub_comps/ListStyledSubComps';
import { SmallNormalTag } from '../ui_reusable/styled_comps/Tags';
import SimpleAccordion from "../ui_reusable/SimpleAccordion";
import {HStackRadioComplex,ClientContextualLabel} from "../comp_reusable/drivers/styled_sub_comps/ListStyledSubComps"
import CustomIcons from '../ui_reusable/CustomIcons';
import EditModal from "../comp_reusable/drivers/EditModal";
import { useAuth } from '../../context/AuthContext';  // Fix the path from contexts to context
import { StandardRadio, RadioProvider } from '../ui_reusable/StandardRadioButtons';

import  { FormSelectPicker }  from "../ui_reusable/StandardSelectPickers";
import { getFitnessTag } from '../../utils/ScoreFitnessValidation';

const DatasetList = () => {
    const [loading, setLoading] = useState(true);
    const [datasets, setDatasets] = useState([]);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [everExpanded, setEverExpanded] = useState(new Set());  // Track rows that have ever been expanded
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const { isAdmin } = useAuth();
    const [clients, setClients] = useState([]);
    const [categoryParam, setCategoryParam] = useState();
    const [clientParam, setClientParam] = useState();

    useEffect(() => {
        const getDatasets = async () => {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_URL}/api/datasets/?limit=99999`;
    
            if (categoryParam) {
                url += `&filter_by=category`;
                url += `&category=${categoryParam}`;
                if (categoryParam === "client" && clientParam) {
                    url += `&client=${clientParam}`;
                }
            }
    
            try {
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                const res = await response.json();
                setDatasets(res.data);
            } catch (error) {
                console.error("Failed to fetch datasets:", error);
            } finally {
                setLoading(false);
            }
        };
    
        getDatasets();
    }, [refresh, categoryParam, clientParam]);

    useEffect(()=>{
        const getClients = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/?limit=99999`,
                    { 
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                const res = await response.json();
                setClients(res.data);
            } catch (error) {
                console.error('Failed to fetch clients:', error);
            } finally {
                setLoading(false);
            }
        };
        if(isAdmin && categoryParam === 'client'){
            getClients();
        }
    },[isAdmin, categoryParam]);



    const handleExpand = (dataIdx) => {
        setExpandedRows(prev => {
            const next = new Set(prev);
            if (next.has(dataIdx)) {
                next.delete(dataIdx);
            } else {
                next.add(dataIdx);
                // Add to ever-expanded set when expanding
                setEverExpanded(prev => new Set(prev).add(dataIdx));
            }
            return next;
        });
    };

    const handleEdit = (dataset) => {
        setSelectedDataset(dataset);
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedDataset(null);
    };

    // Grid headers configuration
    const headers = [
        {
            title: 'Dataset name',
            key: 'name',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            flexGrow: 2
        },
        {
            title: 'Source',
            key: 'source',
            type: 'string',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Interval',
            key: 'interval',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Last updated',
            key: 'created_at',
            type: 'datetime',
            isSortable: true,
            isSearchable: true
        },
        {
            title: 'Observations',
            key: 'observations',
            type: 'number',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Units',
            key: 'units',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'Quality',
            key: 'quality',
            type: 'string',
            isSortable: true,
            isSearchable: true,
            center: true
        },
        {
            title: 'View/Edit',
            key: 'actions',
            type: 'none',
            isSortable: false,
            isSearchable: false,
            center: true
        }
    ];

    // Grid header stats
    const gridHeader = (
        <StatsContainer>
            
            <div style={{width: '42%',display: 'flex', gap: '1rem',justifyContent: 'space-between', alignItems: 'center'}} >

                <span css={HeaderMedium600Style}>Total Datasets: {datasets.length}</span>
                <div  style={{display: 'flex', justifyItems: 'center'}}>   
                    <SmallNormalTag>
                        {datasets.filter(d => d.quality === 'good').length} good
                    </SmallNormalTag>
                    <SmallNormalTag>
                        {datasets.filter(d => d.quality === 'check').length} check
                    </SmallNormalTag>
                    <SmallNormalTag>
                        {datasets.filter(d => d.quality === 'poor').length} poor
                    </SmallNormalTag>

                </div>
            </div>
            <div style={{width: '50%',display: 'flex', gap: '1rem',justifyContent: 'space-between',}} >
                {(isAdmin ) && (
                    <RadioProvider initValue="all" group="driverType" 
                        onChange={
                                (value) => {
                                    if(value==='all'){
                                        setCategoryParam(''); 
                                        setClientParam('');
                                    }
                                    else if(value==='contextual'){
                                        setCategoryParam('contextual')
                                        setClientParam('');
                                    }else if(value==='client'){
                                        setCategoryParam('client')
                                    }
                                }
                            }
                            >
                        <div style={{display: 'flex', gap: '1rem'}} >
                            <HStackRadioComplex style={{paddingLeft: '5 rem'}}>
                                <StandardRadio
                                    size={'m'}
                                    group="driverType"
                                    value="all"
                                    initState={true}
                                />
                                <ClientContextualLabel>All</ClientContextualLabel>
                            </HStackRadioComplex>
                            <HStackRadioComplex style={{paddingLeft: '5 rem'}}>
                                <StandardRadio
                                    size={'m'}
                                    group="driverType"
                                    value="contextual"
                                    initState={false}

                                />
                                <ClientContextualLabel>Contextual</ClientContextualLabel>
                            </HStackRadioComplex>
                            <HStackRadioComplex>
                                <StandardRadio
                                    size={'m'}
                                    group="driverType"
                                    value="client"
                                    initState={false}

                                />
                                <ClientContextualLabel>Client</ClientContextualLabel>

                            </HStackRadioComplex>
                        </div>

                    </RadioProvider>
                    )}
                    {( isAdmin && categoryParam === 'client' )&& <HStackRadioComplex>
                            <FormSelectPicker 
                                searchable={false}
                                cleanable={true}
                                placeholder="Select client"
                                defaultValue={0} 
                                size="s" 
                                data={clients.map((client, i) => ({
                                    key: i+1,
                                    label: client.name,
                                    value: client.id
                                }))}
                                onChange={(value)=>setClientParam(value)}
                                style={{ width: 150 }}
                            />
                        </HStackRadioComplex>
                    }
                
            </div>
        </StatsContainer>
    );

    // Simplify renderIntersectingRow - remove the immediate div wrapper
    const renderIntersectingRow = useCallback((rowData, rowIdx, dataIdx) => {
        if (!expandedRows.has(dataIdx)) {
            return null;  // Return null instead of empty div
        }
        return <ShowDataset dataset={rowData} datasetId={rowData.id} units={rowData.units} />;
    }, [expandedRows]);

    return (
        <>
            {/* Page Header Section */}
            <HeaderContainer>
                <SectionTitle>Manage Datasets</SectionTitle>
            </HeaderContainer>

            {/* Main Content Section */}
            <SimpleAccordion id={"datasetPageAccordion"} defaultActiveKey={['1']}>
                <SimpleAccordion.Panel 
                    header="Dataset List"
                    eventKey="1"
                    noCollapse
                >
                    {/* Dataset Creation Interface */}
                    <CreateDataset 
                        setRefresh={setRefresh} 
                    />

                    {/* Dataset Data Grid */}
                    <VersatileGrid
                        headers={headers}
                        data={datasets}
                        searchPlaceholder="Find dataset"
                        renderRow={(rowData, rowIdx, dataIdx) => [
                            <GridCellBoldText>{rowData.name}</GridCellBoldText>,
                            <GridCellNormalText>{rowData.source}</GridCellNormalText>,
                            <GridCellNormalText>{rowData.interval}</GridCellNormalText>,
                            <GridCellSmallFadedText>
                                {`${new Date(rowData.created_at).toLocaleDateString()}@${new Date(rowData.created_at).getHours()}:${new Date(rowData.created_at).getMinutes()}`}
                            </GridCellSmallFadedText>,
                            <GridCellNormalText>{rowData.observations}</GridCellNormalText>,
                            <GridCellNormalText>{rowData.units}</GridCellNormalText>,
                            <GridCellNormalText>
                                {getFitnessTag(null, rowData.quality)}
                            </GridCellNormalText>,
                            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                                <EasyIconButton onClick={() => handleExpand(dataIdx)}>
                                    {expandedRows.has(dataIdx) ? 
                                        <CustomIcons.faEyeSlash  color={theme.colors.veryFadedText} solid={false} size="xs" /> : 
                                        <CustomIcons.faEye  color={theme.colors.veryFadedText} solid={false} size="xs" />
                                    }
                                </EasyIconButton>
                                <EasyIconButton  onClick={() => handleEdit(rowData)}>
                                    <CustomIcons.PenIcon color={theme.colors.veryFadedText} size="xs" />
                                </EasyIconButton>
                            </div>
                        ]}
                        renderIntersectingRow={renderIntersectingRow}
                        headerInfo={gridHeader}
                        maxDisplayedItems={10}
                        loading={loading}
                    />
                </SimpleAccordion.Panel>
            </SimpleAccordion>

            {selectedDataset && (
                <EditModal
                    dataset={selectedDataset}
                    setRefresh={setRefresh}
                    open={editModalOpen}
                    onClose={handleCloseEditModal}
                />
            )}

        </>
    );
};

export default DatasetList;