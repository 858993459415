import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const [accordionContexts, setAccordionContexts] = useState(new Map());
    const [navigationInfo, setNavigationInfo] = useState(() => {
        return window.history.state?.navigationInfo || {};
    });
    const [mainContainer, setMainContainer] = useState(null);
    const [isNavigatingBack, setIsNavigatingBack] = useState(false);

    // Track scroll position without causing re-renders
    useEffect(() => {
        let scrollTimeout;
        const handleScroll = () => {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                // Update history state directly without React state
                const currentState = window.history.state || {};
                const newInfo = {
                    ...(currentState.navigationInfo || {}),
                    scrollY: window.scrollY
                };
                
                window.history.replaceState(
                    { ...currentState, navigationInfo: newInfo },
                    ''
                );
            }, 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimeout);
        };
    }, []);

    // Separate effect for handling popstate events
    useEffect(() => {
        const handlePopState = (event) => {
            setIsNavigatingBack(true);
            const savedInfo = event.state?.navigationInfo;
            
            if (typeof savedInfo?.scrollY === 'number') {
                createTemporaryScrollSpace();
                setTimeout(() => {
                    window.scrollTo(0, savedInfo.scrollY);
                }, 100);
            }

            // Only update React state for accordion states
            if (savedInfo?.accordionStates) {
                setNavigationInfo(prev => ({
                    ...prev,
                    accordionStates: savedInfo.accordionStates
                }));
            }

            setTimeout(() => setIsNavigatingBack(false), 1000);
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [mainContainer]);

    // Function to manage temporary scroll space
    const createTemporaryScrollSpace = () => {
        if (!mainContainer) return;
        
        // Create temporary div
        const tempDiv = document.createElement('div');
        tempDiv.style.height = '3000px';
        tempDiv.style.width = '1px';
        tempDiv.style.position = 'absolute';
        tempDiv.style.opacity = '0';
        tempDiv.style.pointerEvents = 'none';
        tempDiv.id = 'temp-scroll-space';
        
        mainContainer.appendChild(tempDiv);

        // Remove after animation completes
        setTimeout(() => {
            tempDiv.remove();
        }, 1000);
    };

    // Update history state when accordion states change
    const registerAccordionState = (contextId, activeKeys) => {
        console.log('🔄 Registering accordion state:', { contextId, activeKeys });
        setNavigationInfo(prev => {
            const newInfo = {
                ...prev,
                accordionStates: {
                    ...(prev.accordionStates || {}),
                    [contextId]: activeKeys
                }
            };
            // Update history state
            window.history.replaceState(
                { ...window.history.state, navigationInfo: newInfo },
                ''
            );
            return newInfo;
        });
    };

    // Simple context registration
    const registerAccordionContext = useCallback((id, context) => {
        //console.log('📥 Registering accordion context:', id);
        setAccordionContexts(prev => {
            const newMap = new Map(prev);
            newMap.set(id, context);
            return newMap;
        });
    }, []);

    const unregisterAccordionContext = useCallback((id) => {
        setAccordionContexts(prev => {
            const newMap = new Map(prev);
            newMap.delete(id);
            return newMap;
        });
    }, []);

    /**
     * Scrolls to a panel and opens it, without scrolling to any specific element inside
     * @param {string} accordionContextId - ID of the accordion container
     * @param {string} accordionKey - ID of the specific panel
     */
    const scrollToPanel = useCallback(async (accordionContextId, accordionKey) => {
        console.log('ScrollToPanel called with:', { accordionContextId, accordionKey });
        console.log('Available contexts:', Array.from(accordionContexts.keys()));

        const context = accordionContexts.get(accordionContextId);
        if (!context) {
            console.error(`Accordion context ${accordionContextId} not found. Available contexts:`, 
                Array.from(accordionContexts.keys()));
            return;
        }

        const accordion = context.getAccordion(accordionKey);
        if (!accordion) {
            console.error(`Accordion ${accordionKey} not found in context ${accordionContextId}`);
            return;
        }

        // First scroll to the panel header
        const headerElement = accordion.getHeaderElement();
        if (headerElement) {
            headerElement.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start'
            });

            // Wait for scroll animation to complete before opening
            await new Promise(resolve => setTimeout(resolve, 500));
            
            // Open the panel
            await context.activateAccordion(accordionKey);
        }
    }, [accordionContexts]);

    /**
     * Enhanced scroll function that both opens a panel and scrolls to a specific element
     * @param {string} accordionContextId - ID of the accordion container
     * @param {string} accordionKey - ID of the specific panel
     * @param {HTMLElement} domElement - Specific element to scroll to after opening
     */
    const scrollTo = useCallback(async (accordionContextId, accordionKey, domElement) => {
        // First do the panel scroll
        await scrollToPanel(accordionContextId, accordionKey);

        // Then scroll to the specific element if provided
        if (domElement) {
            // Wait for panel animation to complete
            await new Promise(resolve => setTimeout(resolve, 300));
            
            domElement.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start'
            });
        }
    }, [scrollToPanel]);

    return (
        <ScrollContext.Provider value={{
            registerAccordionContext,
            unregisterAccordionContext,
            registerAccordionState,
            scrollTo,
            scrollToPanel,
            setMainContainer, // Add this
            isNavigatingBack, // Add this to context
        }}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = () => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider');
    }
    return context;
};
