import React, { useState, useEffect } from 'react';
import { Message } from "rsuite";
import GeneralDriverList from '../../comp_reusable/GeneralDriverList';
import useStandardToaster from '../../ui_reusable/StandardToaster';

const ContextualDrivers = ({ clientId }) => {
    const [loading, setLoading] = useState(true);
    const [contextualDrivers, setContextualDrivers] = useState([]);
    const { pushToast } = useStandardToaster();    
    const [refresh, setRefresh] = useState(new Date().getTime());
    
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const responseClientDriver = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/${clientId}/datasets?filter_by=category&category=contextual&limit=99999`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                );
                const clientDrivers = await responseClientDriver.json();

                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/datasets?category=contextual&limit=99999`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                );
                const drivers = await response.json();
                setContextualDrivers(drivers.data.map(e => {
                    return {
                        dataset: e,
                        is_active: clientDrivers.data.find(clientDriver => clientDriver.dataset.id === e.id)?.is_active || false
                    }
                }) || []);
                
            } catch (error) {
                console.error('Failed to fetch drivers:', error);
                pushToast(
                    <Message type="error">Failed to load drivers</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [clientId, refresh]);

    const handleDriverToggle = async (driverId) => {
        const is_active = contextualDrivers.find(contextualDriver => contextualDriver.dataset.id === driverId)?.is_active || false;
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/clients/${clientId}/datasets/${driverId}`,
                {
                    headers: { 
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'PATCH',
                    body: JSON.stringify({ is_active: !is_active })
                }
            );
            if (response.ok) {
                setRefresh(new Date().getTime());
                pushToast(
                    <Message type="info">Driver selection updated.</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            } else {
                pushToast(
                    <Message type="error">Failed to update driver selection.</Message>,
                    { placement: 'topCenter', duration: 5000 }
                );
            }
        } catch (error) {
            pushToast(
                <Message type="error">`${error.message}`</Message>,
                { placement: 'topCenter', duration: 5000 }
            );
        }
    };

    return (
        <GeneralDriverList
            data={contextualDrivers}
            loading={loading}
            onCheckboxClick={handleDriverToggle}
            totalAvailable={contextualDrivers.length}
            totalSelected={contextualDrivers.filter(e=>e.is_active).length}
        />
    );
};

export default ContextualDrivers;
