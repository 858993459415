import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { TextMediumMini400Style } from '../../styles/TextStyles';

export const StatsContainer = styled.div`
    display: flex;
    gap: 1rem;
    min-width: 20rem;
    width: 100%;
    //make items move away from each other
    height: 5rem;
    align-items: center;

`;

export const StatItem = styled.span`
    ${TextMediumMini400Style};
    color: ${props => props.variant === 'active' 
        ? theme.colors.success 
        : props.variant === 'inactive' 
            ? theme.colors.error 
            : 'inherit'};
`;


export const HeaderSection = styled.div`
    flex: 0 0 auto;
`;

export const ProjectTagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export const ProjectTag = styled.div`
    background-color: ${theme.colors.primaryVeryVeryLight};
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${TextMediumMini400Style};
    color: ${theme.colors.primary};

`;
