import styled from '@emotion/styled';

// Vertical stack container
export const VStack = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;


    @container VStackBroad (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    
`;

export const LeftChildOfTopContainer = styled.div`
    //streching none
`

export const RightChildOfTopContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto 20rem;
    grid-template-rows: 2.4rem 2.4rem;
    column-gap: 1rem;
    padding-left: 2rem;
    width: 35rem;
    padding-top: 3rem;
`

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
`;



// Horizontal stack container
export const HStack = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
`;

// Horizontal stack container
export const VStackBroad = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    container: VStackBroad / inline-size;
`;


// Horizontal radio and text
export const HStackRadioComplex = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: center;
`;