import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { TextLarge500Style, InfoMediumMaxi500Style, InfoXXXLarge600Style } from '../../styles/TextStyles';
import { DataValueLabel } from './DataInfoUtils';

const checkboxSizes = {
  small: {
    size: '0.8rem',
    checkLeft: '4px',
    checkTop: '1px',
    checkWidth: '3px',
    checkHeight: '6px',
    radius: '0.15rem'
  },
  medium: {
    size: '1rem',
    checkLeft: '5px',
    checkTop: '2px',
    checkWidth: '4px',
    checkHeight: '8px',
    radius: '0.2rem'
  },
  big: {
    size: '1.2rem',
    checkLeft: '6px',
    checkTop: '3px',
    checkWidth: '5px',
    checkHeight: '10px',
    radius: '0.25rem'
  },
};

export const standardCheckboxStyle = (size = 'medium', color) => css`
  appearance: none;
  min-height: ${checkboxSizes[size].size};
  max-height: ${checkboxSizes[size].size};
  min-width: ${checkboxSizes[size].size};
  width: ${checkboxSizes[size].size};
  height: ${checkboxSizes[size].size};
  outline: 1px solid ${theme.colors.veryVeryVeryFadedText};
  border-radius: ${checkboxSizes[size].radius};
  background-color: ${theme.colors.surface};
  cursor: pointer;
  position: relative;
  margin: 2px;

  &:hover {
    border-color: ${color || theme.colors.primaryDark};
    background-color: ${color ? `${color}22` : theme.colors.primaryLight};
  }

  &:checked {
    background-color: ${color ? `${color}22` : theme.colors.primaryVeryVeryLight};
    outline: 2px solid ${color || theme.colors.primary};
    &:after {
      content: '';
      position: absolute;
      left: ${checkboxSizes[size].checkLeft};
      top: ${checkboxSizes[size].checkTop};
      width: ${checkboxSizes[size].checkWidth};
      height: ${checkboxSizes[size].checkHeight};
      
      color: ${color || theme.colors.primary};
      border: solid ${color || theme.colors.primary};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:hover {
      background-color: ${color || theme.colors.primaryDark};
    }
  }
`;

// Base style that will be shared between input and description
export const baseStandardInputStyle = ({ highlightError }) => css`
    ${DataValueLabel}
    box-sizing: border-box;
    border: 1px solid ${highlightError ? theme.colors.error : theme.colors.backgroundDark};
    border-radius: 0.5rem;
    background: transparent;
    transition: border-color 0.2s ease;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        outline: none;
        border-color: ${highlightError ? theme.colors.error : theme.colors.primary};
    }

    &:disabled {
        background: transparent;
        border-color: transparent;
        cursor: default;
    }
`;

export const standardInputStyle = ({ highlightError }) => css`
    ${baseStandardInputStyle({ highlightError })}
    padding: 0.5rem;
    white-space: nowrap;
`;

export const standardDescriptionStyle = ({ highlightError }) => css`
    ${baseStandardInputStyle({ highlightError })}
    padding: 0.75rem;
    min-height: 5rem;
    resize: vertical;
    white-space: pre-wrap;
    line-height: 1.4;
    width: 100%;
    box-sizing: border-box;
`;

export const headerInputStyle = css`
    ${InfoXXXLarge600Style};
    padding: 0;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    background: transparent;
    transition: border-color 0.2s ease;
    width: 100%;

    &:focus {
        outline: none;
        border-bottom-color: ${theme.colors.primary};
    }

    &:disabled {
        background: transparent;
        border-color: transparent;
        cursor: default;
    }
`;
