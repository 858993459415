import React from 'react';
import styled from '@emotion/styled';
import theme from '../../../styles/theme';

const HighlightSpan = styled.span`
    background-color: ${theme.colors.primaryLight};
    border-radius: 2px;
`;

const StringSearchMatchDisplayer = ({ children, searchTerms = [] }) => {
    if (!searchTerms.length || !children) return children;

    // Filter out empty search terms and create regex pattern
    const pattern = searchTerms
        .filter(term => term.trim())
        .map(term => term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
        .join('|');

    if (!pattern) return children;

    // Split the text by matches
    const regex = new RegExp(`(${pattern})`, 'gi');
    const parts = String(children).split(regex);

    return (
        <>
            {parts.map((part, i) => {
                // Check if this part matches any search term
                const isMatch = searchTerms.some(term => 
                    term.toLowerCase() === part.toLowerCase()
                );

                return isMatch ? (
                    <HighlightSpan key={i}>{part}</HighlightSpan>
                ) : (
                    part
                );
            })}
        </>
    );
};

export default StringSearchMatchDisplayer;
