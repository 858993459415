import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { InfoXXXLarge600Style } from '../../styles/TextStyles';



export const SectionPanel = styled.div`
    margin: 10px;
    padding: 1.5rem;
    border-radius: 1.2rem;
    background-color: ${theme.colors.surfaceSecondary};
`;

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SectionTitle = styled.span`
    ${InfoXXXLarge600Style};
    color: ${theme.colors.veryFadedText};
    text-transform: uppercase;
`;
